import prodRegistry from './prod_registry.json'

export type Root = Root2[]

export interface Root2 {
  slug: string
  kybInfo: KybInfo
  medias: Medias
  socialNetworks: SocialNetwork[]
  web: Web[]
  cryptoWallets: CryptoWallets
  blockpass: Blockpass
}

export interface KybInfo {
  businessDetails: BusinessDetails
}

export interface BusinessDetails {
  name: string
  jurisdiction: string
  brandName: string
}

export interface Medias {
  logo?: string
}

export interface SocialNetwork {
  name: string
  account: string
}

export interface Web {
  url: string
  urlDisplay?: string
  description: string
}

export interface CryptoWallets {
  evm?: Evm[]
  bitcoin?: any[]
}

export interface Evm {
  chainId: string
  publicKey: string
  description: string
}

export interface Blockpass {
  services?: Service[]
}

export interface Service {
  type: string
  clientId: string
  description?: string
  logo?: string
  register?: string
}

const data: Root = prodRegistry
export default data
