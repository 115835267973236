import get from 'lodash/get'
import { makeObservable, observable, action } from 'mobx'

class DashboardObj {
  images: any

  constructor(data: any) {
    makeObservable(this, {
     
      images: observable,

      updateData: action,
    })
    this.updateData(data)
  }

  updateData(data: any) {
    this.images = get(data, 'images', {})
  }

  static fromJS(object: any) {
    return new DashboardObj(object)
  }
}

export default DashboardObj
