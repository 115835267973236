import { useObserver } from 'mobx-react'
import { DashboardTooltip, Typography } from '@blockpass-org/ui'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
// import useRootStore from '@hooks/useRootStore'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import {
  WebsiteIcon,
  RightArrowComponentIcon,
} from '@components/Icon/CommonIcons'
import COLOR from '@theme/colors'
import get from 'lodash/get'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Link from '@mui/material/Link'
import { cleanDisplayUrl } from '@/utils'

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 344,
    height: 64,
    textAlign: 'center',
    padding: '16px',
    backgroundColor: COLOR.commonColor.white,
    borderRadius: 8,
    border: '1px solid rgba(196, 196, 196, 1)',
    cursor: 'pointer',
  },
  containerFull: {
    maxWidth: 320,
    width: '100%',
    height: 64,
    textAlign: 'center',
    padding: '12px',
    backgroundColor: COLOR.commonColor.white,
    borderRadius: 8,
    border: '1px solid rgba(196, 196, 196, 1)',
    cursor: 'pointer',
  },

  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
}))

export const WebsiteComponent = (props: any) => {
  const classes = useStyles()
  // const rootStore = useRootStore();
  // const {
  //   LocalizationStore,
  // } = rootStore;

  const { web, modeFull } = props
  const theme = useTheme()
  let downSm = useMediaQuery(theme.breakpoints.down('sm'))

  return useObserver(() => {
    const url = get(web, 'url', '')
    let urlDisplay = get(web, 'urlDisplay', '')
    if (urlDisplay === '') {
      urlDisplay = cleanDisplayUrl(url)
    }

    let desc = get(web, 'description', '')
    if (desc.trim() === '') {
      desc = 'Insights, updates, and more. Click to discover.'
    }

    return (
      <Box
        className={modeFull ? classes.containerFull : classes.container}
        style={modeFull && downSm ? { width: 'auto' } : {}}
      >
        {' '}
        <Link
          href={url.toString()}
          target="_blank"
          rel="noopener"
          underline="none"
        >
          <Grid container spacing={modeFull ? 1.5 : 2}>
            <Grid item xs={1}>
              <WebsiteIcon />
            </Grid>
            <Grid item xs={9}>
              {' '}
              <DashboardTooltip
                title={
                  <Typography variant="body2" color={'white'}>
                    {desc}
                  </Typography>
                }
                arrow
              >
                <Box style={{ textAlign: 'left', paddingLeft: 12 }}>
                  <Typography noWrap variant="subtitle1">
                    {urlDisplay}
                  </Typography>
                  <div className={classes.truncate}>
                    <Typography variant="body2" color="textSecondary">
                      {desc}
                    </Typography>
                  </div>
                </Box>
              </DashboardTooltip>
            </Grid>
            <Grid item xs={2} style={{ display: 'flex' }}>
              <IconButton
                color="primary"
                component="span"
                // onClick={onClickLink}
                sx={{
                  '&.MuiIconButton-root': {
                    borderRadius: '4px !important',
                    width: '40px !important',
                    height: '40px !important',
                  },
                }}
                style={{ height: '40px', margin: 'auto', marginTop: '12px' }}
              >
                <RightArrowComponentIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Link>
      </Box>
    )
  })
}

export default WebsiteComponent
