import BaseStore from '@stores/base/BaseStore'
import RootStore from '../RootStore'

class BaseViewStore extends BaseStore {
  _storeName: string
  rootStore: RootStore
  viewStoreName: string
  constructor(rootStore: RootStore, viewStoreName?: string) {
    super()
    this.BASE_STORE_DEBUG_COLOR = 'Green'
    this.rootStore = rootStore
    this.viewStoreName = viewStoreName
  }

  // -----------------------------------------------------//
  //  Override Life circle
  // -----------------------------------------------------//
  async start(rootStore: RootStore) {
    await super.start()
    this.rootStore = rootStore
    this._storeName =
      this.viewStoreName || this.constructor.name + '_' + Date.now().toString()
    rootStore.loadViewStore(this._storeName, this)
  }

  async stop(rootStore: RootStore) {
    await super.stop()
    rootStore.unloadViewStore(this._storeName)
  }
}

export default BaseViewStore
