import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import useViewStore from '@hooks/useViewStore'
import ViewStore from './ServiceList.ViewStore'
import { DEFAULT_AVATAR, SOCIAL_BASE_URL } from '@/constants/constants'
import Avatar from '@mui/material/Avatar'
import { Typography, BpDataGrid, SearchBar } from '@blockpass-org/ui'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { toJS } from 'mobx'
import { EmptyGraphicIcon2 } from '@workspace/shared/Icon/CommonIcons'
import COLOR from '@theme/colors'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import useRootStore from '@hooks/useRootStore'
import { SplashPage } from '../SplashPage'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import countries from '../../constants/countries.json'
import Link from '@mui/material/Link'
// import { pushDataLayer } from '@utils/index'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import SocialIcon from '@/components/SocialIcon'
import loadable from '@loadable/component'
import { cleanDisplayUrl } from '@/utils'
const DashboardTooltip = loadable(
  () => import('@components/DashboardTooltip/DashboardTooltip')
)

const ROW_HEIGHT = 72

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 40,
    height: 40,
  },
  iconMenu: {
    padding: '16px 0px 16px 0px',
  },
  containerEmpty: {
    display: 'flex',

    backgroundColor: COLOR.commonColor.white,
    color: COLOR.textColor.secondary,
    borderRadius: 8,
    // height: 540,
  },
  avatar: {
    margin: 'auto !important',
    maxHeight: 40,
    maxWidth: 40,
  },
  kybInfo: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}))

const getNameByCode3 = (code3) => {
  if (code3) {
    const country = countries.find((c) => {
      return c.code3.toLowerCase() === code3.toLowerCase()
    })
    if (country) return country.name
  }
  return 'Invalid country'
}

const ServiceList = observer(function ServiceList() {
  useEffect(() => {
    if (DashboardStore.ignoreGetParam) {
      return
    }
    let embedded = '0'
    let searchObj = {}
    const splitHash = get(window, 'location.hash', '').split('?')
    if (splitHash.length > 1) {
      const search = get(window, 'location.hash', '')
        .split('?')[1]
        .split('&')
        .filter((v) => v !== '')
      search.forEach((s) => {
        const filter = s.split('=').filter((v) => v !== '')
        if (filter.length > 1) {
          const value = filter[1]
          searchObj[filter[0]] = value
        }
      })
    }
    embedded = get(searchObj, 'embedded', '0')

    if (embedded && embedded === '1') {
      DashboardStore.setEmbedMode(true)
    } else {
      DashboardStore.setEmbedMode(false)
    }
  })

  /* eslint-disable */
  const [currentClientId, setCurrentClientId] = useState('')
  const [currentServiceInfo, setCurrentServiceInfo] = useState({})
  const [openDialogShareableLink, setOpenDialogShareableLink] = useState(false)
  // const [openDialogOpenDashboard, setOpenDialogOpenDashboard] = useState(false);
  const [openDialogTestService, setOpenDialogTestService] = useState(false)
  /* eslint-enable */

  const classes = useStyles()
  const rootStore = useRootStore()
  const {
    LocalizationStore: { translate },
    DashboardStore,
    RouteStore,
  } = rootStore
  const viewStore = useViewStore(rootStore, ViewStore, {})

  const embedded = DashboardStore.embedded

  const theme = useTheme()
  let betMdLg = useMediaQuery(theme.breakpoints.between('md', 'lg')) // 768 - 1200
  let downMd = useMediaQuery(theme.breakpoints.down('md'))

  const handleOnClick = (social) => {
    const name = get(social, 'name', '').toLowerCase()
    const account = get(social, 'account', '')
    const url = SOCIAL_BASE_URL[name] + account
    window.open(url.toString(), '_blank')
  }

  const columnsFull = [
    {
      field: 'kybInfo',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          Brand/Company
        </Typography>
      ),
      width: 112,
      flex: 5,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (params) => {
        return (
          <Box className={classes.kybInfo}>
            <Avatar
              alt="Blockpass"
              src={get(params, 'row.medias.logo', DEFAULT_AVATAR)}
              className={classes.avatar}
            />
            <Box style={{ marginLeft: '12px' }}>
              <Typography variant="subtitle2">
                {get(params, 'row.kybInfo.businessDetails.brandName', '')}
              </Typography>
              <Typography variant="body2" color={COLOR.textColor.secondary}>
                {get(params, 'row.kybInfo.businessDetails.name', '')}
              </Typography>
            </Box>
          </Box>
        )
      },
    },
    {
      field: 'Jurisdiction',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          Jurisdiction
        </Typography>
      ),
      flex: 1.7,
      // minWidth: 300,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderHeader: (params) => {
        const { colDef } = params
        return (
          <>
            {/* <DashboardTooltip title={colDef.headerName || colDef.field} placement="bottom" arrow> */}
            {colDef.headerName || colDef.field}
            {/* </DashboardTooltip> */}
          </>
        )
      },
      renderCell: (params) => {
        const name = getNameByCode3(
          get(params, 'row.kybInfo.businessDetails.jurisdiction', '')
        )
        return (
          <Typography
            style={{ display: 'inline-block', whiteSpace: 'pre-line' }}
            variant="body2"
          >
            {name}
          </Typography>
        )
      },
    },
    {
      field: 'website',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          Website
        </Typography>
      ),
      flex: 2.7,
      // minWidth: 200,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderHeader: (params) => {
        const { colDef } = params

        return (
          <>
            {/* <DashboardTooltip title={colDef.headerName || colDef.field} placement="bottom" arrow> */}
            {colDef.headerName || colDef.field}

            {/* </DashboardTooltip> */}
          </>
        )
      },
      renderCell: (params) => {
        const website = get(params, 'row.web', [])
        let webObj = { url: '', description: '' }
        if (website.length > 0) {
          webObj = website[0]
        }
        return (
          <Link
            href={webObj.url}
            underline="hover"
            target="_blank"
            rel="noopener"
            onClick={(event) => event.stopPropagation()}
          >
            {cleanDisplayUrl(webObj.url)}
          </Link>
        )
      },
    },

    {
      field: 'action',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          {''}
        </Typography>
      ),
      type: 'actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      getActions: (params) => {
        if (params.row.socialNetworks.length <= 0) {
          return []
        }
        // top 2 social
        const socials = params.row.socialNetworks
        if (socials.length > 1) {
          const name0 = get(socials[0], 'name', '')
          const name1 = get(socials[1], 'name', '')
          return [
            <DashboardTooltip title={name0} placement="bottom" arrow>
              {/*// @ts-ignore */}
              <GridActionsCellItem
                icon={<SocialIcon color={false} name={name0} />}
                label={name0}
                onClick={() => handleOnClick(socials[0])}
                className={classes.icon}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            </DashboardTooltip>,

            <DashboardTooltip title={name1} placement="bottom" arrow>
              {/*// @ts-ignore */}
              <GridActionsCellItem
                icon={<SocialIcon color={false} name={name1} />}
                label="Shareable"
                onClick={() => handleOnClick(socials[1])}
                className={classes.icon}
              />
            </DashboardTooltip>,
          ]
        } else if (socials.length > 0) {
          const name0 = get(socials[0], 'name', '')
          return [
            <DashboardTooltip title={name0} placement="bottom" arrow>
              {/*// @ts-ignore */}
              <GridActionsCellItem
                icon={<SocialIcon color={false} name={name0} />}
                label={name0}
                onClick={() => handleOnClick(socials[0])}
                className={classes.icon}
                // disabled={!canOpenKYCConnect}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            </DashboardTooltip>,
          ]
        }
      },
    },
  ]
  const columnsMd = [
    {
      field: 'kybInfo',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          Brand/Company
        </Typography>
      ),
      width: 112,
      flex: 4,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (params) => {
        return (
          <Box className={classes.kybInfo}>
            <Avatar
              alt="Blockpass"
              src={get(params, 'row.medias.logo', DEFAULT_AVATAR)}
              className={classes.avatar}
            />
            <Box style={{ marginLeft: '12px' }}>
              <Typography variant="subtitle2">
                {get(params, 'row.kybInfo.businessDetails.brandName', '')}
              </Typography>
              <Typography variant="body2" color={COLOR.textColor.secondary}>
                {get(params, 'row.kybInfo.businessDetails.name', '')}
              </Typography>
            </Box>
          </Box>
        )
      },
    },
    {
      field: 'Jurisdiction',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          Jurisdiction
        </Typography>
      ),
      flex: 3,
      // minWidth: 300,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderHeader: (params) => {
        const { colDef } = params
        return (
          <>
            {/* <DashboardTooltip title={colDef.headerName || colDef.field} placement="bottom" arrow> */}
            {colDef.headerName || colDef.field}
            {/* </DashboardTooltip> */}
          </>
        )
      },
      renderCell: (params) => {
        const name = getNameByCode3(
          get(params, 'row.kybInfo.businessDetails.jurisdiction', '')
        )
        return (
          <Typography
            style={{ display: 'inline-block', whiteSpace: 'pre-line' }}
            variant="body2"
          >
            {name}
          </Typography>
        )
      },
    },
    {
      field: 'website',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          Website
        </Typography>
      ),
      flex: 3,
      // minWidth: 200,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderHeader: (params) => {
        const { colDef } = params

        return (
          <>
            {/* <DashboardTooltip title={colDef.headerName || colDef.field} placement="bottom" arrow> */}
            {colDef.headerName || colDef.field}

            {/* </DashboardTooltip> */}
          </>
        )
      },
      renderCell: (params) => {
        const website = get(params, 'row.web', [])
        let webObj = { url: '', description: '' }
        if (website.length > 0) {
          webObj = website[0]
        }
        return (
          <Link
            href={webObj.url}
            underline="hover"
            target="_blank"
            rel="noreferrer"
          >
            {webObj.url}
          </Link>
        )
      },
    },
  ]
  const columnsSm = [
    {
      field: 'kybInfo',
      headerName: (
        <Typography variant="body1" color={COLOR.textColor.secondary}>
          Brand/Company
        </Typography>
      ),
      width: 112,
      flex: 5,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (params) => {
        return (
          <Box className={classes.kybInfo}>
            <Avatar
              alt="Blockpass"
              src={get(params, 'row.medias.logo', DEFAULT_AVATAR)}
              className={classes.avatar}
            />
            <Box style={{ marginLeft: '12px' }}>
              <Typography variant="subtitle2">
                {get(params, 'row.kybInfo.businessDetails.brandName', '')}
              </Typography>
              <Typography variant="body2" color={COLOR.textColor.secondary}>
                {get(params, 'row.kybInfo.businessDetails.name', '')}
              </Typography>
            </Box>
          </Box>
        )
      },
    },
  ]

  const onClickServiceDetail = (params) => {
    DashboardStore.selectedCompanySlug = get(params, 'row.slug', '')
    // header + footer
    DashboardStore.hideLayout = true

    RouteStore.push(
      `/${encodeURI(get(params, 'row.slug', ''))}${
        DashboardStore.embeddedParam
      }`
    )
  }
  const handleOnSearch = async (searchString: string) => {
    DashboardStore.setSearchInput(searchString)
  }

  const { ready } = viewStore
  let columns = columnsFull
  if (betMdLg) {
    columns = columnsMd
  }
  if (downMd) {
    columns = columnsSm
  }

  if (ready) {
    const data = toJS(DashboardStore.filterRegistry)
    const paging = {}
    const hasRecord = !isEmpty(data)
    let offset = downMd ? 0 : 225
    let defaultHeight = offset + data.length * ROW_HEIGHT
    if (data.length === 0) {
      defaultHeight = 486
    }

    let paddingBottom = '48px'
    if (downMd) {
      paddingBottom = '32px'
    }
    if (DashboardStore.embedded) {
      paddingBottom = '24px'
    }

    return (
      <div
        style={
          embedded
            ? {
                margin: 'auto',
                borderRadius: '16px',
                padding: downMd ? '0px 0px 256px 0px' : '0px 32px',
              }
            : {
                margin: 'auto',
                maxWidth: '1280px',
                padding: downMd ? '32px 16px 256px 16px' : '32px 32px 0px 32px',
              }
        }
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            id="serviceList"
            style={{
              width: '100%',
              background: COLOR.commonColor.white,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              height: defaultHeight,
              paddingBottom: paddingBottom,
              // minHeight: downMd ? 'calc(100vh - 450px)' : 'calc(100vh - 365px)',

              borderBottomLeftRadius: DashboardStore.embedded ? 8 : 0,
              borderBottomRightRadius: DashboardStore.embedded ? 8 : 0,

              marginTop: downMd ? 0 : 12,
            }}
          >
            {embedded ? (
              <></>
            ) : (
              <>
                <Box>
                  <Typography variant={downMd ? 'h5' : 'h4'}>
                    The KYB Registry
                  </Typography>
                  <Typography
                    variant="body1"
                    color={'textSecondary'}
                    style={{ marginTop: 8 }}
                  >
                    Web3’s Validated Business Directory.
                  </Typography>
                </Box>
                <Box style={{ margin: '24px 0px 24px 0px' }}>
                  <Divider />
                </Box>
              </>
            )}

            <Box
              style={{
                marginBottom: downMd ? 16 : 24,
                width: downMd ? '100%' : '400px',
              }}
            >
              <SearchBar
                onSearch={handleOnSearch}
                placeHolder={'Search'}
                defaultValue={DashboardStore.searchInput}
                searchOnClearInput
                displayAdvanced={false}
                advanceArray={null}
                width={downMd ? '100%' : '400px'}
                searchOnTyping
              />
            </Box>

            <BpDataGrid
              autoHeight={hasRecord ? true : false}
              separator={false}
              disableColumnMenu
              headerColor={COLOR.commonColor.greyButton}
              headerHeight={56}
              height={hasRecord ? 'auto' : '411px'}
              getRowId={(row: any) => row.slug}
              columns={columns}
              rows={data}
              /*// @ts-ignore */
              rowCount={paging?.total}
              /*// @ts-ignore */
              page={paging?.page}
              paginationMode={'server'}
              hideFooter={true}
              paginationType={'totalNumber'}
              alignPagination={'right'}
              // loading={loadingTable}
              rowHeight={ROW_HEIGHT}
              optionIcon={true}
              optionTooltip={translate('txtMore')}
              // callback
              onRowClick={(params) => onClickServiceDetail(params)}
              noRowTitle={'No company match your search'}
              noRowDesc={translate('txtNoServiceMatchDesc')}
              emptyIcon={<EmptyGraphicIcon2 />}
              pageSize={100}
            ></BpDataGrid>
          </div>
        </div>
      </div>
    )

    // if (hasRecord || DashboardStore.loadingList) {

    // } else {
    //   return (

    //   )
    // }
  } else {
    return (
      <div style={{ height: '100vh', textAlign: 'center' }}>
        <SplashPage />
      </div>
    )
  }
})

export default ServiceList
