import { observable, makeObservable } from 'mobx'
import get from 'lodash/get'
import { getCookie, setCookie,  } from '../../utils'
import Cookies from 'js-cookie'
import BaseStore from '../base/BaseStore'
import RootStore from '../RootStore'
// import { BLOCKPASS_SERVICE } from '@blockpass-org/utils-http'
import { NOTI_AUTO_HIDE_TIME } from "@workspace/shared/constants/constants";
// const pjson = require('../../../package.json');
// const env = get(window, 'env.BLOCKPASS_ENV')

// const bpService = BLOCKPASS_SERVICE.ac

const COOKIES_SSID = get((window as any).env, 'COOKIES_SSID', 'SSID')
class AuthStore extends BaseStore {
  _rootStore: RootStore
  accessToken = ''
  userProfile = {} 
  verifying = true

  isAuthenticated = false
  openSessionExpired = false
  constructor(rootStore: RootStore) {
    super()
    makeObservable(this, {
      userProfile: observable,
      accessToken: observable,
      verifying: observable,
      // consoleClient: observable,
      isAuthenticated: observable,
      openSessionExpired: observable,
    })
    this._rootStore = rootStore
    this._init()
  }

  async _init () {
    this.isAuthenticated = false
    this.verifying = true
   

    const token = Cookies.get(COOKIES_SSID)
    if(token) {
      
      this.setAccessToken(token)

      this.setAuthenticated(true)
      this.verifying = false
    } else {
      this.setAuthenticated(false)
      this.verifying = false
    }
    
  }

  getErrorMessage = (error: any) => {
    let msg = "txtUnauthorized";
    if (error.code === 401) {
      // this.setAuthenticated(false)
      error.message = "txtUnauthorized";
    } else if (error.code === 400) {
      msg = error.message;
    } else {
      msg = error.message;
    }
    return msg;
  };
  showNotificationWithString = (notiString) => {
    const {
      UINotificationStore,
    } = this._rootStore;
    UINotificationStore.showSnackbar({
      message: notiString,
      options: {
        variant: 'notification',
        actionLabel: '',
        // actionCallback: null,
        dismiss: true,
        preventDuplicate: false,
        autoHideDuration: NOTI_AUTO_HIDE_TIME,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
      },
    })

  }

  showNotificationWithError = (error) => {
    const {
      UINotificationStore,
      LocalizationStore: { translate },
    } = this._rootStore;
    if (error.code === 401) {
      this.setOpenSessionExpired(true)
    } else if (error.code === 500) {
      UINotificationStore.showSnackbar({
        message: 'An unexpected error happened on the server',
        options: {
          variant: 'notification',
          actionLabel: 'RELOAD',
          dismiss: true,
          preventDuplicate: false,
          autoHideDuration: NOTI_AUTO_HIDE_TIME,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          actionCallback: () => {
            window.location.reload();
          },
          persist: true
        },
      })
    } else {
      UINotificationStore.showSnackbar({
        message: translate(error.message),
        options: {
          variant: 'notification',
          actionLabel: '',
          // actionCallback: null,
          dismiss: true,
          preventDuplicate: false,
          autoHideDuration: NOTI_AUTO_HIDE_TIME,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
        },
      })
    }
  }

  

   _setDevCookies(ssid: string) {
    const name = get(window, 'env.COOKIES_SSID', 'QASSID')
    const expAt = new Date(Date.now() + 24 * 60 * 60 * 1000)
    setCookie(name, ssid, expAt)
  }

  _storeMemoryToken({ expiry, accessToken }) {
    // this.memoryToken = accessToken
  }

  setAccessToken = (token) =>{
    this.accessToken = token
  }
  
  async withLocalizeError<R>(action: () => Promise<R>): Promise<R> {
    try {
      return await action()
    } catch (error) {
      const translatedText = this._rootStore.LocalizationStore.translate(
        error.message,
        {
          fallbackRawValue: error.message,
        }
      )
      throw new Error(translatedText)
    }
  }

 
  redirectToOauth = () => {
    //show login screen
    this.setAuthenticated(false)
  }

  openOauth = () => {
    const authHost = get((window as any).env, 'AUTH_HOST', 'oauth.blockpass.org')
    // const redirectLink = get((window as any), 'location.href', 'location.origin')
    const redirectLink = get((window as any), 'location.origin', 'location.origin')
    // https://qa-oauth.blockpass.org/?client_id=blockpass-intl&redirect_uri=<ac_v2_url>
    const urlOauth = `${authHost}/?client_id=blockpass-intl&redirect_uri=${encodeURIComponent(redirectLink)}`
    window.open(urlOauth, '_self')
  }

  // ---------------------------------------------//
  //  Scope
  // ---------------------------------------------//
  hasScope = (scope) => {
    // if (!scope) return true
    // return this.scopes.includes(scope)
  
    return true
  }

  hasPermission(permissions) {
    return true
  }
  // ---------------------------------------------//
  //  Public - Register, Login
  // ---------------------------------------------//
  getTokenFromCookies = () => {
    const cookiesKey = get(window, 'env.COOKIES_SSID', 'SSID')
    return getCookie(cookiesKey)
  }

  getToken = (cookiesKey: string) => {
    return getCookie(cookiesKey)
  }

  ///////blockpass login///////
  public setAuthenticated(value: boolean) {
    this.isAuthenticated = value
  }

  public setOpenSessionExpired(value: boolean) {
    this.openSessionExpired = value
  }

  




  // ---------------------------------------------//
  //  Life cycle
  // ---------------------------------------------//
  async start() {
    // this._rootStore.on(EV_BROWSER_MSG, this._onBrowserEvent)
  }

  async stop() {
    // this._rootStore.off(EV_BROWSER_MSG, this._onBrowserEvent)
  }
}

export default AuthStore
