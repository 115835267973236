import { useObserver } from 'mobx-react'
import { Typography, CountryFlag, Chip } from '@blockpass-org/ui'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
// import useRootStore from '@hooks/useRootStore'
import Avatar from '@mui/material/Avatar'
import { DEFAULT_AVATAR } from '@/constants/constants'
import countries from '../../constants/countries.json'
import get from 'lodash/get'
import COLOR from '@theme/colors'
import DashboardTooltip from '../DashboardTooltip'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: 120,
    textAlign: 'left',
    padding: '0px',
    display: 'flex',
    margin: 'auto !important',
    backgroundColor: COLOR.commonColor.white,
  },
  avatar: {
    width: '120px !important',
    height: '120px !important',
  },
  info: {
    padding: '11px 16px',
    display: 'grid',
    alignItems: 'center',
  },
}))

const getCountryByCode3 = (code3) => {
  if (code3) {
    const country = countries.find((c) => {
      return c.code3.toLowerCase() === code3.toLowerCase()
    })
    if (country) return country
  }
  return 'n/a'
}

export const BrandComponent = (props: any) => {
  const classes = useStyles()
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  return useObserver(() => {
    const { company } = props
    const country = getCountryByCode3(
      get(company, 'kybInfo.businessDetails.jurisdiction', '')
    )

    return (
      <Box className={classes.container}>
        <Avatar
          alt="Blockpass"
          src={get(company, 'medias.logo', DEFAULT_AVATAR)}
          className={classes.avatar}
        />
        <Box
          className={classes.info}
          style={downMd ? { minWidth: 'auto' } : { minWidth: '570px' }}
        >
          <Typography variant={downMd ? 'h5' : 'h4'}>
            {get(company, 'kybInfo.businessDetails.brandName', '')}
          </Typography>
          <Typography
            variant="body1"
            color={COLOR.textColor.secondary}
            style={{ marginTop: 2 }}
          >
            {get(company, 'kybInfo.businessDetails.name', '')}
          </Typography>
          <Box style={{ marginTop: 8, maxWidth: 150 }}>
            <DashboardTooltip
              title={
                <Typography variant="body2" color={'white'}>
                  {get(country, 'name', '')}
                </Typography>
              }
              arrow
            >
              <Box>
                <Chip
                  size="small"
                  icon={
                    <div style={{ scale: '0.67' }}>
                      <CountryFlag iso2={get(country, 'code2', '')} />
                    </div>
                  }
                  label={
                    <Typography
                      variant="caption"
                      color={'#424C57'}
                      style={{ marginLeft: '-8px' }}
                    >
                      {get(country, 'name', '')}
                    </Typography>
                  }
                  sx={{
                    '&.MuiChip-root': {
                      borderRadius: '4px !important',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: 'fit-content',
                      padding: 0,
                      '& .MuiChip-iconSmall': {
                        margin: '0px !important',
                      },
                      maxWidth: 150,
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                  variant="outlined"
                />
              </Box>
            </DashboardTooltip>
          </Box>
        </Box>
      </Box>
    )
  })
}

export default BrandComponent
