/* eslint-disable */
// prettier-ignore
export default {
  "txPersonalAttributeDesc": "您可以在入职期间向用户索取其他信息。这些属性未通过BlockPass验证。",
  "txt404": "您要寻找的页面找不到！",
  "txtAML": "AML",
  "txtAMLCheck": "AML检查",
  "txtAMLDesc1": "筛选针对PEP/制裁列表和不利媒体的全球数据库，并确定您的客户是否在政治上暴露，批准或具有不利的媒体资料。",
  "txtAMLDesc2": "此支票要求用户提供其全名和出生日期。",
  "txtAPIAndWebhooks": "API和Webhooks",
  "txtAccountDeletion": "帐户删除",
  "txtAccountDeletionDesc": "删除移动和网络帐户",
  "txtAction": "行动",
  "txtActive": "积极的",
  "txtActivityLogs": "活动日志",
  "txtAdd": "添加",
  "txtAddCompany": "添加公司",
  "txtAddNewCompany": "添加新公司",
  "txtAddNewsContent": "添加新闻",
  "txtAddNotification": "添加通知",
  "txtAddPaymentMethod": "添加付款方式",
  "txtAddPaymentMethodFail": "这里有问题。请稍后再添加付款方式。",
  "txtAddPaymentMethodSuccessfully": "成功添加了您的支付卡",
  "txtAddRichContent": "添加丰富的内容",
  "txtAddTag": "添加标签",
  "txtAddTagPlaceholder": "键入标签的标签...",
  "txtAddTagSuccess": "“ <0>”标签成功添加了。",
  "txtAddingTag": "添加标签",
  "txtAdmin": "行政",
  "txtAdminConsole": "管理控制台",
  "txtAdminSettingsUpdateSuccess": "管理设置成功更新。",
  "txtAdministration": "行政",
  "txtAdvancedApprovalDesc": "高级KYC机器人：",
  "txtAdvancedApprovalDesc1": "一组10多个规则以自动批准或拒绝用户。",
  "txtAdvancedApprovalTooltip": "Advanced KYC机器人不可用于此计划。如果您想启用它，请与我们联系。",
  "txtAfterReturn": "API返回数据后",
  "txtAgeRestriction": "年龄限制",
  "txtAgeRestrictionDesc1": "您可以根据申请人的年龄排除申请人。",
  "txtAgeRestrictionDesc2": "申请人至少必须是",
  "txtAgeRestrictionDesc3": "年龄。",
  "txtAllPlanFeatureHeader": "所有计划都包括以下功能",
  "txtAllTimeAreUTC": "（一直都是UTC）",
  "txtAllowed": "允许",
  "txtAllowedCountries": "允许的国家",
  "txtAnnouncements": "公告",
  "txtAnyoneWithTheLink": "任何有链接的人",
  "txtAnyoneWithTheLinkDesc1": "具有此链接的任何人都可以注册您的服务。",
  "txtAnyoneWithTheLinkDesc2": "此页面允许申请人将其KYC信息发送到您的仪表板。将链接复制并粘贴到电子邮件或您要分享的任何地方。",
  "txtApiKey": "API键",
  "txtApiKeyCopied": "您的API键复制",
  "txtApiKeyDeleteDesc": "删除后，无法恢复API键。如果您不完全确定可以删除键，则可以将状态设置为无活动。",
  "txtApiKeys": "API键",
  "txtApply": " 申请。",
  "txtApproveAdvanced": "高级KYC机器人",
  "txtApproveBasic": "KYC机器人",
  "txtApproveDesc1": "您可以选择审核方式来节省时间。\n",
  "txtApproveDesc2": "建议大量用户使用KYC机器人。看",
  "txtApproveDesc3": "更多细节",
  "txtApproveDesc4": " 在文档中",
  "txtApproveFreeManual": "手册：手动批准或拒绝每个用户。选择一个计划来加快您的入职过程。",
  "txtApproveNone": "手动审查",
  "txtAssignCompanyToService": "添加公司信息",
  "txtAssignRole": "分配的角色",
  "txtAssignUserRole": "分配的角色",
  "txtAttributeDataDesc": "一旦档案存档，仅保留元数据，就会删除身份属性。",
  "txtAttributesConfig": "属性配置",
  "txtAttributesConfigDesc": "配置用户配置文件中可用的属性",
  "txtAttributesGroupConfig": "属性组配置",
  "txtAttributesGroupConfigDesc": "配置用户配置文件中可用的属性组",
  "txtAutoApproval": "自动批准",
  "txtAutoArchive": "自动存档用户的个人资料",
  "txtAutoArchiveTooltip": "自动存档用户的个人资料@@ ar_ch_ti_me @@ API返回数据后",
  "txtAutoTranslate": "（自动翻译）",
  "txtAutomationRules": "自动化规则",
  "txtAvatar": "头像",
  "txtAvaxAddress": "雪崩地址",
  "txtBack": "后退",
  "txtBasicApprovalDesc": "KYC机器人：",
  "txtBasicApprovalDesc1": "自动批准用户清晰的检查。",
  "txtBasicInfo": "基本信息",
  "txtBestPractices": "最佳实践",
  "txtBilling": "计费",
  "txtBillingInfo": "账单信息",
  "txtBillingInfomation": "账单信息",
  "txtBillingOwner": "帐单所有者",
  "txtBinanceAddress": "Binance（BSC）地址",
  "txtBitcoinAddress": "比特币地址",
  "txtBlockpass": "Blockpass",
  "txtBlockpassKycRules": "BlockPass KYC规则",
  "txtBpKYCRules": "BlockPass KYC规则",
  "txtBrowse": "浏览",
  "txtBtcAdress": "比特币地址",
  "txtBusinessInfomation": "商业信息",
  "txtBusinessTerms": "业务条款",
  "txtCanNotSetTagsOnChainKYCForMultipleCryptoService": "无法为服务设置标签OnChainKyC有多个加密字段",
  "txtCancel": "取消",
  "txtCardHolderName": "持卡人姓名",
  "txtCardInfomation": "卡信息",
  "txtCardanoAddress": "卡尔达诺（ADA）地址",
  "txtCaution": "注意！",
  "txtCautionDesc1": "如果您目前正在登上用户，则不建议更改计划。请与我们联系以寻求帮助。",
  "txtCautionDesc2": "否则，您可以继续进行配置。",
  "txtCertificatesOrderConfig": "证书订单配置",
  "txtCertificatesOrderConfigDesc": "在用户配置文件中配置证书订单",
  "txtChangeBillingPlan": "更改计费计划",
  "txtChangePlan": "更改计划",
  "txtChangeWebhook": "更新的Webhook",
  "txtChartEmptyTooltip": "当用户使用您的服务时，该图表将填充。",
  "txtCheckDebugger": "检查调试器",
  "txtCheckDebuggerDesc": "通过检查ID进行搜索检查。检查通常会发出证书",
  "txtChecksConfig": "检查配置",
  "txtChecksConfigDesc": "配置属性和证书定义检查",
  "txtChinese": "中国人",
  "txtClickOrDragToUpload": "单击或拖动以上载徽标",
  "txtClientId": "客户ID",
  "txtClientIdExisted": "客户ID已经存在，请使用其他客户ID",
  "txtClientIdTooltip": "您服务的唯一标识符。\n它是自动基于的\n关于您的服务名称",
  "txtClose": "关闭",
  "txtCodeHasBeenCopied": "代码已复制",
  "txtCodeSample": "代码样本",
  "txtCompany": "公司",
  "txtCompanyAddress": "公司地址",
  "txtCompanyAddressCity": "城市",
  "txtCompanyAddressCountry": "国家",
  "txtCompanyAddressLine1": "地址第一行",
  "txtCompanyAddressLine1IsRequired": "公司地址第1行是必需的。",
  "txtCompanyAddressLine2": "地址第2行（可选）",
  "txtCompanyAddressPostalCode": "邮政编码",
  "txtCompanyAddressPostalCodeRequired": "需要邮政编码。",
  "txtCompanyAddressState": "状态",
  "txtCompanyCity": "城市",
  "txtCompanyCountryIsRequired": "需要国家",
  "txtCompanyCustomRequest": "信息",
  "txtCompanyEmailIsRequired": "需要公司电子邮件。",
  "txtCompanyGroup": "3.公司",
  "txtCompanyInfo": "公司信息",
  "txtCompanyInfomation": "公司信息",
  "txtCompanyName": "公司名称",
  "txtCompanyNameIsRequired": "需要公司名称",
  "txtCompanyNameRepIsRequired": "需要公司代表的名称。",
  "txtCompanyNumber": "公司编号",
  "txtCompanyNumberIsRequired": "需要公司编号",
  "txtCompanyPhoneRepIsRequired": "需要公司代表的电话。",
  "txtCompanyPostalCode": "邮政编码",
  "txtCompanyRepresentative": "公司代表的姓名",
  "txtCompanyState": "状态",
  "txtCompanyVAT": "税或增值税编号",
  "txtCompanyVATNumber": "税或增值税编号",
  "txtCompanyVATNumberIsRequired": "需要公司增值税号码",
  "txtConfiguration": "配置",
  "txtConfirm": "确认",
  "txtConfirmDeleteCompanyInfo": "如果您返回上一步，将删除任何输入的公司信息。",
  "txtConnectWithUs": "联系我们",
  "txtContactSale": "联系销售",
  "txtContactSaleTeam": "联系我们的销售团队",
  "txtContactSupport": "联系销售",
  "txtContent": "内容",
  "txtContinue": "继续",
  "txtContinueToConfigureService": "继续配置您的服务",
  "txtCopied": "复制！",
  "txtCopy": "复制",
  "txtCopyAndClose": "复制并关闭",
  "txtCopyApiKeyWarning": "关闭弹出窗口后，您将无法尽快检索它。将其存放在安全的地方。\n如果丢失，您将必须生成一个新密钥。",
  "txtCopyLink": "复制链接",
  "txtCopyright": "©BlockPass",
  "txtCount": "数数",
  "txtCountryRestriction": "国家限制",
  "txtCountryRestrictionDesc1": "您可以将居民排除在某些国家 /地区或仅接受其他国家的居民。此选项要求用户提供其住宅地址。",
  "txtCountryRestrictionDesc2": "这里提供的国家只是一个例子。您应该与您的合规官核对各国限制。",
  "txtCountryRestrictionDescAllow": "只允许居民",
  "txtCountryRestrictionDescExclude": "排除居民",
  "txtCoutryOfIncorporation": "建立国家",
  "txtCreate": "创造",
  "txtCreateAPIKey": "创建API密钥",
  "txtCreateAPIKeyFooter1": "公开暴露API密钥可能会导致用户的个人数据受到损害。",
  "txtCreateAPIKeyFooter2": "您必须关注",
  "txtCreateAPIKeyFooter3": " 用于安全使用API​​键。",
  "txtCreateAccount": "创建了新帐户",
  "txtCreateApiKeySuccess": "创建新的API密钥",
  "txtCreateBillingPlan": "创建计费计划",
  "txtCreateCertificate": "创建了新证书",
  "txtCreateCheckConfig": "创建检查配置",
  "txtCreateCompany": "创建公司",
  "txtCreateFlowConfig": "创建流程配置",
  "txtCreateIdentityField": "创建的身份字段",
  "txtCreateKYCSettings": "创建了KYC设置",
  "txtCreateNewTag": "创建标签",
  "txtCreateRuntimeCfg": "创建的运行时配置",
  "txtCreateService": "创建了新服务",
  "txtCreateServiceDashboard": "创建服务",
  "txtCreateServiceSuccess": "新服务成功创建了",
  "txtCreateVersion": "创建了新的API版本",
  "txtCreateWebhook": "创建的Webhook",
  "txtCryptoAddresses": "加密地址",
  "txtCurrentPlan": "当前计划",
  "txtCustomRegistrationUrl": "自定义注册URL",
  "txtCustomUrlInvalid": "此URL无效。",
  "txtCustomiseAppLink": "移动的应用链接",
  "txtDAY": "天",
  "txtDOB": "出生日期",
  "txtDashboard": "仪表板",
  "txtDataExport": "数据导出",
  "txtDate": "日期",
  "txtDelete": "删除",
  "txtDeleteAPIKey": "删除API键",
  "txtDeleteApiKeySuccess": "API密钥已删除",
  "txtDeleteBulkIdentityRecordConfirmMessage": "您确定要删除这些帐户吗？",
  "txtDeleteThisApiKey": "删除此API键",
  "txtDeleteVersion": "删除API版本",
  "txtDeleteWebhook": "删除Webhook",
  "txtDescription": "描述",
  "txtDescriptionIsRequired": "请输入此API密钥的描述",
  "txtDetachPaymentMethod": "分离付款方式",
  "txtDetails": "细节",
  "txtDiscard": "丢弃",
  "txtDiscardRichContent": "丢弃丰富的内容",
  "txtDisplay": "展示",
  "txtDoNotSendAddMember": "您无权邀请新成员。请联系服务所有者。错误代码403。",
  "txtDocs": "文档",
  "txtDocumentation": "文档",
  "txtDonNotHaveService": "您在这家公司没有任何服务",
  "txtDontHavePaymentMethod": "您没有任何付款方式，请在下面添加付款方式",
  "txtDownload": "下载",
  "txtDownloadLogs": "下载日志",
  "txtDrivingLicense": "驾驶执照",
  "txtDropFileHere": "在这里放下徽标",
  "txtEditClientId": "编辑客户端ID",
  "txtEditClientIdWarning": "该客户端ID以后不能更改，并且必须是唯一的。",
  "txtEditConfig": "编辑配置",
  "txtEditNewsContent": "编辑新闻",
  "txtEditSearch": "编辑搜索",
  "txtEmail": "电子邮件",
  "txtEmailAddress": "电子邮件地址",
  "txtEmailRepresentative": "公司代表的电子邮件",
  "txtEmailWrongFormat": "错误的电子邮件格式",
  "txtEnable": "使能够",
  "txtEnableApiKeys": "启用API键",
  "txtEnableApiKeysDes": "允许客户使用API​​密钥来访问用户的数据。",
  "txtEnableEndUserRegistrations": "启用最终用户注册",
  "txtEnableEndUserRegistrationsDes": "允许最终用户使用此服务注册验证。",
  "txtEnableKycDashboard": "启用KYC仪表板",
  "txtEnableKycDashboardDes": "允许客户访问KYC仪表板。",
  "txtEnableService": "启用服务",
  "txtEnableServiceDes": "此选项很危险；经常打开和关闭服务可能会导致严重错误。",
  "txtEndDate": "结束日期",
  "txtEnglish": "英语",
  "txtEnterpriseDesc1": "每月至少100个配置文件",
  "txtEnterpriseDesc2": " 有关更多信息。",
  "txtEnterpriseSentDesc1": "感谢您选择企业计划。我们的销售团队将尽快与您联系，并根据您的要求配置服务。\n",
  "txtEnterpriseSentDesc2": "同时，请添加付款方式并完成您的帐单信息，需要启用您的计划",
  "txtErrorUnsupportFile": "不支持的文件",
  "txtEthAddress": "以太坊地址",
  "txtEthereumAddress": "以太坊地址",
  "txtEvent": "事件",
  "txtExample": "例子",
  "txtExcept": "除了",
  "txtExcluded": "排除",
  "txtExcludedCountries": "排除国家",
  "txtExpiryDate": "到期日",
  "txtExploreKyc": "探索您的KYC连接仪表板。",
  "txtExportServiceConfig": "导出服务配置",
  "txtFaceMatch": "面对比赛",
  "txtFaceMatchCheck": "面对匹配检查",
  "txtFaceMatchDesc1": "BlockPass将用户ID上的图片与注册期间请求的实时自拍进行了比较。",
  "txtFaceMatchDesc2": "此检查要求用户提供其身份文档和实时自拍照。",
  "txtFacematchCheck": "facematch检查",
  "txtFailedToSendMemberInvitation": "未能发送会员邀请",
  "txtFamilyName": "姓",
  "txtFaq": "常问问题",
  "txtFileNotSupport": "此文件不支持",
  "txtFillCompanyInfo": "根据下面的现有公司或添加新公司填写信息。",
  "txtFlowAddress": "流（流）地址",
  "txtFlowDebugger": "流量调试器",
  "txtFlowDebuggerDesc": "通过流ID进行搜索流。流可以包括多个检查",
  "txtFlowsConfig": "Flow Config",
  "txtFlowsConfigDesc": "配置检查定义流程",
  "txtForbidden": "您无权执行此操作",
  "txtFreePlanDescription": "您会收到未验证的身份。每天限制10个。",
  "txtFreeTrial": "免费试用",
  "txtFrench": "法语",
  "txtFunctionUnavailable": "功能暂时不可用",
  "txtGeneralInfomation": "一般信息",
  "txtGeoRestriction": "地理限制",
  "txtGetServiceDisplayFailed": "获取服务显示失败。",
  "txtGetServiceListFailed": "获取服务列表失败。",
  "txtGetServiceWebhookListFailed": "获取服务Webhooks失败。",
  "txtGivenName": "给定的名称",
  "txtGoToDashboard": "去仪表板",
  "txtGoToServiceDashboard": "去服务仪表板",
  "txtGoToServiceList": "之后",
  "txtGotoDashboardDesc1": "转到此服务的KYC Connect仪表板。",
  "txtGotoDashboardDesc2": "仪表板是您的团队可以在这里查看个人上传到您服务的身份。",
  "txtHelp": "帮助",
  "txtIdEngine": "ID引擎",
  "txtIdEngineDesc": "测试ID引擎OCR工具",
  "txtIdentityDesc1": "BlockPass验证护照，国家身份证和驾驶执照。",
  "txtIdentityDesc2": "要求用户拍摄其文档的现场照片。",
  "txtIdentityDesc3": "此选项要求用户提供其身份文件，全名和出生日期。",
  "txtIdentityDocument": "身份证件",
  "txtIdentityDocumentCheck": "身份文档检查",
  "txtIdentityLink1": "请参阅支持国家的清单",
  "txtImageTooBig": "图像太大了",
  "txtImgDimensionBetween10242048": "请在512x512和2048x2048像素之间上传图像",
  "txtImgDimensionRule": "PNG，JPEG。最小512x512像素，最大2048x2048像素。",
  "txtImpactChanging": "影响充电",
  "txtInactive": "不活动",
  "txtIncludeRawDataExceptPictureTooltip": "返回元数据和身份属性除图片外",
  "txtIncludeRawDataIncludePictureTooltip": "返回元数据和身份属性，包括图片",
  "txtIncludeRawDataTooltip": "返回元数据和身份属性除图片外",
  "txtIncluding": "包括",
  "txtIncorrectFormat": "字段应仅包含普通 /上层字符，数字和下划线（_）",
  "txtInfomation": "信息",
  "txtInputYourServiceName": "输入您的服务名称",
  "txtInternalServerError": "服务器上发生了意外错误",
  "txtInvalidEmailFormat": "无效的电子邮件格式",
  "txtInvalidEmails": "无效的电子邮件",
  "txtInvalidParams": "请求包含无效参数，请重新检查您的输入",
  "txtInvalidURL": "URL无效",
  "txtInvalidUrl": "这个网址无效",
  "txtInvitationRemoved": "邀请已删除",
  "txtInvitationResent": "邀请很讨厌",
  "txtInviteAlreadySent": "邀请已经发送",
  "txtInviteUser": "邀请用户",
  "txtIsCopiedToClipboard": "被复制到剪贴板。",
  "txtIsSuccess": "成功",
  "txtJapanese": "日本人",
  "txtJobId": "工作ID",
  "txtKYCBot": "KYC机器人",
  "txtKYCDashboardAccess": "KYC仪表板访问",
  "txtKybDashboard": "KYB仪表板",
  "txtKybService": "KYB服务",
  "txtKybServiceDesc": "KYB代表“知道您的业务”。创建KYB服务以验证企业的身份。",
  "txtKybServiceName": "KYB服务名称",
  "txtKybServices": "KYB服务（WIP）",
  "txtKycDashboard": "KYC仪表板",
  "txtKycService": "KYC服务",
  "txtKycServiceDesc": "KYC代表“了解您的客户”。创建KYC服务以验证个人的身份。",
  "txtKycServiceName": "KYC服务名称",
  "txtKycServices": "KYC服务",
  "txtLast180Days": "最后180天",
  "txtLast30Days": "最近30天",
  "txtLast7Days": "最近7天",
  "txtLast90Days": "最近90天",
  "txtLastMonth": "上个月",
  "txtLastRunAt": "最后一个",
  "txtLatestInvoice": "最新发票",
  "txtLatestInvoiceDesc": "发票每月发送给帐单所有者电子邮件地址。您还可以下面下面下载下面发行的发票。",
  "txtLearnMoreAboutApiKey": "了解有关BlockPass API的更多信息",
  "txtLengthTooShort": "场长太短",
  "txtLink": "关联",
  "txtListDeleteEmail": "您要删除的电子邮件列表，由逗号分隔，多达50封电子邮件",
  "txtListOfServices": "服务列表",
  "txtListingRunningCheck": "清单运行支票",
  "txtListingRunningCheckDesc": "列出运行支票降低",
  "txtLive": "居住",
  "txtLockUser": "锁定用户",
  "txtLogin": "登录",
  "txtLogo": "标识",
  "txtLogoDesc": "PNG，JPEG，JPG。\nMin 512x512 PX，最大2048x2048 PX",
  "txtLogoRestriction": "图像大小必须在1024 x 1024和2048 x 2048像素之间",
  "txtLogoSizeError": "您刚刚上传的徽标不是正确的大小。",
  "txtLogout": "登出",
  "txtLogs": "日志",
  "txtLongDescription": "详细描述",
  "txtLongDescriptionTooltip": "服务的完整说明（仅无需文本）",
  "txtMONTH": "月",
  "txtMarkerPlace": "市场",
  "txtMarkerPlaceDesc": "管理市场条目",
  "txtMarketPlace": "市场 -  WIP",
  "txtMarketplace": "市场",
  "txtMarketplaceSettingUpdateSuccess": "市场设置成功更新。",
  "txtMaticAddress": "多边形（matic）地址",
  "txtMaxEmailsExceeded": "每个删除最多50封电子邮件",
  "txtMetaDataOnlyTooltip": "仅返回元数据（配置文件状态，ID，...）",
  "txtMore": "更多的",
  "txtMoreDetails": "更多细节",
  "txtMoreInfomation": "更多信息",
  "txtName": "姓名",
  "txtNameOfCompany": "公司名称",
  "txtNameOfCompanyIsRequired": "需要公司名称",
  "txtNameOnCard": "卡片上的名字",
  "txtNameOnCardIncomplete": "卡上的名称不完整。",
  "txtNameRepresentative": "公司代表的姓名",
  "txtNationalID": "国家身份证",
  "txtNationalId": "国家身份证",
  "txtNewCompany": "新公司",
  "txtNewsContent": "新闻内容",
  "txtNext": "下一个",
  "txtNoActivityLogs": "没有活动与您的搜索相匹配",
  "txtNoActivityLogsDesc": "尝试不那么具体或使用不同的关键字。",
  "txtNoApiKeyDesc": "需要一个API密钥来提取个人的身份，包括元数据，个人信息和图像。",
  "txtNoApiKeys": "没有API键",
  "txtNoCompanyAdd": "您尚未添加任何公司信息",
  "txtNoCompanyAddDesc": "单击下面添加第一个公司信息。",
  "txtNoCountryRestriction": "没有任何一个国家。使用搜索将一个国家添加到列表中",
  "txtNoFieldsAreUpdated": "没有更新字段",
  "txtNoInvoiceDesc1": "发票每月发送给帐单所有者电子邮件地址。",
  "txtNoInvoiceDesc2": "没有发行发票立即下载。",
  "txtNoServiceMatch": "没有服务与您的搜索相匹配",
  "txtNoServiceMatchDesc": "尝试不那么具体或使用不同的关键字。",
  "txtNoSetupCost": "没有设置成本",
  "txtNoUsageDetail": "没有数据显示",
  "txtNoUsageDetailDesc": "目前没有可用的统计数据。",
  "txtNone": "没有任何",
  "txtNoneApprovalDesc": "手动的：",
  "txtNoneApprovalDesc1": "批准或手动拒绝每个用户。",
  "txtNormalDesc1": "每月最多100个配置文件。",
  "txtNotStoreCard": "BlockPass不会存储您的卡详细信息。",
  "txtNotifications": "通知",
  "txtNotificationsDesc": "向移动用户发送推送通知",
  "txtOnboarding": "入职",
  "txtOnboardingGroup": "2.入职",
  "txtOnboardingSetting": "入职设置",
  "txtOnly": " 仅有的",
  "txtOpenDashboard": "打开仪表板",
  "txtOverview": "概述",
  "txtPOA": "地址证明",
  "txtPage403Desc1": "您没有许可\n 访问此服务",
  "txtPage403Desc2": "请返回服务并找到合适的服务或联系服务所有者。",
  "txtParametersValidationError": "请求包含无效参数，请重新检查您的输入",
  "txtPassport": "护照",
  "txtPassword": "密码",
  "txtPayAsYouGo": "现收现付",
  "txtPaymentMethod": "付款方式",
  "txtPaymentMethodDeleted": "付款方式无法检索或已删除。",
  "txtPermissionDenied": "您无权执行此操作",
  "txtPersonalAtt": "个人属性",
  "txtPersonalAttributes": "个人属性",
  "txtPhoneNumber": "电话号码",
  "txtPhoneNumberWrongFormat": "电话号码必须是数字",
  "txtPhoneRepresentative": "公司代表的电话号码",
  "txtPickYourServiceName": "为您的服务选择名称",
  "txtPlan": "计划",
  "txtPlanConfig": "计划配置",
  "txtPlanConfigDesc": "派人账单要求他们支付欠款的过程",
  "txtPlanDescription1": "小于@@ num_indent @@每天的身份和每个业务。\n",
  "txtPlanDescription2": "处理时间超过24小时。标准",
  "txtPleaseCopyAPIKey": "请复制API密钥",
  "txtPleaseReplacePaymentMethod": "请更换付款方式。",
  "txtPostalCodeWrongFormat": "格式是错误的。",
  "txtPrivacy": "隐私",
  "txtPrivacyPolicy": "隐私政策",
  "txtProfile": "轮廓",
  "txtProofOfAddress": "地址证明",
  "txtProvideNameOfService": "提供服务名称",
  "txtPublicLinkDesc": "互联网上使用链接的任何人都可以提交其KYC个人资料。",
  "txtPublishService": "已发布服务",
  "txtPushNotification": "推送通知",
  "txtRedirect": "重定向",
  "txtRemove": "消除",
  "txtRemoveInvitation": "删除邀请",
  "txtRemoveTagConfirmContent": "您确定要删除此标签吗？",
  "txtRemoveTagConfirmTitle": "删除“ <0>”标签",
  "txtRemoveTagSuccess": "“ <0>”标签已删除。",
  "txtRemoveUser": "删除用户",
  "txtReplace": "代替",
  "txtReplacePaymentMethod": "更换付款方式",
  "txtReplacePaymentMethodDesc1": "新卡将用于您的所有服务",
  "txtReplacePaymentMethodDesc2": "注意，付款方式更改将影响许多使用此的服务\n付款方式。",
  "txtReport": "报告",
  "txtRepresentative": "代表",
  "txtRequestAccess": "申请进入",
  "txtRequestEnterprise": "要求企业计划",
  "txtRequestEnterpriseSent": "要求发送企业计划！",
  "txtRequired": "* 必需的",
  "txtRequiredOneIdentityDocument": "请选择至少一份身份文档",
  "txtRequirementPlaceHolder": "告诉我们更多有关您的要求和时间表的信息",
  "txtResAddress": "住宅地址",
  "txtResidentialAddress": "住宅地址",
  "txtRestricted": "受限制的",
  "txtRestrictedDesc1": "只有在您的网站上进行身份验证的人才能注册。",
  "txtRestrictedDesc2": "如果要将用户提交给小部件，请选择此选项。",
  "txtRestrictedDesc3": "请输入网站上安装小部件的URL。",
  "txtRestrictedDescLink": "文档中的更多信息。",
  "txtRestrictedLinkDesc1": "强制用户登录您的网站，然后才能提交其KYC个人资料。",
  "txtRestrictedLinkDesc2": "如果要将用户转置到小部件，请选择此选项。",
  "txtRestrictedLinkDesc3": "文档中的更多信息。",
  "txtReturnAttributeData1": "返回元数据和身份属性",
  "txtReturnAttributeData2": "图片",
  "txtReturnAttributeDataExceptPicture1": "返回元数据和身份属性",
  "txtReturnAttributeDataExceptPicture2": "图片",
  "txtReturnMetaData1": "返回",
  "txtReturnMetaData2": "元数据（个人资料状态，ID，...）",
  "txtReturnToServices": "返回服务",
  "txtRole": "角色",
  "txtRuleArchiveTime": "一个从1到9999的数字",
  "txtRunningFlowConfig": "运行流程配置",
  "txtRunningFlowConfigDesc": "运行流程配置下降",
  "txtRuntimeConfig": "运行时配置",
  "txtRuntimeConfigDesc": "配置控制台和仪表板参数",
  "txtRussian": "俄语",
  "txtSAVE": "节省",
  "txtSampleCode": "示例代码",
  "txtSave": "节省",
  "txtSaveChange": "保存更改",
  "txtSaveChanges": "保存更改",
  "txtScheduleDeleteProfilesSuccess": "成功安排删除配置文件",
  "txtScheduleForBulkDeletion": "批量删除时间表",
  "txtScope": "范围",
  "txtSearchActivityLogs": "搜索活动日志",
  "txtSearchCountry": "搜索国家",
  "txtSearchPlaceHolder": "搜索...",
  "txtSeconds": "第二",
  "txtSeeList": "请参阅列表。",
  "txtSelectACryptoAddress": "选择一个加密地址",
  "txtSelectAPlan": "选择一个计划",
  "txtSelectAPlanToUnlock": "升级您的计划",
  "txtSelectIdentityAttributes": "选定的身份属性",
  "txtSelectPlan": "选择方案",
  "txtSelfie": "自拍照",
  "txtServiceClientIdIsExisted": "客户ID已经存在，请使用其他客户ID",
  "txtServiceCompanyInfomation": "的公司信息",
  "txtServiceDetail": "服务细节",
  "txtServiceEmailIsRequired": "支持电子邮件需要",
  "txtServiceListInvalidPage": "无效分页值",
  "txtServiceLogo": "服务徽标",
  "txtServiceLogoDesc": "您可以立即上传服务徽标，也可以在以后在设置中更新。",
  "txtServiceName": "服务名称",
  "txtServiceNameIsRequired": "需要服务名称",
  "txtServiceNameOrClientId": "服务名称或客户ID",
  "txtServiceNameOrClientIdPlaceholder": "输入服务名称或客户端ID",
  "txtServiceNameTooltip": "为您的服务选择一个名称（最大64个字符）",
  "txtServicePortalIsRequired": "需要支持门户",
  "txtServiceTags": "服务标签",
  "txtServiceTagsDes": "为此服务添加或删除任何标签",
  "txtServices": "服务",
  "txtServicesUsage": "最近的服务用法",
  "txtServicesUsageDesc": "派人账单要求他们支付欠款的过程",
  "txtSettings": "设置",
  "txtShareableDesc": "此页面允许申请人将其KYC信息发送到您的仪表板。将链接复制并粘贴到电子邮件或您要分享的任何地方。",
  "txtShareableLink": "可共享链接",
  "txtShareableLinkMustBeSetIfUsingRestrictedShareableMethod": "缺少可共享的链接",
  "txtShortDescription": "简短的介绍",
  "txtShortDescriptionTooltip": "一个描述您服务的简短文字（仅仅是未格式化的文本）",
  "txtShowLess": "显示较少",
  "txtShowMore": "展示更多",
  "txtShowRefId": "表现出来",
  "txtShowSession": "表演会话",
  "txtShowThisService": "显示此服务",
  "txtShowThisServiceDes": "打开或关闭此服务以在市场上显示。",
  "txtSolanaAddress": "Solana（Sol）地址",
  "txtStartDate": "开始日期",
  "txtStartingAt": "开始于",
  "txtStartingAtTooltip": "其他信息不可用。",
  "txtStatus": "地位",
  "txtStringMaxLengthError": "必须长<0>或更少的字符",
  "txtStripeError": "验证帐单信息时出现问题。请与我们联系以寻求支持。",
  "txtSubscribe": "订阅",
  "txtSubscribeTo": "订阅我们的新闻",
  "txtSubscribed": "订阅",
  "txtSubscription": "订阅",
  "txtSupport": "支持",
  "txtSupportPortal": "您的支持Web门户网站的URL",
  "txtSupportPortalTooltip": "您的客户服务门户网站的URL",
  "txtSupportrEmail": "您的支持电子邮件",
  "txtSupportrEmailTooltip": "客户支持电子邮件地址。如果您不提供支持门户网址，则使用此地址。默认地址是support@blockpass.org",
  "txtTags": "标签",
  "txtTaxNumberWrongFormat": "无效的格式。",
  "txtTeam": "团队",
  "txtTestService": "测试服务",
  "txtTheRequestWasMadeButNoResponseWasReceived": "网络错误",
  "txtThisFieldIsRequired": "此字段是必需的。",
  "txtTitleAllow": "允许的国家",
  "txtTitleExclude": "排除国家",
  "txtToGetStart": "要开始，请登录。",
  "txtToday": "今天",
  "txtTools": "工具",
  "txtTooltipAddressCountryRestriction": "如果启用了国家限制，则需要地址",
  "txtTooltipAddressProofOfAddress": "如果选择地址证明，则需要地址",
  "txtTotal": "全部的",
  "txtTotalForMonth": "总计一个月",
  "txtTypeSomthing": "输入一些东西...",
  "txtUSDperCheck": "美元/支票",
  "txtUnAuthorized": "会话已过期或无效，请再次登录",
  "txtUnauthorized": "会话已过期或无效，请再次登录",
  "txtUnlimittedCollaborator": "无限合作者",
  "txtUnsubscribe": "退订",
  "txtUpdate": "更新",
  "txtUpdateAPIKey": "更新API密钥",
  "txtUpdateApiKey": "更新API密钥",
  "txtUpdateApiKeySuccess": "API密钥更新了",
  "txtUpdateBillingPlanDisplay": "更新计费计划显示",
  "txtUpdateBillingPlanKycBot": "更新KYC机器人配置",
  "txtUpdateBillingPlanVerification": "更新KYC检查配置",
  "txtUpdateCertificate": "更新的证书",
  "txtUpdateCheckConfig": "更新检查配置",
  "txtUpdateCompany": "更新公司",
  "txtUpdateCompanyInfoDesc": "更新公司信息。",
  "txtUpdateFlowConfig": "更新流程配置",
  "txtUpdateIdentityField": "更新的身份字段",
  "txtUpdateInfo": "更新信息",
  "txtUpdateKYCSettings": "更新了KYC设置",
  "txtUpdatePlanSuccess": "成功更新服务计费计划",
  "txtUpdateRuntimeCfg": "更新的运行时配置",
  "txtUpdateService": "更新的服务",
  "txtUpdateServiceSuccess": "所有更改均保存",
  "txtUpdateWebhook": "更新Webhook",
  "txtUploadALogo": "上传徽标",
  "txtUrlPrivacyPolicy": "您的隐私政策的URL",
  "txtUrlPrivacyPolicyTooltip": "在用户注册之前将显示指向您隐私政策的链接",
  "txtUrlTermsAndConditions": "您的条款和条件的URL",
  "txtUrlTermsAndConditionsTooltip": "用户注册之前将显示指向您条款和条件的链接",
  "txtUsage": "用法",
  "txtUsageMetrics": "用法指标",
  "txtUserDebugger": "用户调试器",
  "txtUserDebuggerDesc": "通过电子邮件或支持搜索用户",
  "txtUserId": "用户身份",
  "txtUserSessionExpired": "用户会话过期",
  "txtUserSessionExpiredDesc": "您已注销。请再次登录",
  "txtUsername": "用户名",
  "txtUsernameOrEmail": "用户名或电子邮件",
  "txtUsernameOrEmailPlaceholder": "输入用户名或电子邮件",
  "txtVATNumber": "增值税编号",
  "txtVerification": "验证",
  "txtVerificationGroup": "1.验证",
  "txtVerifications": "验证",
  "txtViewDetails": "查看详情",
  "txtVisitThePage": "访问页面",
  "txtWEEK": "星期",
  "txtWarningApprovalMode": "请在移至下一步之前确认批准方式",
  "txtWavesAddress": "波浪地址",
  "txtWebhooks": "Webhooks",
  "txtWebsite": "您网站的URL",
  "txtWebsiteIntegration": "小部件集成",
  "txtWebsiteTooltip": "链接到您的官方网站",
  "txtWebsiteUrlIsRequired": "请填写此字段",
  "txtWelcomeBody": "创建，配置和监视服务以验证客户的身份。",
  "txtWelcomeTitle": "欢迎来到BlockPass Admin Console",
  "txtWelcomeTo": "欢迎来到",
  "txtWhoCanResgister": "谁可以注册？",
  "txtWillBeDeleted": "将被删除。",
  "txtYEAR": "年",
  "txtYesterday": "昨天",
  "txtYourApiKey": "您的API键",
  "txtYourCard": "您的卡",
  "txtYourCompanyInfo": "您的公司信息",
  "txtYourEmailAddress": "您的电子邮件地址",
  "txtYourLink": "您的链接",
  "txtYourLinkCopiedToClipboard": "您的链接复制到剪贴板",
  "txtYourPaymentMethod": "您的付款方式",
  "txtYourPaymentMethodGroup": "4.付款方式",
  "txtYourServiceConfigCopiedToClipboard": "您的服务配置复制到剪贴板",
  "txtYourServiceCreated": "您的服务已创建",
  "txtYourServiceLink": "您的服务链接",
  "txtYourWebsite": "https://your-website.com",
  "txtYourWebsiteUrl": "您的网站URL",
  "txtYourWebsiteUrlDesc": "请输入安装小部件的网站URL",
  "txtdelete": "删除",
  "txtdonwloadMonitorReport": "DONWLOAD监视器报告",
  "txtsettingUpdated": "设置更新"
} // eslint-disable-line no-template-curly-in-string