import styled from 'styled-components'

import { COLOR } from "@theme/colors";
export const HeaderWrapper = styled.header`
  // background-color: ${COLOR.commonColor.primary};
  max-height: max-content;
  display: flex;
  // padding: 16px 32px 16px 32px;
  // @media (max-width: 1599px) {
  //   display: flex;
  //   // max-height: 80px;
  // }

  // @media (max-width: 1320px) {
  //   // max-height: 160px;
  //   display: block;
  // }

  // @media (max-width: 420px) {
  //   // max-height: 240px;
  //   display: block;
  // }
  
`

export const HeaderTitle = styled.div`
  margin: 32px auto;
`
