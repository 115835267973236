import React from 'react'
import { useObserver } from 'mobx-react'
import { COLOR } from '@theme/colors'
import CircularProgress from '@mui/material/CircularProgress'
export const SplashPage = (props: any) => {
  const activeColor = COLOR.commonColor.primary
  return useObserver(() => {
    return (
      <div style={{
        position: 'relative',
        overflow: 'visible',
        zIndex: 999,
        top: '50vh',
        left: '50wh',
        marginTop: '-64px',
        marginLeft: '-0px'
      }}>
        <CircularProgress size={48} style={{ color: activeColor }} />
      </div >
    )
  })
}

export default SplashPage
