
import { useObserver } from "mobx-react";
import { Typography } from "@blockpass-org/ui";
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles';
// import useRootStore from '@hooks/useRootStore'
import Grid from '@mui/material/Grid';
import CryptoIcon from "../CryptoIcon";

import ButtonCopy2 from "../ButtonCopy2";
import COLOR from '@theme/colors'
import get from 'lodash/get'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 344,
    height: 116,
    textAlign: 'center',
    // margin: 'auto',
    padding: '16px',
    backgroundColor: COLOR.commonColor.white,
    borderRadius: 8,
    border: '1px solid rgba(196, 196, 196, 1)'
  },
  containerFull: {
    maxWidth: 320,
    width: '100%',
    height: 116,
    textAlign: 'center',
    // margin: 'auto',
    padding: '12px',
    backgroundColor: COLOR.commonColor.white,
    borderRadius: 8,
    border: '1px solid rgba(196, 196, 196, 1)'
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  }

}))
export const CryptoComponent = (props: any) => {
  const classes = useStyles()
  // const rootStore = useRootStore();
  // const {
  //   LocalizationStore,
  // } = rootStore;
  const theme = useTheme();
  let downSm = useMediaQuery(theme.breakpoints.down('sm'))
  return useObserver(() => {
    const { crypto, modeFull } = props
    let cryptoObj = crypto
    const publicKey = get(cryptoObj, 'publicKey', '')
    let sortPublicKey = publicKey
    if (publicKey.length > 12) {
      let p = publicKey.substring(0, 6);
      let p1 = publicKey.substring(publicKey.length - 6, publicKey.length);
      sortPublicKey = p + '...' + p1
    }


    return (
      <Box className={modeFull ? classes.containerFull : classes.container} style={modeFull && downSm ? { width: 'auto' } : {}}>
        <Box>
          <Grid container spacing={modeFull ? 1.5 : 2}>
            <Grid item xs={2}>
              <Box style={{ border: "1px solid rgba(196, 196, 196, 1)", height: '40px', width: '40px', borderRadius: 4, display: 'flex' }}>
                <Box style={{ margin: '8px' }}>
                  <CryptoIcon chainId={get(cryptoObj, 'chainId', '')} ticker={get(cryptoObj, 'ticker', '')} />
                </Box>

              </Box>
            </Grid>
            <Grid item xs={10} style={{ paddingLeft: 12 }}>
              <Box style={{ textAlign: 'left', height: '100%', alignContent: 'center' }}>
                <Typography variant="subtitle1">{get(cryptoObj, 'chainId', '')}</Typography>
                <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '16px' }}>
                  <Typography variant="caption" color="textSecondary">{sortPublicKey}</Typography>
                  <ButtonCopy2 size={'small'} copyValue={get(cryptoObj, 'publicKey', '')} />
                </Box>
              </Box>
            </Grid>

          </Grid>
        </Box>
        <Box style={{ textAlign: 'left', paddingTop: 16 }}>
          <div className={classes.truncate}>
            <Typography variant="body2" color="textSecondary">{get(cryptoObj, 'description', '')}</Typography>
          </div>
        </Box>
      </Box >
    );
  });
};

export default CryptoComponent;