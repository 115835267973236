import { useObserver } from 'mobx-react'
import { Typography } from '@blockpass-org/ui'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
// import useRootStore from '@hooks/useRootStore'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { RightArrowComponentIcon } from '@components/Icon/CommonIcons'
import COLOR from '@theme/colors'
import get from 'lodash/get'
import SocialIcon from '../SocialIcon'
import { SOCIAL_BASE_URL } from '@/constants/constants'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Link from '@mui/material/Link'

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 344,
    height: 40,
    textAlign: 'center',
    padding: '16px',
    backgroundColor: COLOR.commonColor.white,
    borderRadius: 8,
    border: '1px solid rgba(196, 196, 196, 1)',
    cursor: 'pointer',
  },
  containerFull: {
    maxWidth: 320,
    width: '100%',
    height: 40,
    textAlign: 'center',
    padding: '12px',
    backgroundColor: COLOR.commonColor.white,
    borderRadius: 8,
    border: '1px solid rgba(196, 196, 196, 1)',
    cursor: 'pointer',
  },
}))
export const SocialComponent = (props: any) => {
  const classes = useStyles()
  // const rootStore = useRootStore();
  // const {
  //   LocalizationStore,
  // } = rootStore;

  const theme = useTheme()
  let downSm = useMediaQuery(theme.breakpoints.down('sm'))

  return useObserver(() => {
    const { social, modeFull } = props
    const name = get(social, 'name', '').toLowerCase()
    const account = get(social, 'account', '')
    const url = SOCIAL_BASE_URL[name] + account

    return (
      <Box
        className={modeFull ? classes.containerFull : classes.container}
        style={modeFull && downSm ? { width: 'auto' } : {}}
      >
        <Link
          href={url.toString()}
          target="_blank"
          rel="noopener"
          underline="none"
        >
          <Grid container spacing={modeFull ? 1.5 : 2}>
            <Grid item xs={2}>
              <Box style={{ scale: '1.6667', marginTop: '8px' }}>
                <SocialIcon name={get(social, 'name', '')} color={true} />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box
                style={{
                  textAlign: 'left',
                  height: '100%',
                  alignContent: 'center',
                }}
              >
                <Typography variant="subtitle1">
                  {get(social, 'name', '')}
                </Typography>
                {/* <Typography variant="body2" color="textSecondary">{get(social, 'account', '')}</Typography> */}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                color="primary"
                component="span"
                // onClick={handleOnClick}
                sx={{
                  '&.MuiIconButton-root': {
                    borderRadius: '4px !important',
                    width: '40px !important',
                    height: '40px !important',
                  },
                }}
              >
                <RightArrowComponentIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Link>
      </Box>
    )
  })
}

export default SocialComponent
