// ===============================================================
//                            Configs
// ===============================================================

// Localization
export const localizations = {
  en: {},
}

// ===============================================================
//                             Store
// ===============================================================
export const storeDomains: any[] = [
  // {name, type}
]

export const storeCores: any[] = [
  // {name, type}
]

// ===============================================================
//                             Routes
// ===============================================================

// Page routes
// {
// key: 99,
// path: '/abc'
// extra: {}
// scope: 'authorize|unauthorize|<empty>'
// Component: PageComp
// }
export const pagesExtFunc = (routes: any) => routes
