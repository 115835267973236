const commonColor = {
  black: '#000',
  white: '#fff',
  primary: '#4388ff',
  accent: '#2D426B',
  error: '#EB5757',
  errorDisabled: '#EC9C9D',
  warning: '#F0C74A',
  success: '#27AE60',
  successDisabled: '#9AD6AE',
  disabled: '#9EC1FF',

  // basic color
  red: '#EB5757',
  yellow: '#F0C74A',
  green: '#27AE60',
  blue: '#4388FF',

  // textColor
  textPrimary: '#404042',
  textSecondary: '#717275',
  
  active: '#BDFFC9',
  inactive: '#FFB3BC',

  mainBackground: '#F2F2F2',
  hoverBackground: '#F0F1F3',

  // button 
  greyButton: '#F0F1F1',
  hoverService: '#ffffff33'
}

const textColor = {
  primary: '#404042',
  secondary: '#717275',
  tertiary: '#C8C9CC',
}

const backgroundColor = '#F7F7F7'
const bgColorSecondary = '#F5F6FA'

const lineColor = '#C4C4C4'

const primaryGreyScale = {
  '50': '#F1F7FF',
  '100': '#BCD5FF',
  '200': '#9EC1FF',
  '300': '#80AEFF',
  '400': '#629BFF',
  '500': '#4388FF', // primary color
  '600': '#347AF2', // primary dark color
  '700': '#256BE5',
  '800': '#165DD8',
  '900': '#074FCB',
  '1000': '#C6CCDB', // drag/drop component
  '1100': '#B7BBC2', // create service text 
  '1200': '#6E7685', // create service text 
  '1300' : '#E1E2E5', // skeleton
  '1400' : '#E0E0E0' //background selected language
}

export type StatusColorType =
  | 'default'
  | 'error'
  | 'success'
  | 'warning'
  | 'blocked'
export type StatusColor = {
  color: string
  bgColor: string
  disabled: string
}

const statusColor: Record<StatusColorType, StatusColor> = {
  default: {
    color: '#4388FF',
    bgColor: '#F1F7FF',
    disabled: '#9EC1FF',
  },
  error: {
    color: '#EB5757',
    bgColor: '#FEEBEF',
    disabled: '#EC9C9D',
  },
  warning: {
    color: '#F0C74A',
    bgColor: '#FDF5E0',
    disabled: '#F4D57D',
  },
  success: {
    color: '#27AE60',
    bgColor: '#E6F5EB',
    disabled: '#9AD6AE',
  },
  blocked: {
    color: commonColor.textPrimary,
    bgColor: '#f0f1f1',
    disabled: '#f0f1f1',
  },
}

export const COLOR = {
  commonColor,
  textColor,
  backgroundColor,
  bgColorSecondary,
  lineColor,
  primaryGreyScale,
  statusColor,
}

export default COLOR
