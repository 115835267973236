import React from 'react'
import DefaultHeader from './Header'
import { createTeleporter } from 'react-teleporter'

const HeaderTeleporter = createTeleporter()

export const HeaderTarget = () => <HeaderTeleporter.Target role="app-header" />

export const HeaderSource = ({ children, hideHeader }: any) => (
  <HeaderTeleporter.Source>
    {hideHeader ? (
      children
    ) : (
      <>
        {children}
        <DefaultHeader />
      </>
    )}
  </HeaderTeleporter.Source>
)

export const Header = () => {
  return (
    <HeaderTarget />
  )
}

export { DefaultHeader }

export default Header
