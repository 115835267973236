let debugFlags = {}
try {
  if (!process.env.REACT_APP_IS_RELEASE && window.localStorage) {
    const FEAT_ALLOW_UPLOAD = Boolean(
      window.localStorage.getItem('FEAT_ALLOW_UPLOAD')
    )
    const FEAT_MULTI_LANG = Boolean(
      window.localStorage.getItem('FEAT_MULTI_LANG')
    )
    const FEAT_MOVE_TO_MA = Boolean(
      window.localStorage.getItem('FEAT_MOVE_TO_MA')
    )

    // Aug-30: Move out of expirement flag
    // const FEAT_SE_WS = Boolean(window.localStorage.getItem('FEAT_SE_WS'))

    //'bothFaces|eachFace'
    const FEAT_SE_MODE = window.localStorage.getItem('FEAT_SE_MODE')

    const DEBUG = Boolean(window.localStorage.getItem('DEBUG'))
    const FEAT_SIM_CMD_DELAY_MS =
      parseInt(window.localStorage.getItem('FEAT_SIM_CMD_DELAY_MS')) ?? 0
    debugFlags = {
      FEAT_ALLOW_UPLOAD,
      FEAT_MULTI_LANG,
      FEAT_SE_MODE,
      DEBUG,
      FEAT_SIM_CMD_DELAY_MS,
      FEAT_MOVE_TO_MA,
    }
  }
} catch (error) {}
const GCAPCHAKEY =
  (window as any).env && (window as any).env.AUTH_GCAPCHA_SITEKEY
const GTM_ID = (window as any).env && (window as any).env.GTM_ID
const GTM_AUTH = (window as any).env && (window as any).env.GTM_AUTH
const GTM_PREVIEW = (window as any).env && (window as any).env.GTM_PREVIEW
// IOS 13 embeded iframe have some bug
// const isIOS = /iPad|iPhone|iPod/.test(navigator.platform || '')
// const ANIMATION_EXPERIMENT = !isIOS
const ANIMATION_EXPERIMENT = true
const WOOTRIC_TOKEN = (window as any).env && (window as any).env.WOOTRIC_TOKEN
const WOOTRIC_DEBUG = !process.env.REACT_APP_IS_RELEASE
const DISABLE_SERVICE_WORKER = !!process.env.REACT_APP_DISABLE_SERVICE_WORKER


const hoc = process.env.STORYBOOK ? (v) => v : Object.freeze
export default hoc({
  GCAPCHAKEY,
  GTM_ID,
  GTM_AUTH,
  GTM_PREVIEW,
  ANIMATION_EXPERIMENT,
  WOOTRIC_TOKEN,
  WOOTRIC_DEBUG,
  DISABLE_SERVICE_WORKER,
  ...debugFlags,
})
