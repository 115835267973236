import React, { useEffect, useState } from "react";
import { forwardRef, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import { Typography } from "@blockpass-org/ui";
import { COLOR } from '../theme/colors'
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from '@mui/material/styles';
const useStyles = makeStyles(() => ({
    root: {
        width: "344px !important"
    },
    card: {
        width: "100%"
    },
    actionRoot: {
        justifyContent: "space-between",
        display: 'flex',
    },

    actionRootBlock: {
        padding: "14px 16px",
        justifyContent: "space-between",
        display: 'block',
    },
    nowrap: {
        // overflowX: 'scroll',
        whiteSpace: 'nowrap',
        padding: 0,
        backgroundColor: `rgb(51, 51, 51) !important`,
        color: `rgb(51, 51, 51) !important`
    },
    actionContainer: {
        marginLeft: 4,
        display: 'inline-flex',
    },
    actionContainerRight: {
        marginTop: 14,
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'right'
    },
    action: {
        color: "#fff !important",
        transition: "all .2s",
        borderRadius: '4px  !important',
        width: 40,
        height: 40
    },

    paper: {
        backgroundColor: "#fff",
        padding: 16
    },
    checkIcon: {
        fontSize: 20,
        paddingRight: 4
    },
    truncate: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    }

}));

interface NotificationProps extends CustomContentProps {
    actionLabel?: string;
    dismiss?: boolean;
    actionCallback?: () => void;
}

const ActionButton = styled(Button)<ButtonProps>(({ theme }) => ({
    '&:hover': {
        backgroundColor: '#4388FF14'
    },
    '&:active': {
        backgroundColor: '#4388FF1F'
    },
    "& .MuiTouchRipple-root .MuiTouchRipple-child": {
        backgroundColor: '#4388FF1F'
    }
}));


const Notification = forwardRef<HTMLDivElement, NotificationProps>(
    ({ id, ...props }, ref) => {

        const [render, setRender] = useState(false);
        useEffect(() => {
            setTimeout(() => {
                setRender(true);
            }, 50)

        }, []);

        const classes = useStyles();
        const { closeSnackbar } = useSnackbar();


        const handleDismiss = useCallback(() => {
            closeSnackbar(id);
        }, [id, closeSnackbar]);

        const handleActionClick = useCallback(() => {
            // callback
            if (props.actionCallback) {
                props.actionCallback()
            }
        }, []);

        const longAction = props.actionLabel.trim().split(' ').length >= 2
        const hasDismiss = props.dismiss

        const objNotiMessage = document?.getElementById('noti-message');
        const objNotiMessageHidden = document?.getElementById('noti-message-hidden');
        let isTwoLine = false
        if (objNotiMessage && objNotiMessageHidden) {
            if (objNotiMessageHidden.scrollWidth > objNotiMessage.clientWidth) {
                isTwoLine = true
            } else {
                isTwoLine = false
            }
        }
        const hasAction = props.actionLabel.trim().length > 0

        const objActionBtn = document?.getElementById('action-btn');
        const objDismissBtn = document?.getElementById('dismiss-btn');

        let actionOffset = 0
        if (objActionBtn && hasAction) {
            actionOffset += objActionBtn.getBoundingClientRect().width
        }

        if (objDismissBtn && hasDismiss) {
            actionOffset += objDismissBtn.getBoundingClientRect().width
        }

        // long action -> force padding 14
        let paddingOneLine = "4px 16px"
        const paddingTwoLine = "14px 16px"

        if (longAction) {
            paddingOneLine = paddingTwoLine
        }
        return (
            <SnackbarContent ref={ref} className={classes.root} style={{ visibility: render ? 'visible' : 'hidden' }}>
                <Card className={classes.card} style={{ backgroundColor: "#333333" }}>
                    <Box className={longAction ? classes.actionRootBlock : classes.actionRoot}
                        style={{ padding: isTwoLine ? paddingTwoLine : paddingOneLine }}>
                        <div style={{ width: longAction ? '100%' : `calc(100% - ${actionOffset}px)`, marginTop: 'auto', marginBottom: 'auto' }}>
                            <Typography id={'noti-message'} variant="body2" color={COLOR.commonColor.white} className={classes.truncate}>
                                {props.message.toString().trim()}
                            </Typography>
                            <Typography id={'noti-message-hidden'} variant="body2" color={COLOR.commonColor.white} className={classes.nowrap}
                                style={{ width: longAction ? '312px' : `calc(312px - ${actionOffset}px)`, height: '0.1px' }}>
                                {props.message.toString().trim()}
                            </Typography>
                        </div>
                        <div className={longAction ? classes.actionContainerRight : classes.actionContainer}>
                            {
                                hasAction ? (<ActionButton
                                    id={'action-btn'}
                                    variant="text"
                                    onClick={handleActionClick}
                                >
                                    <Typography variant="button" color={COLOR.primaryGreyScale[300]}>
                                        {props.actionLabel.trim()}
                                    </Typography>
                                </ActionButton>) : (<></>)
                            }
                            {
                                hasDismiss ? (<IconButton
                                    id={'dismiss-btn'}
                                    size="small"
                                    className={classes.action}
                                    onClick={handleDismiss}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: '#FFFFFF14'
                                        },
                                        '&:active': {
                                            backgroundColor: '#FFFFFF1F'
                                        },
                                        "& .MuiTouchRipple-root .MuiTouchRipple-child": {
                                            borderRadius: "4px",
                                            backgroundColor: '#FFFFFF1F'
                                        }
                                    }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>) : (<></>)
                            }
                        </div>
                    </Box>
                </Card>
            </SnackbarContent>
        );


    }
);

Notification.displayName = "Notification";

export default Notification;
