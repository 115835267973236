const commonColor = {
  black: '#000',
  white: '#fff',
  primary: '#4388ff',
  secondary: '#27ae60',
  secondaryDark: '#007d34',
  secondaryLight: '#64e18e',
  accent: '#2D426B',
  error: '#EB5757',
  warning: '#F0C74A',
  success: '#27AE60',
  disabled: '#9EC1FF',

  // basic color
  red: '#EB5757',
  yellow: '#F0C74A',
  green: '#27AE60',
  blue: '#4388FF',

  // textColor
  textPrimary: '#404042',
  textSecondary: '#717275',
  textGreen: '#148D49',
  textYellow: '#E86000',

  greyButton: '#F0F1F1',
}

const textColor = {
  primary: '#404042',
  secondary: '#717275',
  tertiary: '#C8C9CC',
}

const backgroundColor = '#F7F7F7'
const bgColorSecondary = '#F5F6FA'

const lineColor = '#C4C4C4'

const primaryGreyScale = {
  '50': '#F1F7FF',
  '100': '#BCD5FF',
  '200': '#9EC1FF',
  '300': '#80AEFF',
  '400': '#629BFF',
  '500': '#4388FF', // primary color
  '600': '#347AF2', // primary dark color
  '700': '#256BE5',
  '800': '#165DD8',
  '900': '#074FCB',

  '1000': '#C6CCDB', // drag/drop component
  '1100': '#B7BBC2', // create service text
  '1200': '#6E7685', // create service text
  '1300': '#E1E2E5', // skeleton

  '7000': '#FEEBEF', // background chip
  '7100': '#C2E6CD',
  '7200': '#F9E6B0',
  '7300': '#E1E2E3',
  //black text color
  '8000': '#0D1B33',
  '8001': '#E97F00',
  '8002': '#B32425',
  '8003': '#005D2B',
  '8004': '#717275',
  // pill bg color
  '8100': '#D9E7FF',
  '8101': '#FDF5E0',
  '8102': '#FEEBEF',
  '8103': '#E6F5EB',
  '8104': '#E0E0E0',
  '8105': '#585E69', // emphasis
  '8106': '#E69A00',
  '8107': '#BA1A1A',
  '8108': '#2E7D32',
  '8109': '#5C5F65', // emphasis
  // pill outline color
  '8200': '#A1C4FF',
  '8201': '#F0C74A',
  '8202': '#EC9C9D',
  '8203': '#9AD6AE',
  '8204': '#BDBDBD',
}

const statusColor = {
  default: {
    color: '#4388FF',
    bgColor: '#F1F7FF',
    disabled: '#9EC1FF',
  },
  error: {
    color: '#EB5757',
    bgColor: '#FEEBEF',
    disabled: '#EC9C9D',
  },
  warning: {
    color: '#F0C74A',
    bgColor: '#FDF5E0',
    disabled: '#F4D57D',
  },
  success: {
    color: '#27AE60',
    bgColor: '#E6F5EB',
    disabled: '#9AD6AE',
  },
}

export const COLOR = {
  commonColor,
  textColor,
  backgroundColor,
  bgColorSecondary,
  lineColor,
  primaryGreyScale,
  statusColor,
}

export default COLOR
