
import React from 'react'
import { BlockpassLogo } from '@components/Icon/CommonIcons'
import { HeaderWrapper } from './Header.styled'
import useRootStore from '@hooks/useRootStore'
import { COLOR } from '@theme/colors'
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider'
const useStyles = makeStyles((theme) => ({
  container: {
    // background: COLOR.commonColor.primary,
    width: '100%'
  },
  flex: {
    display: 'flex',
    height: 56,
    padding: '0px 32px'
  },
  flexRight: {
    height: 56,
    // backgroundColor: COLOR.commonColor.primary,
    paddingRight: 32,
    padding: '0px 32px'
  },
  flexMini: {
    display: 'flex',
    height: 56
  },
  flexRightMini: {
    height: 56,
    // backgroundColor: COLOR.commonColor.primary,
    paddingRight: 32
  },
  divider: {
    borderColor: `rgba(255, 255, 255, 0.2) !important`
  },

  blockpassLogo: {
    display: 'flex',
    margin: 'auto',
    cursor: 'pointer',

  },
  blockpassLogoMini: {
    display: 'flex',
    margin: 'auto',
    cursor: 'pointer'
  },
  blockpassLogoText: {
    color: `${COLOR.commonColor.primary} !important`,
    marginLeft: '16px  !important',
    margin: 'auto  !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  rightPack: {
    display: 'flex',
    margin: 'auto',
    height: '100%'
  },
  iconButton: {
    padding: '0px !important'
  },
  dummyMenu: {
    width: 56,

  },
  link: {
    margin: 'auto 0px !important',
    padding: '8px 16px !important',
    minWidth: '32px !important',
    color: `${COLOR.commonColor.white} !important`
  },
}))

type HeaderProps = {
  children?: React.ReactNode
  hideHeader?: boolean
}

export const Header = observer(
  function Header(props: HeaderProps, ref: React.ForwardedRef<HTMLDivElement>) {


    const classes = useStyles()
    const rootStore = useRootStore();
    const {
      RouteStore,
      DashboardStore
    } = rootStore;

    const theme = useTheme();
    const downSm = useMediaQuery(theme.breakpoints.down('md'))
    const { hideHeader } = props

    const onGoHome = () => {
      // header + footer
      DashboardStore.hideLayout = false
      RouteStore.push(`/${DashboardStore.embeddedParam}`)
    }


    return !hideHeader ? (
      <>
        <HeaderWrapper >
          <Box className={classes.container}>


            <Box style={{ width: '100%', justifyContent: 'space-between', display: 'flex', }}>
              <div className={downSm ? classes.flexMini : classes.flex}>


                <Box className={downSm ? classes.blockpassLogoMini : classes.blockpassLogo} >
                  <BlockpassLogo onClick={onGoHome} style={{ width: 32, height: 32, cursor: 'pointer', marginLeft: downSm ? 30 : 8 }}></BlockpassLogo>
                  {/* <div style={{ marginLeft: 48, color: COLOR.commonColor.primary }}>Blockpass Registry - WIP</div> */}
                </Box>

              </div>


            </Box>
            <Divider />
          </Box >
        </HeaderWrapper >
      </>
    ) : <></>
  },
  { forwardRef: true }
)
export default Header