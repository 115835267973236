import React from 'react'
import { SvgIcon } from '@mui/material'


export const PlusIcon = (props: any) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.25 4a1 1 0 0 0-1-1h-.5a1 1 0 0 0-1 1v6.25a.5.5 0 0 1-.5.5H4a1 1 0 0 0-1 1v.5a1 1 0 0 0 1 1h6.25a.5.5 0 0 1 .5.5V20a1 1 0 0 0 1 1h.5a1 1 0 0 0 1-1v-6.25a.5.5 0 0 1 .5-.5H20a1 1 0 0 0 1-1v-.5a1 1 0 0 0-1-1h-6.25a.5.5 0 0 1-.5-.5V4Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>

)

export const PlusBlueIcon = (props: any) => (
  <svg
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#4388FF"
        fillRule="evenodd"
        d="M9.938 3a.75.75 0 0 0-.75-.75h-.376a.75.75 0 0 0-.75.75v4.688a.375.375 0 0 1-.374.375H3a.75.75 0 0 0-.75.75v.374c0 .415.336.75.75.75h4.688c.207 0 .375.168.375.376V15c0 .414.335.75.75.75h.374a.75.75 0 0 0 .75-.75v-4.688c0-.207.168-.374.376-.374H15a.75.75 0 0 0 .75-.75v-.376a.75.75 0 0 0-.75-.75h-4.688a.375.375 0 0 1-.374-.374V3Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>

)

export const Info = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"

    {...props}
  >
    <g clipPath="url(#aInfo)">
      <path
        d="M15.75 3.25a1 1 0 0 0-1-1h-3.635A2.258 2.258 0 0 0 9 .75c-.975 0-1.8.63-2.115 1.5H3.25a1 1 0 0 0-1 1v11.5a1 1 0 0 0 1 1h11.5a1 1 0 0 0 1-1V3.25ZM9 1.823c.165 0 .307.075.412.187a.56.56 0 0 1 .15.375.567.567 0 0 1-.562.563.567.567 0 0 1-.563-.563.56.56 0 0 1 .15-.375A.562.562 0 0 1 9 1.823Zm5.49 12.167a.5.5 0 0 1-.5.5H4.01a.5.5 0 0 1-.5-.5V4.01a.5.5 0 0 1 .5-.5h9.98a.5.5 0 0 1 .5.5v9.98ZM9 4.5a2.257 2.257 0 0 0-2.25 2.25A2.257 2.257 0 0 0 9 9a2.257 2.257 0 0 0 2.25-2.25A2.257 2.257 0 0 0 9 4.5Zm0 3a.752.752 0 0 1-.75-.75c0-.412.338-.75.75-.75s.75.338.75.75-.338.75-.75.75Zm-4.5 4.852V13.5h9v-1.148c0-1.875-2.977-2.684-4.5-2.684-1.522 0-4.5.802-4.5 2.684ZM6.232 12c.518-.42 1.785-.84 2.768-.84.982 0 2.258.42 2.768.84H6.232Z"
        fill="#4388FF"
      />
    </g>
    <defs>
      <clipPath id="aInfo">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);


export const Edit = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"

    {...props}
  >
    <g clipPath="url(#aEdit)">
      <path
        d="m10.5 6.24 1.26 1.26-7.08 6.99H3.51v-1.17l6.99-7.08Zm2.745-3.99a.75.75 0 0 0-.525.217L11.347 3.84l2.813 2.813 1.373-1.373a.747.747 0 0 0 0-1.058l-1.755-1.755a.736.736 0 0 0-.533-.217Zm-2.7 2.393-8.002 8.002a1 1 0 0 0-.293.707v2.398h2.398a1 1 0 0 0 .707-.293l8.002-8.002-2.812-2.813Z"
        fill="#4388FF"
      />
    </g>
    <defs>
      <clipPath id="aEdit">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const Warning = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"

    {...props}
  >
    <path
      d="M12 7c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1s-1-.45-1-1V8c0-.55.45-1 1-1Zm-.01-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2ZM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8Zm1-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      fill="#EB5757"
    />
  </svg>
);

export const WarningIcon = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
    <path d="M12 7C12.55 7 13 7.45 13 8L13 12C13 12.55 12.55 13 12 13C11.45 13 11 12.55 11 12L11 8C11 7.45 11.45 7 12 7ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z" fill="#EB5757" />
  </svg>

)

export const WhiteAlertIcon = (props: any) => (
  <svg
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 11.333a.84.84 0 0 1-.84-.84V7.186a.84.84 0 1 1 1.681 0v3.305a.84.84 0 0 1-.84.841Zm0-5.828a.84.84 0 1 1 0-1.68.84.84 0 0 1 0 1.68Zm0-4.172a6.667 6.667 0 1 1 0 13.334A6.667 6.667 0 0 1 8 1.333Z"
      fill="#fff"
    />
  </svg>

)

export const SampleCodeIcon = (props: any) => (
  <svg
    width={20}
    height={16}
    fill="none"
    {...props}
  >
    <path
      d="M15.097 12.417a.5.5 0 0 0 .707 0l4.05-4.055a.501.501 0 0 0 0-.709l-4.052-4.061a.5.5 0 0 0-.707 0l-1.074 1.076a.501.501 0 0 0 0 .708l2.265 2.278a.501.501 0 0 1 0 .708L14.02 10.63a.502.502 0 0 0 0 .709l1.076 1.078ZM4.903 12.417a.5.5 0 0 1-.707 0L.146 8.362a.501.501 0 0 1 0-.709l4.052-4.061a.5.5 0 0 1 .707 0l1.074 1.076a.501.501 0 0 1 0 .708L3.714 7.654a.501.501 0 0 0 0 .708L5.98 10.63a.502.502 0 0 1 0 .709l-1.076 1.078ZM6.795 14.975a.501.501 0 0 0 .354.614l1.464.394a.5.5 0 0 0 .612-.354l3.91-14.605a.5.5 0 0 0-.354-.613L11.314.017a.5.5 0 0 0-.613.354l-.675 2.524-.149.557-.11.41-2.972 11.113Z"
      fill="#717275"
    />
  </svg>

)

export const CheckIcon = (props: any) => (
  <svg
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#6E7685"
      fillRule="evenodd"
      d="M7.726 11.276a.75.75 0 0 1-1.06 0L3.527 8.149a.75.75 0 0 0-1.06 0l-.036.037a.75.75 0 0 0 0 1.061l4.235 4.224a.75.75 0 0 0 1.06 0l7.844-7.843a.75.75 0 0 0 0-1.06l-.036-.037a.75.75 0 0 0-1.06 0l-6.748 6.746Z"
      clipRule="evenodd"
    />
  </svg>

)

export const CopyIcon = (props: any) => (
  <SvgIcon
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#aCopy)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.996 1.999a1 1 0 0 0-1 1v16.02a1 1 0 0 0 1 1h2.108a.25.25 0 0 0 .25-.25v-1.173a.25.25 0 0 0-.25-.25H5.921a.25.25 0 0 1-.25-.25V4.174a.5.5 0 0 1 .5-.5h9.894a.25.25 0 0 1 .25.25v1.177c0 .138.112.25.25.25h1.175a.25.25 0 0 0 .25-.25V2.999a1 1 0 0 0-1-1H4.996ZM10.03 7.03a1 1 0 0 0-1 1V21a1 1 0 0 0 1 1h8.966a1 1 0 0 0 1-1V8.03a1 1 0 0 0-1-1H10.03Zm.68 2.173a.5.5 0 0 1 .5-.5h6.606a.5.5 0 0 1 .5.5v10.616a.5.5 0 0 1-.5.5H11.21a.5.5 0 0 1-.5-.5V9.203Z"
        fill="#717275"
      />
    </g>
    <defs>
      <clipPath id="aCopy">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>

)

export const CopyIcon2 = (props: any) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#aCopy2)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.996 1.999a1 1 0 0 0-1 1v16.02a1 1 0 0 0 1 1h2.108a.25.25 0 0 0 .25-.25v-1.173a.25.25 0 0 0-.25-.25H5.921a.25.25 0 0 1-.25-.25V4.174a.5.5 0 0 1 .5-.5h9.894a.25.25 0 0 1 .25.25v1.177c0 .138.112.25.25.25h1.175a.25.25 0 0 0 .25-.25V2.999a1 1 0 0 0-1-1H4.996ZM10.03 7.03a1 1 0 0 0-1 1V21a1 1 0 0 0 1 1h8.966a1 1 0 0 0 1-1V8.03a1 1 0 0 0-1-1H10.03Zm.68 2.173a.5.5 0 0 1 .5-.5h6.606a.5.5 0 0 1 .5.5v10.616a.5.5 0 0 1-.5.5H11.21a.5.5 0 0 1-.5-.5V9.203Z"
        fill="#717275"
      />
    </g>
    <defs>
      <clipPath id="aCopy2">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>

)

export const DownloadIcon = props => (
  <svg
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)" fill="#4388FF">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.943 8.268a.19.19 0 0 1-.188-.195l.02-3.356h-1.53l.002 3.352a.19.19 0 0 1-.186.194H6.867l1.991 1.94c.072.07.182.07.254 0l2.023-1.943-1.192.008Zm2.529-.984.67-.002c.136 0 .202.172.102.268l-4.06 3.901a.29.29 0 0 1-.406-.001L4.78 7.555c-.099-.096-.032-.269.103-.267l.73.008H7.13a.19.19 0 0 0 .186-.194L7.315 4.06c0-.171.133-.31.298-.31h2.8c.165 0 .298.14.297.311L10.692 7.1a.19.19 0 0 0 .187.195l1.593-.011Z"
      />
      <path d="M4.5 13.334a.38.38 0 0 1 .372-.387h8.256a.38.38 0 0 1 .372.387v.53a.38.38 0 0 1-.372.386H4.872a.38.38 0 0 1-.372-.387v-.53Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)

export const CopyBlueIcon = (props: any) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect width={24} height={24} rx={2} fill="#F1F7FF" />
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.996 1.999a1 1 0 0 0-1 1v16.02a1 1 0 0 0 1 1h2.108a.25.25 0 0 0 .25-.25v-1.173a.25.25 0 0 0-.25-.25H5.921a.25.25 0 0 1-.25-.25V4.174a.5.5 0 0 1 .5-.5h9.894a.25.25 0 0 1 .25.25v1.177c0 .138.112.25.25.25h1.175a.25.25 0 0 0 .25-.25V2.999a1 1 0 0 0-1-1H4.996ZM10.03 7.03a1 1 0 0 0-1 1V21a1 1 0 0 0 1 1h8.966a1 1 0 0 0 1-1V8.03a1 1 0 0 0-1-1H10.03Zm.68 2.173a.5.5 0 0 1 .5-.5h6.606a.5.5 0 0 1 .5.5v10.616a.5.5 0 0 1-.5.5H11.21a.5.5 0 0 1-.5-.5V9.203Z"
        fill="#4388FF"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

// stupid share classNamme
export const SettingIcon = (props: any) => (
  <SvgIcon class="service-webhook-MuiSvgIcon-root" focusable="false" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" fill="#4388FF"  {...props}>
    <path
      d="m14.586 8.625 1.633.395A4.384 4.384 0 0 0 16.34 8a4.357 4.357 0 0 0-2.43-3.898 4.315 4.315 0 0 0-.722-.277 4.342 4.342 0 0 0-3.793.704 4.358 4.358 0 0 0-1.486 2.02A4.333 4.333 0 0 0 7.66 8a4.321 4.321 0 0 0 1.37 3.165l-2.2 3.518a2.34 2.34 0 1 0 1.469.82l3.075-4.917A2.658 2.658 0 0 1 9.34 8a2.66 2.66 0 1 1 5.246.625Zm6.799 6.438a4.359 4.359 0 0 0-1.892-1.92 4.358 4.358 0 0 0-3.434-.238l-2.188-3.499a2.35 2.35 0 0 1-1.424.892l3.078 4.92a2.643 2.643 0 0 1 1.975-.878 2.66 2.66 0 1 1-1.874 4.548l-1.184 1.192a4.34 4.34 0 0 0 6.943-5.017ZM9.16 17a2.67 2.67 0 0 1-.136.84 2.66 2.66 0 1 1-3.514-3.31l-.625-1.559a4.36 4.36 0 0 0-2.638 3.162 4.36 4.36 0 0 0 .386 2.84 4.36 4.36 0 0 0 1.806 1.846 4.322 4.322 0 0 0 1.651.502l.014.001a4.387 4.387 0 0 0 1.549-.138 4.344 4.344 0 0 0 3.106-3.344h4.556a2.341 2.341 0 1 0 0-1.68h-6.29a2.665 2.665 0 0 1 .135.84Zm9 0a.66.66 0 1 1-1.32 0 .66.66 0 0 1 1.32 0Zm-11.475.634a.66.66 0 1 0-.37-1.267.66.66 0 0 0 .37 1.267ZM8.74 16.16h-.054l.007.018.047-.018ZM12 8.66a.66.66 0 1 0 0-1.32.66.66 0 0 0 0 1.32ZM14.34 8a2.334 2.334 0 0 1-1.11 1.992A2.34 2.34 0 1 1 14.34 8Z"
    />
  </SvgIcon>
)

export const LogIcon = (props: any) => (
  <SvgIcon class="service-webhook-MuiSvgIcon-root" focusable="false" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" fill="#4388FF"  {...props}>
    <path
      d="M9 2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H9ZM5.23 3H7v1.8H5.8v15.4h12.4V4.8H17V3h1.77c.679 0 1.23.425 1.23.95v17.1c0 .525-.551.95-1.23.95H5.23C4.552 22 4 21.575 4 21.05V3.95C4 3.425 4.551 3 5.23 3Zm5.396 5.616a.5.5 0 0 0-.707 0l-.565.566a.5.5 0 0 0 0 .707l2.121 2.121a.5.5 0 0 0 .707 0l2.829-2.828a.5.5 0 0 0 0-.707l-.566-.566a.5.5 0 0 0-.707 0l-1.556 1.556a.5.5 0 0 1-.707 0l-.848-.849Zm-.707 6a.5.5 0 0 1 .707 0l.849.849a.5.5 0 0 0 .707 0l1.556-1.556a.5.5 0 0 1 .707 0l.566.566a.5.5 0 0 1 0 .707l-2.829 2.828a.5.5 0 0 1-.707 0l-2.121-2.12a.5.5 0 0 1 0-.708l.565-.566Z"
    />
  </SvgIcon>
)

export const RefreshIcon = (props: any) => (
  <svg
    width={18}
    height={20}
    fill="none"
    {...props}
  >
    <path
      d="M5.483.373a.808.808 0 0 1 1.135-.14L8.81 1.949a.818.818 0 0 1 .023.018l.017.013c.35.274.413.78.139 1.13L7.394 5.153a.805.805 0 0 1-1.269-.991l.586-.75a6.906 6.906 0 0 0-3.067 10.873c.273.335.277.842-.05 1.158-.322.31-.85.312-1.149-.046A8.539 8.539 0 0 1 6.116 1.893l-.493-.386a.808.808 0 0 1-.14-1.134ZM14.302 4.329c.32-.32.854-.331 1.163.027a8.539 8.539 0 0 1-3.807 13.686l.6.468a.797.797 0 1 1-.982 1.256l-2.244-1.752a.797.797 0 0 1-.081-1.184l1.662-2.128a.8.8 0 1 1 1.261.985l-.566.725a6.886 6.886 0 0 0 2.962-10.91c-.281-.335-.293-.848.032-1.173Z"
      fill="#717275"
    />
  </svg>

)


export const RefreshDisabledIcon = (props: any) => (
  <svg
    width={18}
    height={20}
    fill="none"

    {...props}
  >
    <path
      d="M5.483.373a.808.808 0 0 1 1.135-.14L8.81 1.949a.818.818 0 0 1 .023.018l.017.013c.35.274.413.78.139 1.13L7.394 5.153a.805.805 0 0 1-1.269-.991l.586-.75a6.906 6.906 0 0 0-3.067 10.873c.273.335.277.842-.05 1.158-.322.31-.85.312-1.149-.046A8.539 8.539 0 0 1 6.116 1.893l-.493-.386a.808.808 0 0 1-.14-1.134ZM14.302 4.329c.32-.32.854-.331 1.163.027a8.539 8.539 0 0 1-3.807 13.686l.6.468a.797.797 0 1 1-.982 1.256l-2.244-1.752a.797.797 0 0 1-.081-1.184l1.662-2.128a.8.8 0 1 1 1.261.985l-.566.725a6.886 6.886 0 0 0 2.962-10.91c-.281-.335-.293-.848.032-1.173Z"
      fill="#C8C9CC"
    />
  </svg>

)


// end stupid share classNamme

export const CheckedIcon = (props: any) => (
  <svg
    width={41}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.802 23.033a1 1 0 0 1-1.412.001l-4.186-4.172a1 1 0 0 0-1.413.001l-.049.05a1 1 0 0 0 .001 1.414l5.647 5.632a1 1 0 0 0 1.413 0L29.26 15.502a1 1 0 0 0 0-1.415l-.048-.048a1 1 0 0 0-1.414 0l-8.997 8.994Z"
      fill="#27AE60"
    />
  </svg>
)

export const CrossIcon = (props: any) => (
  <svg
    width={41}
    height={40}
    fill="none"
    {...props}
  >
    <g clipPath="url(#cross)">
      <path
        d="M13.397 14.693a1.258 1.258 0 0 1 1.809-1.75l4.93 4.93a.5.5 0 0 0 .707 0l4.996-4.994a1.258 1.258 0 0 1 1.736 1.82l-4.962 4.963a.487.487 0 0 0 .014.704l5.005 5.01a1.259 1.259 0 0 1-1.85 1.703l-4.94-4.94a.5.5 0 0 0-.706 0l-5.002 4.998a1.259 1.259 0 0 1-1.71-1.844l4.932-4.933a.5.5 0 0 0 0-.707l-4.96-4.96Z"
        fill="#EB5757"
      />
    </g>
    <defs>
      <clipPath id="cross">
        <path fill="#fff" transform="translate(.5)" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
)
export const CloseIcon = (props: any) => (
  <svg
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <g clipPath="url(#close)">
      <path
        d="M12.897 14.694a1.258 1.258 0 0 1 1.809-1.75l4.93 4.93a.5.5 0 0 0 .707 0l4.996-4.994a1.258 1.258 0 0 1 1.736 1.82l-4.962 4.963a.487.487 0 0 0 .014.703l5.005 5.01a1.259 1.259 0 0 1-1.85 1.704l-4.94-4.94a.5.5 0 0 0-.706 0l-5.002 4.997a1.259 1.259 0 0 1-1.71-1.843l4.932-4.933a.5.5 0 0 0 0-.707l-4.96-4.96Z"
        fill="#6E7685"
      />
    </g>
    <defs>
      <clipPath id="close">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
)
export const EmptyGraphicIcon = (props: any) => (
  <svg
    width={164}
    height={164}
    fill="none"
    {...props}
  >
    <g clipPath="url(#aEmptyGraphicIcon)">
      <path
        fill="#72A2FF"
        fillRule="evenodd"
        d="m33.428 35.71-9.955 11.765L.15 74.683a.618.618 0 0 0 .47 1.022h26.885c1.705 0 3.32-.753 4.41-2.065l6.882-8.262a16.132 16.132 0 0 1 12.397-5.804h60.169c4.786 0 9.329 2.124 12.394 5.807l6.883 8.262a5.74 5.74 0 0 0 4.409 2.065h26.885a.62.62 0 0 0 .471-1.022l-23.326-27.21-9.953-11.766A16.125 16.125 0 0 0 116.813 30H45.742a16.131 16.131 0 0 0-12.314 5.71Z"
        clipRule="evenodd"
      />
      <path
        fill="#C2D7FF"
        d="M43.023 52.852 13.449 85.115l29.574 16.131h79.311l30.918-16.131-30.918-32.262H43.023Z"
      />
      <path
        fill="#8EB3FB"
        d="M0 117.753c0 5.923 0 8.883 1.135 11.152a10.755 10.755 0 0 0 4.812 4.813c2.27 1.134 5.23 1.134 11.152 1.134h129.802c5.92 0 8.883 0 11.152-1.134a10.75 10.75 0 0 0 4.812-4.813C164 126.636 164 123.676 164 117.753V75.705h-39.373a8.066 8.066 0 0 0-8.066 8.065 8.068 8.068 0 0 1-8.066 8.066H56.459a8.065 8.065 0 0 1-8.066-8.066 8.066 8.066 0 0 0-8.065-8.065H0v42.048Z"
      />
      <path
        fill="url(#bEmptyGraphicIcon)"
        d="M0 117.753c0 5.923 0 8.883 1.135 11.152a10.755 10.755 0 0 0 4.812 4.813c2.27 1.134 5.23 1.134 11.152 1.134h129.802c5.92 0 8.883 0 11.152-1.134a10.75 10.75 0 0 0 4.812-4.813C164 126.636 164 123.676 164 117.753V75.705h-39.373a8.066 8.066 0 0 0-8.066 8.065 8.068 8.068 0 0 1-8.066 8.066H56.459a8.065 8.065 0 0 1-8.066-8.066 8.066 8.066 0 0 0-8.065-8.065H0v42.048Z"
      />
      <path
        stroke="#F3F3F3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M32.273 43.443c13.443 20.164 25.972 33.297 56.46 32.225"
      />
    </g>
    <defs>
      <linearGradient
        id="bEmptyGraphicIcon"
        x1={82}
        x2={82}
        y1={75.705}
        y2={134.852}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0} />
        <stop offset={1} stopColor="#BED8FD" />
      </linearGradient>
      <clipPath id="aEmptyGraphicIcon">
        <path fill="#fff" d="M0 0h164v164H0z" />
      </clipPath>
    </defs>
  </svg>
)

export const EmptyGraphicIcon2 = (props: any) => (
  <svg
    width={164}
    height={164}
    fill="none"
    {...props}
  >
    <path
      fill="#4388FF"
      d="M154.394 164c-2.25 0-4.501-.91-6.301-2.957l-37.586-37.986-1.351.91c-12.153 9.098-26.332 13.875-40.736 13.875-17.33 0-34.66-7.051-47.489-19.334C7.427 105.542 0 88.028 0 68.921 0 30.935 30.609 0 68.195 0c25.657 0 48.163 13.648 60.317 36.849 11.928 22.974 10.353 49.814-4.276 71.423l-.901 1.365 37.811 38.213c3.826 3.867 2.926 7.734 2.251 9.781-1.801 3.639-5.402 6.369-9.003 6.369Zm-86.2-145.12c-27.457 0-49.514 22.518-49.514 50.04 0 31.391 25.433 50.27 50.19 50.27 15.08 0 28.808-6.824 38.486-19.107 11.928-15.012 13.954-35.256 5.626-52.77-8.552-17.516-25.657-28.434-44.787-28.434Z"
    />
    <path
      fill="#828282"
      d="M46.815 77.108c2.926 0 5.401-2.502 5.401-5.459s-2.475-5.459-5.401-5.459c-2.926 0-5.402 2.502-5.402 5.46 0 2.956 2.476 5.458 5.402 5.458ZM88.225 77.108c2.926 0 5.401-2.502 5.401-5.459s-2.475-5.459-5.401-5.459c-2.926 0-5.402 2.502-5.402 5.46 0 3.184 2.476 5.458 5.402 5.458ZM46.602 53.589l-11.881 6.484 1.603 3.002 11.883-6.484-1.605-3.002ZM87.491 53.503l-1.603 3.003 11.883 6.482 1.603-3.002-11.883-6.483ZM67.52 89.847c3.48 0 6.302-2.139 6.302-4.777 0-2.638-2.821-4.777-6.302-4.777-3.48 0-6.301 2.139-6.301 4.777 0 2.638 2.82 4.777 6.301 4.777Z"
    />
  </svg>
)

export const EmptyGraphicIcon3 = (props: any) => (
  <svg
    width={164}
    height={164}
    fill="none"
    {...props}
  >
    <path
      fill="#F1F7FF"
      d="M81.035 143.323c32.275 0 58.441-26.166 58.441-58.556 0-32.391-26.281-58.557-58.441-58.557-32.276 0-58.442 26.166-58.442 58.557 0 32.39 26.166 58.556 58.442 58.556ZM142.588 27.94a3.228 3.228 0 1 0 0-6.456 3.228 3.228 0 0 0 0 6.455ZM25.705 46.267a3.227 3.227 0 1 0 0-6.455 3.227 3.227 0 0 0 0 6.455ZM5.994 108.512a5.994 5.994 0 1 0 0-11.987 5.994 5.994 0 0 0 0 11.987Z"
    />
    <g filter="url(#aEmptyGraphicIcon3)">
      <path
        fill="#F1F7FF"
        d="M136.723 54.01v67.821c0 1.99-1.658 3.648-3.648 3.648H30.015c-1.99.083-3.647-1.575-3.647-3.565V54.009a3.627 3.627 0 0 1 3.648-3.648h103.059a3.627 3.627 0 0 1 3.648 3.648Z"
      />
    </g>
    <path
      fill="#4388FF"
      d="M136.723 54.01v5.223H26.368v-5.224a3.627 3.627 0 0 1 3.648-3.648h103.059a3.627 3.627 0 0 1 3.648 3.648Z"
    />
    <path
      fill="#BDBDBD"
      d="M66.33 85.516a3.068 3.068 0 1 0 0-6.135 3.068 3.068 0 0 0 0 6.135ZM96.842 85.516a3.068 3.068 0 1 0 0-6.135 3.068 3.068 0 0 0 0 6.135ZM86.561 105.664h-9.617c-1.493 0-2.737-1.244-2.737-2.737 0-1.492 1.244-2.736 2.737-2.736h9.534c1.493 0 2.737 1.244 2.737 2.736.082 1.493-1.161 2.737-2.654 2.737Z"
    />
    <path
      fill="#F9FAFB"
      d="M31.26 56.331a1.244 1.244 0 1 0 0-2.487 1.244 1.244 0 0 0 0 2.487ZM35.157 56.331a1.244 1.244 0 1 0 0-2.487 1.244 1.244 0 0 0 0 2.487ZM39.136 56.331a1.244 1.244 0 1 0 0-2.487 1.244 1.244 0 0 0 0 2.487Z"
    />
    <path
      fill="#BCD5FF"
      d="M132.246 41.573a1.487 1.487 0 0 1-1.493-1.492V23.415c0-.829.664-1.492 1.493-1.492.829 0 1.492.663 1.492 1.492v16.666c0 .829-.663 1.492-1.492 1.492ZM144.35 54.341c0-.83.663-1.492 1.492-1.492h16.665c.829 0 1.493.663 1.493 1.492 0 .83-.664 1.492-1.493 1.492h-16.665a1.486 1.486 0 0 1-1.492-1.492ZM143.023 43.563c-.58-.58-.58-1.576 0-2.156l11.857-11.773c.58-.58 1.575-.58 2.155 0 .581.58.581 1.575 0 2.155l-11.773 11.774c-.664.58-1.658.58-2.239 0Z"
    />
    <defs>
      <filter
        id="aEmptyGraphicIcon3"
        width={154.355}
        height={119.121}
        x={4.368}
        y={39.361}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_14150_217785"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_14150_217785"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
// export const ErrorIcon = props => (
//   <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M16 2.66671C23.3638 2.66671 29.3333 8.63624 29.3333 16C29.3333 23.3638 23.3638 29.3334 16 29.3334C8.63616 29.3334 2.66663 23.3638 2.66663 16C2.66663 8.63624 8.63616 2.66671 16 2.66671ZM17.3007 20.2696H14.6666V22.6667H17.3007V20.2696ZM17.3007 19.0847H14.6666V9.14977H17.3007V19.0847Z"
//     />
//   </SvgIcon>
// )

// note: remove those to apply mui color rule
// fillRule="evenodd"
// clipRule="evenodd"
// fill="#4388FF"

