import { observable, action, toJS, makeObservable } from 'mobx'
import { safeInvoke } from '../../utils'
import merge from 'lodash/merge'
import BaseStore from '../base/BaseStore'

class BaseDomainStore extends BaseStore {
  Template: any
  data: any
  loading: any
  refreshDataHandler: any
  constructor(template: any) {
    super()

    this.data = new Map()
    this.loading = new Map()
    this.Template = template
    
    makeObservable(this, {
      data: observable,
      loading: observable,
      updateData: action,
      rehydrate: action,
      deleteData: action,
    });

    
  }

  updateData(id: any, data: any) {
    const { Template } = this
    const insObj = this.data.get(id)
    if (!id) return
    if (insObj) {
      insObj.updateData(merge(toJS(insObj) || {}, data))
    } else {
      const newIns = new Template(data)
      this.data.set(id, newIns)
    }

    return this.data.get(id)
  }

  replaceData(id: any, data: any) {
    const { Template } = this
    const insObj = this.data.get(id)

    if (insObj) {
      insObj.updateData(data)
    } else {
      const newIns = new Template(data)
      this.data.set(id, newIns)
    }

    return this.data.get(id)
  }

  deleteData(id: any) {
    this.data.delete(id)
  }

  getData = (id: any, options = { autoLoad: false }) => {
    let ins = this.data.get(id)

    if (!ins && options.autoLoad) {
      const { Template } = this
      ins = new Template({ _id: id })
      this.data.set(id, ins)
      this._refreshData({ _id: id })
    }

    return ins
  }

  rehydrate() {
    this.data.forEach((item: any) => this._refreshData(item))
  }

  rehydrateWithId(id: any) {
    this._refreshData({ _id: id })
  }

  normalizeData = (id = '') => {
    if (id) {
      return {
        [id]: toJS(this.data.get(id)),
      }
    }
    const result = {}
    this.data.forEach((value: any, key: any) => (result[key] = toJS(value)))
    return result
  }

  get size() {
    return this.data.size
  }

  async _refreshData(item: any) {
    this._setLoading(item._id, true)

    await safeInvoke(this.refreshDataHandler, item)

    this._setLoading(item._id, false)
  }

  _setLoading(id: any, loading: any) {
    this.debugLog('setLoading ', id, loading)
    this.loading.set(id, loading)
  }

  clear() {
    this.data.clear()
  }

  // -----------------------------------------------------//
  //  Override Life circle
  // -----------------------------------------------------//
  async stop() {
    super.stop()
    this.data.clear()
  }
}

export default BaseDomainStore
