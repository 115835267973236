import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import useViewStore from '@hooks/useViewStore'
import ViewStore from './CompanyDetail.ViewStore'
import ScrollToTop from 'react-scroll-up'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import { GoBackArrowBlueIcon, ScrollUp } from '@components/Icon/CommonIcons'
import COLOR from '@theme/colors'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import useRootStore from '@hooks/useRootStore'
import { SplashPage } from '../SplashPage'
// import makeStyles from '@mui/styles/makeStyles';
import get from 'lodash/get'
import Grid from '@mui/material/Grid'
// import { pushDataLayer } from '@utils/index'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import BrandComponent from '@/components/BrandComponent'
import SocialComponent from '@/components/SocialComponent'
import CryptoComponent from '@/components/CryptoComponent'
import ServiceComponent from '@/components/ServiceComponent'
import { Button, Typography } from '@mui/material'
import WebsiteComponent from '@/components/WebsiteComponent'
import { toJS } from 'mobx'

// const useStyles = makeStyles((theme) => ({
//   icon: {
//     width: 40,
//     height: 40,
//   },
//   iconMenu: {
//     padding: "16px 0px 16px 0px"
//   },
//   containerEmpty: {
//     display: 'flex',
//     padding: '24px',
//     paddingTop: '0px',
//     // margin: 32,
//     // marginTop: 0,
//     backgroundColor: COLOR.commonColor.white,
//     color: COLOR.textColor.secondary,
//     borderRadius: 8,
//     // height: 540,
//   },
//   avatar: {
//     margin: 'auto !important',
//     maxHeight: 40,
//     maxWidth: 40
//   },
//   kybInfo: {
//     display: 'flex',
//     justifyContent: 'space-around'
//   }
// }))

const CompanyDetail = observer(function CompanyDetail() {
  const rootStore = useRootStore()
  const {
    // LocalizationStore: { translate },
    DashboardStore,
    RouteStore,
  } = rootStore
  const viewStore = useViewStore(rootStore, ViewStore, {})

  useEffect(() => {
    if (DashboardStore.ignoreGetParam) {
      return
    }

    window.scrollTo(0, 0)

    let embedded = '0'
    let searchObj = {}
    const splitHash = get(window, 'location.hash', '').split('?')
    if (splitHash.length > 1) {
      const search = get(window, 'location.hash', '')
        .split('?')[1]
        .split('&')
        .filter((v) => v !== '')
      search.forEach((s) => {
        const filter = s.split('=').filter((v) => v !== '')
        if (filter.length > 1) {
          const value = filter[1]
          searchObj[filter[0]] = value
        }
      })
    }
    embedded = get(searchObj, 'embedded', '0')

    if (embedded && embedded === '1') {
      DashboardStore.setEmbedMode(true)
    } else {
      DashboardStore.setEmbedMode(false)
    }

    DashboardStore.hideLayout = true
    // get companySlug
    const { companySlug } = RouteStore.params
    if (DashboardStore.selectedCompanySlug === '') {
      DashboardStore.selectedCompanySlug = decodeURI(companySlug || '')
    }
  })
  /* eslint-disable */
  const [currentClientId, setCurrentClientId] = useState('')
  const [currentServiceInfo, setCurrentServiceInfo] = useState({})
  const [openDialogShareableLink, setOpenDialogShareableLink] = useState(false)
  // const [openDialogOpenDashboard, setOpenDialogOpenDashboard] = useState(false);
  const [openDialogTestService, setOpenDialogTestService] = useState(false)
  /* eslint-enable */

  // const classes = useStyles()

  // const isKYBService = true

  const embedded = DashboardStore.embedded
  const theme = useTheme()
  let downMd = useMediaQuery(theme.breakpoints.down('md'))
  let betSmLg = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  let downSm = useMediaQuery(theme.breakpoints.down('sm'))
  let onlySm = useMediaQuery(theme.breakpoints.only('sm'))
  const onGoBack = () => {
    // header + footer
    DashboardStore.hideLayout = false
    RouteStore.push(`/${DashboardStore.embeddedParam}`)
  }

  let padding = '64px 40px'
  if (betSmLg) {
    padding = '32px'
  }
  if (downSm || onlySm) {
    padding = '32px 16px'
  }

  const { ready } = viewStore

  if (ready) {
    const data = toJS(
      DashboardStore.getCompanyDetailData(DashboardStore.selectedCompanySlug)
    )

    const websites = get(data, 'web', [])
    const social = get(data, 'socialNetworks', [])
    const crypto = get(data, 'cryptoWallets', {})

    const evmList = get(crypto, 'evm', [])
    const btcList = get(crypto, 'bitcoin', [])

    const kycServices = get(data, 'blockpass.services', []).filter(
      (s) => s.type === 'kyc'
    )
    const kybServices = get(data, 'blockpass.services', []).filter(
      (s) => s.type === 'kyb'
    )
    return (
      <div>
        <ScrollToTop
          showUnder={100}
          style={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            cursor: 'pointer',
            transitionDuration: '0.2s',
            transitionTimingFunction: 'linear',
            transitionDelay: '0s',
          }}
        >
          <IconButton
            color="primary"
            component="span"
            sx={{
              '&.MuiIconButton-root': {
                borderRadius: '4px !important',
                width: '40px !important',
                height: '40px !important',
              },
            }}
            style={{ backgroundColor: COLOR.commonColor.primary }}
          >
            <ScrollUp />
          </IconButton>
        </ScrollToTop>
        <div
          style={
            embedded
              ? {
                  maxWidth: '720px',
                  // width: '100%',
                  margin: 'auto',
                  // minHeight: '100vh',
                  padding: downMd ? '8px' : 32,
                }
              : {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: padding,
                }
          }
        >
          <div
            id="CompanyDetail"
            style={{
              maxWidth: '720px',
              width: '100%',
              background: COLOR.commonColor.white,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              // height: defaultHeight,
              minHeight: embedded ? '' : 'calc(100vh - 365px)',

              borderBottomLeftRadius: DashboardStore.embedded ? 8 : 0,
              borderBottomRightRadius: DashboardStore.embedded ? 8 : 0,
            }}
          >
            <Box>
              <Button
                variant="text"
                startIcon={<GoBackArrowBlueIcon />}
                onClick={onGoBack}
                style={{ marginBottom: 24 }}
              >
                {'GO BACK'}
              </Button>

              <BrandComponent company={data} />
            </Box>

            {websites.length > 0 ? (
              <>
                <Box style={{ margin: '24px 0px 24px 0px' }}>
                  <Divider />
                </Box>
                <Box style={{ marginBottom: 24 }}>
                  <Typography
                    style={{ marginBottom: downSm ? 12 : 16 }}
                    variant="h5"
                  >
                    {'Websites'}
                  </Typography>
                  {downMd ? (
                    <Stack
                      direction={{
                        xs: 'column',
                        sm: 'column',
                        md: 'column',
                        lg: 'column',
                        xl: 'column',
                      }}
                      spacing={{ xs: 1.5, sm: 1.5, md: 2, lg: 2, xl: 2 }}
                      justifyContent="space-between"
                      alignItems={{
                        sx: embedded ? 'center' : 'flex-start',
                        sm: embedded ? 'center' : 'flex-start',
                        md: embedded ? 'center' : 'flex-start',
                        lg: embedded ? 'center' : 'flex-start',
                        xl: embedded ? 'center' : 'flex-start',
                      }}
                      width={'100%'}
                    >
                      {websites.map((w) => {
                        return <WebsiteComponent web={w} modeFull={true} />
                      })}
                    </Stack>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        {websites.map((w) => {
                          return (
                            <Grid item xs={6}>
                              <WebsiteComponent web={w} modeFull={false} />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <></>
            )}

            {social.length > 0 ? (
              <>
                <Box style={{ margin: '24px 0px 24px 0px' }}>
                  <Divider />
                </Box>
                <Box style={{ marginBottom: 24 }}>
                  <Typography
                    style={{ marginBottom: downSm ? 12 : 16 }}
                    variant="h5"
                  >
                    {'Social networks'}
                  </Typography>
                  {downMd ? (
                    <Stack
                      direction={{
                        xs: 'column',
                        sm: 'column',
                        md: 'column',
                        lg: 'column',
                        xl: 'column',
                      }}
                      spacing={{ xs: 1.5, sm: 1.5, md: 2, lg: 2, xl: 2 }}
                      justifyContent="space-between"
                      alignItems={{
                        sx: embedded ? 'center' : 'flex-start',
                        sm: embedded ? 'center' : 'flex-start',
                        md: embedded ? 'center' : 'flex-start',
                        lg: embedded ? 'center' : 'flex-start',
                        xl: embedded ? 'center' : 'flex-start',
                      }}
                      width={'100%'}
                    >
                      {social.map((w) => {
                        return <SocialComponent social={w} modeFull={true} />
                      })}
                    </Stack>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        {social.map((w) => {
                          return (
                            <Grid item xs={6}>
                              <SocialComponent social={w} modeFull={false} />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <></>
            )}
            {evmList.length > 0 || btcList.length > 0 ? (
              <>
                <Box style={{ margin: '24px 0px 24px 0px' }}>
                  <Divider />
                </Box>
                <Box style={{ marginBottom: 24 }}>
                  <Typography
                    style={{ marginBottom: downSm ? 12 : 16 }}
                    variant="h5"
                  >
                    {'Crypto wallets'}
                  </Typography>
                  {downMd ? (
                    <Stack
                      direction={{
                        xs: 'column',
                        sm: 'column',
                        md: 'column',
                        lg: 'column',
                        xl: 'column',
                      }}
                      spacing={{ xs: 1.5, sm: 2, md: 1.5, lg: 2, xl: 2 }}
                      justifyContent="space-between"
                      alignItems={{
                        sx: embedded ? 'center' : 'flex-start',
                        sm: embedded ? 'center' : 'flex-start',
                        md: embedded ? 'center' : 'flex-start',
                        lg: embedded ? 'center' : 'flex-start',
                        xl: embedded ? 'center' : 'flex-start',
                      }}
                      width={'100%'}
                    >
                      {evmList.map((w) => {
                        return <CryptoComponent crypto={w} modeFull={true} />
                      })}
                      {btcList.map((w) => {
                        return <CryptoComponent crypto={w} modeFull={true} />
                      })}
                    </Stack>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        {evmList.map((w) => {
                          return (
                            <Grid item xs={6}>
                              <CryptoComponent crypto={w} modeFull={false} />
                            </Grid>
                          )
                        })}
                        {btcList.map((w) => {
                          return (
                            <Grid item xs={6}>
                              <CryptoComponent crypto={w} modeFull={false} />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <></>
            )}
            {kycServices.length > 0 ? (
              <>
                <Box style={{ margin: '24px 0px 24px 0px' }}>
                  <Divider />
                </Box>
                <Box style={{ marginBottom: 24 }}>
                  <Typography
                    style={{ marginBottom: downSm ? 12 : 16 }}
                    variant="h5"
                  >
                    {'KYC Registration'}
                  </Typography>
                  {downMd ? (
                    <Stack
                      direction={{
                        xs: 'column',
                        sm: 'column',
                        md: 'column',
                        lg: 'column',
                        xl: 'column',
                      }}
                      spacing={{ xs: 1.5, sm: 1.5, md: 2, lg: 2, xl: 2 }}
                      justifyContent="space-between"
                      alignItems={{
                        sx: embedded ? 'center' : 'flex-start',
                        sm: embedded ? 'center' : 'flex-start',
                        md: embedded ? 'center' : 'flex-start',
                        lg: embedded ? 'center' : 'flex-start',
                        xl: embedded ? 'center' : 'flex-start',
                      }}
                      width={'100%'}
                    >
                      {kycServices.map((w) => {
                        return <ServiceComponent service={w} modeFull={true} />
                      })}
                    </Stack>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        {kycServices.map((w) => {
                          return (
                            <Grid item xs={6}>
                              <ServiceComponent service={w} modeFull={false} />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <></>
            )}
            {kybServices.length > 0 ? (
              <>
                <Box style={{ margin: '24px 0px 24px 0px' }}>
                  <Divider />
                </Box>
                <Box style={{ marginBottom: 24 }}>
                  <Typography
                    style={{ marginBottom: downSm ? 12 : 16 }}
                    variant="h5"
                  >
                    {'KYB Registration'}
                  </Typography>
                  {downMd ? (
                    <Stack
                      direction={{
                        xs: 'column',
                        sm: 'column',
                        md: 'column',
                        lg: 'column',
                        xl: 'column',
                      }}
                      spacing={{ xs: 1.5, sm: 1.5, md: 2, lg: 2, xl: 2 }}
                      justifyContent="space-between"
                      alignItems={{
                        sx: embedded ? 'center' : 'flex-start',
                        sm: embedded ? 'center' : 'flex-start',
                        md: embedded ? 'center' : 'flex-start',
                        lg: embedded ? 'center' : 'flex-start',
                        xl: embedded ? 'center' : 'flex-start',
                      }}
                      width={'100%'}
                    >
                      {kybServices.map((w) => {
                        return <ServiceComponent service={w} modeFull={true} />
                      })}
                    </Stack>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        {kybServices.map((w) => {
                          return (
                            <Grid item xs={6}>
                              <ServiceComponent service={w} modeFull={false} />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    )

    // if (hasRecord || DashboardStore.loadingList) {

    // } else {
    //   return (

    //   )
    // }
  } else {
    return (
      <div style={{ height: '100vh', textAlign: 'center' }}>
        <SplashPage />
      </div>
    )
  }
})

export default CompanyDetail
