import { observable, action, makeObservable } from 'mobx'
import BaseStore from '@stores/base/BaseStore'
import chroma from 'chroma-js'

import { COLOR } from '@theme/colors'
import RootStore from '../RootStore'

interface AppBar {
  dense: boolean
  visible: boolean
  expanded: boolean
  position: 'fixed' | 'absolute'
}

class UILayoutStore extends BaseStore {
  _rootStore: RootStore
  activeColor: any
  appBar: AppBar
  fullscreen: boolean
  maxContentHeight?: number
  isReviewSubmitted: boolean
  isReviewProfileChanged: boolean
  showBackButton: boolean

  constructor(rootStore: any) {
    super()

    this._rootStore = rootStore
    this._init()
    
    makeObservable(this, {
      maxContentHeight: observable,
      fullscreen: observable,
      appBar: observable,
      isReviewSubmitted: observable,
      isReviewProfileChanged: observable,
      showBackButton: observable,

      setFullscreen: action,
      setReviewSubmittedProfile: action,
      setShowBackButton: action,
      setAppBar: action,
    })

    
  }

  _init() {
    this.isReviewSubmitted = false
    this.isReviewProfileChanged = false
    this.showBackButton = true
    this.maxContentHeight = undefined
    this.fullscreen = false
    this.appBar = {
      dense: false,
      visible: !this._rootStore.isIframe && !this.fullscreen,
      expanded: false,
      position: 'fixed',
    }
  }

  // ---------------------------------------------//
  //  Public
  // ---------------------------------------------//

  setActiveColor(mainColor: any) {
    // get valid color only
    if (!chroma.valid(mainColor)) return

    this.activeColor = chroma(mainColor)

    // make sure font and background colors are well contrasted. Darken the color otherwise
    while (chroma.contrast(this.activeColor, COLOR.commonColor.white) < 3) {
      this.activeColor = chroma(this.activeColor).darken(0.1)
    }
    COLOR.commonColor.primary = this.activeColor.hex()

    // Derive other main colors
    COLOR.commonColor.disabled = chroma(this.activeColor).luminance(0.75).hex()
    COLOR.primaryGreyScale['200'] = chroma(this.activeColor)
      .luminance(0.75)
      .hex()
    COLOR.primaryGreyScale['50'] = chroma(this.activeColor).luminance(0.5).hex()
    COLOR.primaryGreyScale['600'] = chroma(this.activeColor).darken().hex()
  }

  setAppBar = <K extends keyof AppBar>(key: K, value: AppBar[K]) => {
    if (!key) return
    this.appBar[key] = value
  }

  /**
   * enable/disable full screen layout mode
   * @param {*} value Boolean
   */
  setFullscreen = (value: any) => {
    this.fullscreen = value
  }

  setMaxContentHeight = (height: number | undefined) => {
    this.maxContentHeight = height
  }

  setReviewSubmittedProfile = (value: boolean) => {
    this.isReviewSubmitted = value
  }

  setReviewProfileChanged = (value: boolean) => {
    this.isReviewProfileChanged = value
  }

  setShowBackButton = (value: boolean) => {
    this.showBackButton = value
  }

  turnOnWarningOnLeave = () => {
    window.onbeforeunload = function () {
      return 'Are you sure you want to leave?'
    }
  }

  turnOffWarningOnLeave = () => {
    window.onbeforeunload = undefined
  }

  // ---------------------------------------------//
  //  Life cycle
  // ---------------------------------------------//
}

export default UILayoutStore
