// For Testing, feel free to update
export type Root = Root2[]

export interface Root2 {
  slug: string
  kybInfo: KybInfo
  medias: Medias
  socialNetworks: SocialNetwork[]
  web: Web[]
  cryptoWallets: CryptoWallets
  blockpass: Blockpass
}

export interface KybInfo {
  businessDetails: BusinessDetails
}

export interface BusinessDetails {
  name: string
  jurisdiction: string
  brandName: string
}

export interface Medias {
  logo?: string
}

export interface SocialNetwork {
  name: string
  account: string
}

export interface Web {
  url: string
  urlDisplay: string
  description: string
}

export interface CryptoWallets {
  evm?: Evm[]
  bitcoin?: any[]
}

export interface Evm {
  chainId: string
  publicKey: string
  description: string
}

export interface Blockpass {
  services?: Service[]
}

export interface Service {
  type: string
  clientId: string
  description: string
  logo: string
  register: string
}

const data: Root = [
  {
    slug: 'company1',
    kybInfo: {
      businessDetails: {
        name: 'Company 1234 Name ',
        jurisdiction: 'ARE',
        brandName: 'Company 1234 BrandName',
      },
    },
    medias: {
    },
    socialNetworks: [
    ],
    web: [
    ],
    cryptoWallets: {
      // evm: [
      // ],
      bitcoin: [
      ],
    },
    blockpass: {
      // services: [
      // ],
    },
  },
  {
    slug: 'Company 2',
    kybInfo: {
      businessDetails: {
        name: 'Company 5678 Name',
        jurisdiction: 'GBR',
        brandName: 'Company 5678 BrandName',
      },
    },
    medias: {
      logo: 'https://cdn.blockpass.org/upload-qa/663ae647e176166c2d1d6f45-service-thumbnail?t=1715309142695',
    },
    socialNetworks: [
      {
        name: 'youtube',
        account: 'watchingmovie',
      },
    ],
    web: [
      {
        url: 'https://www.blockpass.org/',
        urlDisplay: 'www.blockpass.com',
        description: 'Blockpass website description',
      },
    ],
    cryptoWallets: {
      evm: [
        {
          chainId: 'ETH',
          publicKey: 'publichkey.....evmkeykey 1',
          description: 'crypto wallet evm description 1',
        },
        {
          chainId: 'ADA',
          publicKey: 'publichkey.....evmkeykey 2',
          description: 'crypto wallet evm description 2',
        },
        {
          chainId: 'AVAX',
          publicKey: 'publichkey.....evmkeykey 3',
          description: 'crypto wallet evm description 3',
        },
      ],
      bitcoin: [
        {
          chainId: 'BTC',
          publicKey: 'publichkey.....bitcoinkeykey 4',
          description:
            'crypto wallet bitcoin yryryry wewewe1212 crypto wallet bitcoin description description crypto wallet bitcoin description crypto wallet bitcoin description wewewe1212 crypto wallet bitcoin description',
        },
      ],
    },
    blockpass: {
      services: [
        {
          type: 'kyc',
          clientId: 'qt080524_5aea9',
          description: 'services description ',
          logo: 'https://cdn.blockpass.org/upload-qa/661c9f0db8aaf58fcef5266a-service-thumbnail?t=1715328277528',
          register:
            'https://verify-with.blockpass.org/?clientId=qt080524_5aea9&env=qa',
        },
        {
          type: 'kyc',
          clientId: 'kyc_2',
          description: 'services description ',
          logo: 'https://cdn.blockpass.org/upload-qa/661c9f0db8aaf58fcef5266a-service-thumbnail?t=1715328277528',
          register:
            'https://verify-with.blockpass.org/?clientId=qt080524_5aea9&env=qa',
        },
        {
          type: 'kyc',
          clientId: 'kyc_3',
          description:
            'services description services description services description services description services description services description services description services description services description services description services description ',
          logo: 'https://cdn.blockpass.org/upload-qa/661c9f0db8aaf58fcef5266a-service-thumbnail?t=1715328277528',
          register:
            'https://verify-with.blockpass.org/?clientId=qt080524_5aea9&env=qa',
        },
        {
          type: 'kyb',
          clientId: 'kyb_1',
          description: 'services description ',
          logo: 'https://cdn.blockpass.org/upload-qa/661c9f0db8aaf58fcef5266a-service-thumbnail?t=1715328277528',
          register: 'https://qa-kyb.blockpass.org/?kybClientId=kyb_test_5fb9f',
        },
        {
          type: 'kyb',
          clientId: 'kyb_2',
          description:
            'services description services description services description services description services description services description services description services description services description services description ',
          logo: 'https://cdn.blockpass.org/upload-qa/661c9f0db8aaf58fcef5266a-service-thumbnail?t=1715328277528',
          register: 'https://qa-kyb.blockpass.org/?kybClientId=kyb_test_5fb9f',
        },
      ],
    },
  },
  {
    slug: 'Company 3',
    kybInfo: {
      businessDetails: {
        name: 'Company 999 Name',
        jurisdiction: 'VNM',
        brandName: 'Company 999 BrandName',
      },
    },
    medias: {
      logo: 'https://cdn.blockpass.org/upload-qa/663ae647e176166c2d1d6f45-service-thumbnail?t=1715309142695',
    },
    socialNetworks: [
      {
        name: 'discord',
        account: 'nguyengamer',
      },
      {
        name: 'pinterest',
        account: 'toanlovegirl',
      },
      {
        name: 'medium',
        account: 'ttin',
      },
    ],
    web: [
      {
        url: 'https://www.abcd.org/',
        urlDisplay: 'www.abcd.org',
        description: 'abcd website description',
      },
    ],
    cryptoWallets: {
      evm: [
        {
          chainId: 'ETH',
          publicKey: 'publichkey.....evmkeykey 1',
          description: 'crypto wallet evm description 1',
        },
        {
          chainId: 'ADA',
          publicKey: 'publichkey.....evmkeykey 2',
          description: 'crypto wallet evm description 2',
        },
      
      ],
      bitcoin: [
        {
          chainId: 'BTC',
          publicKey: 'publichkey.....bitcoinkeykey 4',
          description:
            'crypto wallet bitcoin yryryry wewewe1212 crypto wallet bitcoin description description crypto wallet bitcoin description crypto wallet bitcoin description wewewe1212 crypto wallet bitcoin description',
        },
      ],
    },
    blockpass: {
      services: [
        {
          type: 'kyc',
          clientId: 'qt080524_5aea9',
          description: 'services description ',
          logo: 'https://cdn.blockpass.org/upload-qa/661c9f0db8aaf58fcef5266a-service-thumbnail?t=1715328277528',
          register:
            'https://verify-with.blockpass.org/?clientId=qt080524_5aea9&env=qa',
        },
        {
          type: 'kyc',
          clientId: 'kyc_2',
          description: 'services description ',
          logo: 'https://cdn.blockpass.org/upload-qa/661c9f0db8aaf58fcef5266a-service-thumbnail?t=1715328277528',
          register:
            'https://verify-with.blockpass.org/?clientId=qt080524_5aea9&env=qa',
        },
        {
          type: 'kyc',
          clientId: 'kyc_3',
          description:
            'services description services description services description services description services description services description services description services description services description services description services description ',
          logo: 'https://cdn.blockpass.org/upload-qa/661c9f0db8aaf58fcef5266a-service-thumbnail?t=1715328277528',
          register:
            'https://verify-with.blockpass.org/?clientId=qt080524_5aea9&env=qa',
        },
        {
          type: 'kyb',
          clientId: 'kyb_1',
          description: 'services description ',
          logo: 'https://cdn.blockpass.org/upload-qa/661c9f0db8aaf58fcef5266a-service-thumbnail?t=1715328277528',
          register: 'https://qa-kyb.blockpass.org/?kybClientId=kyb_test_5fb9f',
        },
        {
          type: 'kyb',
          clientId: 'kyb_2',
          description:
            'services description services description services description services description services description services description services description services description services description services description ',
          logo: 'https://cdn.blockpass.org/upload-qa/661c9f0db8aaf58fcef5266a-service-thumbnail?t=1715328277528',
          register: 'https://qa-kyb.blockpass.org/?kybClientId=kyb_test_5fb9f',
        },
      ],
    },
  },
]
export default data


// let res = [...data]
// for (let i = 0; i < 6; i++) {
//   res = [...res, ...res]
// }
// export default res
