import React from 'react'
import { observer } from 'mobx-react-lite'
import { CopyIcon } from '@workspace/shared/Icon/CommonIcons';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from "@blockpass-org/ui";
import Button from '@mui/material/Button';
import { COLOR } from '@workspace/shared/theme/colors'
import { styled } from '@mui/material/styles';
import useRootStore from '@hooks/useRootStore'
import { useClipboard } from '@hooks/useClipboardCopy'
import DashboardTooltip from "../DashboardTooltip";
const useStyles = makeStyles((theme) => ({
  containerButton: {
    display: 'flex !important',
    textAlign: 'left',
    // margin: 'auto !important',
    marginLeft: '6px  !important',
    height: '28px',
    width: '28px'
  },
  innerText: {
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

  },
  textPrimary: {
    color: `${COLOR.commonColor.primary} !important`
  }
}))
// type StyledButtonProps = ButtonProps & { border?: boolean, size28?: boolean }
const ColorButton = styled(Button)<{ ButtonProps, border, size }>(({ theme, border, size }) => {

  return (
    {
      backgroundColor: '#ffffff',
      borderRadius: 4,
      textTransform: 'inherit',
      minWidth: '24px !important',
      padding: '0px !important',
      width: `${size}px !important`,
      height: `${size}px !important`,
      // boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2)',
      border: `${border ? '1px solid #C4C4C4' : '0px solid #C4C4C4'}`,
      '&:hover': {
        backgroundColor: '#F2F2F2',
      },
      '.MuiTouchRipple-child': {
        backgroundColor: `${COLOR.primaryGreyScale[8104]} !important`
      }
    })
});


export const ButtonCopy2 = (props: any) => {
  /* eslint-disable */
  const [hover, setHover] = React.useState(false);
  const [hoverText, setHoverText] = React.useState('txtCopy');
  /* eslint-enable */
  const classes = useStyles()
  const {
    // LocalizationStore: { translate },
    LocalizationStore,
    // UISnackbarStore
  } = useRootStore()
  const { label, copyValue } = props
  const onClickCopy = () => {
    clipboard.copy(copyValue)

  }

  const clipboard = useClipboard({
    onSuccess() {
      setHoverText('txtCopied')
      setTimeout(() => {
        setHoverText('txtCopy')
      }, 3000)

    },
  })

  const onMouseEnter = () => {
    setHover(true)
  }
  const onMouseLeave = () => {
    setHover(false)
  }

  return (
    <>
      <DashboardTooltip
        title={<Typography variant='body2' color={'white'}>
          {LocalizationStore?.translate(hoverText)}
        </Typography>}
        arrow>
        <ColorButton
          onClick={onClickCopy}
          className={classes.containerButton}
          border={props.border}
          size={props.size}
          // disableRipple
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave} ButtonProps={undefined} >

          <CopyIcon fontSize="small" />
          <div className={classes.innerText}>
            <Typography className={classes.textPrimary} variant='body2'> {LocalizationStore?.translate(label)}</Typography>
          </div>

        </ColorButton >
      </DashboardTooltip>
    </>
  )
}

export default observer(ButtonCopy2)

