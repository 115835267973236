import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
// import COLOR from '@theme/colors'
import { LayoutMain, MainWrapper } from './Main.styled'
import useRootStore from '@hooks/useRootStore'
export const Layout: React.FC = ({ children }) => {
  const { DashboardStore } = useRootStore()
  const embedded = DashboardStore.embedded
  const hideLayout = DashboardStore.hideLayout
  let marginTop = 56
  let marginBottom = 56
  if (embedded) {
    marginTop = 24
    marginBottom = 24
  } else {
    if (hideLayout) {
      // move to padding
      marginTop = 0
      marginBottom = 0
    } else {
      marginTop = 56
      marginBottom = 32
    }
  }

  const layoutRef = useRef(null)

  useEffect(() => {
    const sendHeight = () => {
      if (layoutRef.current) {
        const height = layoutRef.current.offsetHeight
        window.parent.postMessage({ height }, '*')
      }
    }
    const obs = new ResizeObserver(sendHeight)
    sendHeight() // Initial height

    // Send height on content change
    obs.observe(layoutRef.current)
    return () => {
      obs.disconnect()
    }
  }, [])

  return (
    <MainWrapper style={{ marginTop: marginTop, marginBottom: marginBottom }}>
      <LayoutMain ref={layoutRef}>{children}</LayoutMain>
    </MainWrapper>
  )
}

export default observer(Layout)
