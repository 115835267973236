export const EV_METADATA_UPDATE = 'EV_METADATA_UPDATE'
export const EV_JOBS_UPDATE = 'EV_JOBS_UPDATE'
export const EV_PENDING_MIGRATION_UPDATE = 'EV_PENDING_MIGRATION_UPDATE'
export const EV_ACTIVE_SERVICE_METADATA_UPDATE =
  'EV_ACTIVE_SERVICE_METADATA_UPDATE'
export const EV_ACTIVE_CLIENT_ID_CHANGE = 'EV_ACTIVE_CLIENT_ID_CHANGE'
export const EV_RESUBMIT = 'EV_RESUBMIT'
export const EV_REGISTER = 'EV_REGISTER'
export const EV_ATTB_UPDATE = 'EV_ATTB_UPDATE'
export const EV_BOOT_COMPLETED = 'EV_BOOT_COMPLETED'
export const EV_BROWSER_MSG = 'EV_BROWSER_MSG'
export const EV_LOGOUT = 'EV_LOGOUT'

// Browser tabs communication
export const EV_BROWSER_AUTH_STATE_LOGGEDIN = 'EV_BROWSER_AUTH_STATE_LOGGEDIN'
export const EV_BROWSER_AUTH_STATE_LOGGEDOUT = 'EV_BROWSER_AUTH_STATE_LOGGEDOUT'

// Network change
export const EV_NETWORK_OFFLINE = 'EV_NETWORK_OFFLINE'
export const EV_NETWORK_ONLINE = 'EV_NETWORK_ONLINE'

export const EV_SCROLL_TO_FIRST_ERROR_FIELD = 'EV_SCROLL_TO_FIRST_ERROR_FIELD'
export const EV_SCROLL_TO_NEAREST_SERVICE = 'EV_SCROLL_TO_NEAREST_SERVICE'
