// cloned from https://github.com/feross/clipboard-copy
import { useCallback, useRef } from 'react'

async function clipboardCopy(text) {
  // Put the text to copy into a <span>
  const span = document.createElement('span')
  span.textContent = text

  // Preserve consecutive spaces and newlines
  span.style.whiteSpace = 'pre'
  span.style.webkitUserSelect = 'auto'
  span.style.userSelect = 'all'

  // Add the <span> to the page
  document.body.appendChild(span)

  // Make a selection object representing the range of text selected by the user
  const selection = window.getSelection()
  const range = window.document.createRange()
  selection.removeAllRanges()
  range.selectNode(span)
  selection.addRange(range)

  // Copy text to the clipboard
  let success = false
  try {
    success = window.document.execCommand('copy')
  } finally {
    // Cleanup
    selection.removeAllRanges()
    window.document.body.removeChild(span)
  }

  if (!success)
    throw new DOMException('The request is not allowed', 'NotAllowedError')
}

export interface UseClipboardOptions {
  copiedTimeout?: number
  onSuccess?: () => void
  onError?: () => void
  selectOnCopy?: boolean
  selectOnError?: boolean
}

export interface ClipboardAPI {
  readonly copy: (text?: string | any) => void
  readonly isSupported: () => boolean
  readonly target: React.RefObject<any>
}

function isInputLike(
  node: any
): node is HTMLInputElement | HTMLTextAreaElement {
  return node && (node.nodeName === 'TEXTAREA' || node.nodeName === 'INPUT')
}

export function useClipboard(options: UseClipboardOptions = {}): ClipboardAPI {
  const targetRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null)
  const optionsRef = useRef<UseClipboardOptions>(options)
  optionsRef.current = options

  function isSupported() {
    return (
      !!navigator.clipboard ||
      (typeof document.execCommand === 'function' &&
        typeof document.queryCommandSupported === 'function' &&
        document.queryCommandSupported('copy'))
    )
  }

  const copyHandler = useCallback((text?: any) => {
    const opts = optionsRef.current
    const target = targetRef.current

    function handleSuccess() {
      if (opts.onSuccess) {
        opts.onSuccess()
      }
      if (opts.selectOnCopy && isInputLike(target)) {
        target.select()
      }
    }

    function handleError() {
      if (opts.onError) {
        opts.onError()
      }
      if (opts.selectOnError !== false && isInputLike(target)) {
        target.select()
      }
    }

    function copy(value: string) {
      clipboardCopy(value).then(handleSuccess).catch(handleError)
    }

    if (typeof text === 'string') {
      copy(text)
    } else if (target) {
      copy(target.value)
    }
  }, [])

  return {
    copy: copyHandler,
    isSupported,
    target: targetRef,
  }
}
