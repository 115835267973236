import React, { useEffect } from 'react'
import RootStore from '@stores/RootStore'

type StoreProviderProps = {
  children: React.ReactNode
  store: any
  onLoad?: Function
}

export const StoreContext = React.createContext<RootStore | null>(null)

const ProviderWrapper = ({ children, store, onLoad }: StoreProviderProps) => {
  // Debug enabled
  if (process.env.NODE_ENV !== 'production') (window as any).rootStore = store
  useEffect(() => {
    onLoad && onLoad()
  })
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

ProviderWrapper.displayName = 'AppProvider'

export default ProviderWrapper
