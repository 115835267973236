/* eslint-disable */
// prettier-ignore
export default {
  "txPersonalAttributeDesc": "オンボーディング中にユーザーから追加情報をリクエストできます。これらの属性は、ブロックパスによって検証されていません。",
  "txt404": "探しているページは見つかりません！",
  "txtAML": "AML",
  "txtAMLCheck": "AMLチェック",
  "txtAMLDesc1": "PEP/制裁リストのグローバルデータベースと不利なメディアに対して画面を画面化し、顧客が政治的に暴露されているか、認可されているか、または有害なメディアプロファイルを持っているかを特定します。",
  "txtAMLDesc2": "このチェックでは、ユーザーがフルネームと生年月日を提供する必要があります。",
  "txtAPIAndWebhooks": "APIおよびWebHooks",
  "txtAccountDeletion": "アカウントの削除",
  "txtAccountDeletionDesc": "モバイルアカウントとWebアカウントを削除します",
  "txtAction": "アクション",
  "txtActive": "アクティブ",
  "txtActivityLogs": "アクティビティログ",
  "txtAdd": "追加",
  "txtAddCompany": "会社を追加します",
  "txtAddNewCompany": "新しい会社を追加します",
  "txtAddNewsContent": "ニュースを追加します",
  "txtAddNotification": "通知を追加します",
  "txtAddPaymentMethod": "支払い方法を追加します",
  "txtAddPaymentMethodFail": "ここで何か問題があります。後で支払い方法を追加してください。",
  "txtAddPaymentMethodSuccessfully": "支払いカードが正常に追加されます",
  "txtAddRichContent": "豊富なコンテンツを追加します",
  "txtAddTag": "タグ付けする",
  "txtAddTagPlaceholder": "タグのラベルを入力してください...",
  "txtAddTagSuccess": "” <0>”タグが正常に追加されました。",
  "txtAddingTag": "タグの追加",
  "txtAdmin": "管理者",
  "txtAdminConsole": "管理コンソール",
  "txtAdminSettingsUpdateSuccess": "管理者設定は正常に更新されました。",
  "txtAdministration": "管理",
  "txtAdvancedApprovalDesc": "高度なKYCボット：",
  "txtAdvancedApprovalDesc1": "ユーザーを自動承認または拒否する10以上のルールのセット。",
  "txtAdvancedApprovalTooltip": "このプランでは、高度なKYCボットは利用できません。有効にしたい場合はお問い合わせください。",
  "txtAfterReturn": "APIがデータを返した後",
  "txtAgeRestriction": "年齢制限",
  "txtAgeRestrictionDesc1": "あなたは彼らの年齢に基づいて応募者を除外することができます。",
  "txtAgeRestrictionDesc2": "申請者は少なくともそうでなければなりません",
  "txtAgeRestrictionDesc3": "年齢。",
  "txtAllPlanFeatureHeader": "すべての計画には、次の機能が含まれています",
  "txtAllTimeAreUTC": "（すべての時間はUTCです）",
  "txtAllowed": "許可された",
  "txtAllowedCountries": "許可された国",
  "txtAnnouncements": "発表",
  "txtAnyoneWithTheLink": "リンクを持っている人",
  "txtAnyoneWithTheLinkDesc1": "このリンクを持っている人なら誰でもあなたのサービスに登録できます。",
  "txtAnyoneWithTheLinkDesc2": "このページを使用すると、申請者はKYC情報をダッシュ​​ボードに送信できます。リンクを電子メールまたは共有したい場所にコピーして貼り付けます。",
  "txtApiKey": "APIキー",
  "txtApiKeyCopied": "APIキーがコピーされました",
  "txtApiKeyDeleteDesc": "削除すると、APIキーを回復できません。キーを削除できるかどうかが完全にわからない場合は、代わりにステータスを非アクティブに設定できます。",
  "txtApiKeys": "APIキー",
  "txtApply": " 適用する。",
  "txtApproveAdvanced": "高度なKYCボット",
  "txtApproveBasic": "KYCボット",
  "txtApproveDesc1": "レビューモードを選択することで時間を節約できます。\n",
  "txtApproveDesc2": "KYCボットは、大量のユーザーに推奨されます。見る",
  "txtApproveDesc3": "詳細",
  "txtApproveDesc4": " ドキュメントで",
  "txtApproveFreeManual": "マニュアル：各ユーザーを手動で承認または拒否します。オンボーディングプロセスをスピードアップする計画を選択します。",
  "txtApproveNone": "手動レビュー",
  "txtAssignCompanyToService": "会社の情報を追加します",
  "txtAssignRole": "割り当てられた役割",
  "txtAssignUserRole": "割り当てられた役割",
  "txtAttributeDataDesc": "プロファイルがアーカイブされると、メタデータのみが残り、ID属性が削除されます。",
  "txtAttributesConfig": "属性config",
  "txtAttributesConfigDesc": "ユーザープロファイルで使用可能な属性を構成します",
  "txtAttributesGroupConfig": "属性グループ構成",
  "txtAttributesGroupConfigDesc": "ユーザープロファイルで利用可能な属性のグループを構成します",
  "txtAutoApproval": "自動承認",
  "txtAutoArchive": "ユーザーのプロフィールを自動アーカイブします",
  "txtAutoArchiveTooltip": "自動アーカイブAPIがデータを返した後、ユーザーのプロフィール@@ ar_ch_ti_me @@ seconds",
  "txtAutoTranslate": "（自動翻訳）",
  "txtAutomationRules": "自動化ルール",
  "txtAvatar": "アバター",
  "txtAvaxAddress": "雪崩アドレス",
  "txtBack": "戻る",
  "txtBasicApprovalDesc": "KYCボット：",
  "txtBasicApprovalDesc1": "Autoは、クリアチェックでユーザーを承認します。",
  "txtBasicInfo": "基本情報",
  "txtBestPractices": "ベストプラクティス",
  "txtBilling": "請求する",
  "txtBillingInfo": "請求情報",
  "txtBillingInfomation": "請求情報",
  "txtBillingOwner": "請求所有者",
  "txtBinanceAddress": "Binance（BSC）アドレス",
  "txtBitcoinAddress": "ビットコインアドレス",
  "txtBlockpass": "Blockpass",
  "txtBlockpassKycRules": "BlockPass KYCルール",
  "txtBpKYCRules": "BlockPass KYCルール",
  "txtBrowse": "ブラウズ",
  "txtBtcAdress": "ビットコインアドレス",
  "txtBusinessInfomation": "ビジネス情報",
  "txtBusinessTerms": "ビジネス用語",
  "txtCanNotSetTagsOnChainKYCForMultipleCryptoService": "サービス用のタグを設定できませんonchainkycは複数の暗号フィールドを持っています",
  "txtCancel": "キャンセル",
  "txtCardHolderName": "クレジットカード名義人氏名",
  "txtCardInfomation": "カードインフォメーション",
  "txtCardanoAddress": "カルダノ（ADA）アドレス",
  "txtCaution": "注意 ！",
  "txtCautionDesc1": "現在ユーザーを搭載している場合は、計画を変更することはお勧めしません。支援についてはお問い合わせください。",
  "txtCautionDesc2": "それ以外の場合は、構成を続行できます。",
  "txtCertificatesOrderConfig": "証明書の注文設定",
  "txtCertificatesOrderConfigDesc": "ユーザープロファイルで証明書の注文を構成します",
  "txtChangeBillingPlan": "請求計画を変更します",
  "txtChangePlan": "プラン変更",
  "txtChangeWebhook": "WebHookを更新しました",
  "txtChartEmptyTooltip": "このチャートは、ユーザーがサービスを使用するときに居住します。",
  "txtCheckDebugger": "デバッガーを確認してください",
  "txtCheckDebuggerDesc": "IDをチェックしてチェックを検索します。通常、チェックは証明書を発行します",
  "txtChecksConfig": "Checks Config",
  "txtChecksConfigDesc": "チェックを定義する属性と証明書を構成します",
  "txtChinese": "中国語",
  "txtClickOrDragToUpload": "クリックまたはドラッグしてロゴをアップロードします",
  "txtClientId": "クライアントID",
  "txtClientIdExisted": "クライアントIDはすでに存在します、別のクライアントIDを使用してください",
  "txtClientIdTooltip": "あなたのサービスのユニークな識別子。\n自動的に生成されます\nあなたのサービス名に",
  "txtClose": "近い",
  "txtCodeHasBeenCopied": "コードがコピーされました",
  "txtCodeSample": "コードサンプル",
  "txtCompany": "会社",
  "txtCompanyAddress": "会社の住所",
  "txtCompanyAddressCity": "市",
  "txtCompanyAddressCountry": "国",
  "txtCompanyAddressLine1": "住所1",
  "txtCompanyAddressLine1IsRequired": "会社のアドレス1行1が必要です。",
  "txtCompanyAddressLine2": "アドレスライン2（オプション）",
  "txtCompanyAddressPostalCode": "郵便番号",
  "txtCompanyAddressPostalCodeRequired": "郵便番号が必要です。",
  "txtCompanyAddressState": "州",
  "txtCompanyCity": "市",
  "txtCompanyCountryIsRequired": "国が必要です",
  "txtCompanyCustomRequest": "メッセージ",
  "txtCompanyEmailIsRequired": "会社の電子メールが必要です。",
  "txtCompanyGroup": "3.会社",
  "txtCompanyInfo": "会社情報",
  "txtCompanyInfomation": "企業情報",
  "txtCompanyName": "会社名",
  "txtCompanyNameIsRequired": "会社名が必要です",
  "txtCompanyNameRepIsRequired": "会社の代表者の名前が必要です。",
  "txtCompanyNumber": "会社番号",
  "txtCompanyNumberIsRequired": "会社番号が必要です",
  "txtCompanyPhoneRepIsRequired": "会社の代表者の電話が必要です。",
  "txtCompanyPostalCode": "郵便番号",
  "txtCompanyRepresentative": "会社の代表者の名前",
  "txtCompanyState": "州",
  "txtCompanyVAT": "税金またはVAT番号",
  "txtCompanyVATNumber": "税金またはVAT番号",
  "txtCompanyVATNumberIsRequired": "会社のVAT番号が必要です",
  "txtConfiguration": "構成",
  "txtConfirm": "確認する",
  "txtConfirmDeleteCompanyInfo": "前のステップに戻ると、入力された会社の情報が消去されます。",
  "txtConnectWithUs": "私達と接続",
  "txtContactSale": "販売に連絡してください",
  "txtContactSaleTeam": "営業チームに連絡してください",
  "txtContactSupport": "販売に連絡してください",
  "txtContent": "コンテンツ",
  "txtContinue": "続く",
  "txtContinueToConfigureService": "サービスの構成を続けます",
  "txtCopied": "コピー！",
  "txtCopy": "コピー",
  "txtCopyAndClose": "コピーして閉じます",
  "txtCopyApiKeyWarning": "ポップアップを閉じたらすぐにそれを取得することはできません。安全な場所に保管してください。\n紛失した場合、新しいキーを生成する必要があります。",
  "txtCopyLink": "リンクをコピーする",
  "txtCopyright": "©BlockPass",
  "txtCount": "カウント",
  "txtCountryRestriction": "国の制限",
  "txtCountryRestrictionDesc1": "一部の国から住民を除外したり、他の住民のみを受け入れることができます。このオプションでは、ユーザーが住所を提供する必要があります。",
  "txtCountryRestrictionDesc2": "ここで提供される国は例のみです。制限するために、国に関するコンプライアンス担当者に確認する必要があります。",
  "txtCountryRestrictionDescAllow": "からの住民のみを許可します",
  "txtCountryRestrictionDescExclude": "住民を除外します",
  "txtCoutryOfIncorporation": "設立国",
  "txtCreate": "作成する",
  "txtCreateAPIKey": "APIキーを作成します",
  "txtCreateAPIKeyFooter1": "APIキーを公開すると、ユーザーの個人データが侵害される可能性があります。",
  "txtCreateAPIKeyFooter2": "あなたは従わなければなりません",
  "txtCreateAPIKeyFooter3": " APIキーを安全に使用するため。",
  "txtCreateAccount": "新しいアカウントを作成しました",
  "txtCreateApiKeySuccess": "新しいAPIキーが作成されました",
  "txtCreateBillingPlan": "請求計画を作成します",
  "txtCreateCertificate": "新しい証明書を作成しました",
  "txtCreateCheckConfig": "Check Configを作成します",
  "txtCreateCompany": "会社を作成します",
  "txtCreateFlowConfig": "フロー構成を作成します",
  "txtCreateIdentityField": "作成されたアイデンティティフィールド",
  "txtCreateKYCSettings": "KYC設定を作成しました",
  "txtCreateNewTag": "タグを作成します",
  "txtCreateRuntimeCfg": "実行されたランタイム構成",
  "txtCreateService": "新しいサービスを作成しました",
  "txtCreateServiceDashboard": "サービスを作成します",
  "txtCreateServiceSuccess": "新しいサービスが正常に作成されました",
  "txtCreateVersion": "新しいAPIバージョンを作成しました",
  "txtCreateWebhook": "WebHookを作成しました",
  "txtCryptoAddresses": "暗号アドレス",
  "txtCurrentPlan": "現在の計画",
  "txtCustomRegistrationUrl": "カスタム登録URL",
  "txtCustomUrlInvalid": "このURLは無効です。",
  "txtCustomiseAppLink": "モバイル用のアプリリンク",
  "txtDAY": "日",
  "txtDOB": "生年月日",
  "txtDashboard": "ダッシュボード",
  "txtDataExport": "データエクスポート",
  "txtDate": "日付",
  "txtDelete": "消去",
  "txtDeleteAPIKey": "APIキーを削除します",
  "txtDeleteApiKeySuccess": "APIキーが削除されました",
  "txtDeleteBulkIdentityRecordConfirmMessage": "これらのアカウントを削除したいですか？",
  "txtDeleteThisApiKey": "このAPIキーを削除します",
  "txtDeleteVersion": "削除されたAPIバージョン",
  "txtDeleteWebhook": "削除されたwebhook",
  "txtDescription": "説明",
  "txtDescriptionIsRequired": "このAPIキーの説明を入力してください",
  "txtDetachPaymentMethod": "支払い方法を取り外します",
  "txtDetails": "詳細",
  "txtDiscard": "破棄",
  "txtDiscardRichContent": "豊富なコンテンツを捨てます",
  "txtDisplay": "画面",
  "txtDoNotSendAddMember": "新しいメンバーを招待する許可はありません。サービスの所有者に連絡してください。エラーコード403。",
  "txtDocs": "ドキュメント",
  "txtDocumentation": "ドキュメンテーション",
  "txtDonNotHaveService": "この会社にはサービスがありません",
  "txtDontHavePaymentMethod": "支払い方法はありません。以下に支払い方法を追加してください",
  "txtDownload": "ダウンロード",
  "txtDownloadLogs": "ログをダウンロードします",
  "txtDrivingLicense": "運転免許証",
  "txtDropFileHere": "ここにロゴをドロップします",
  "txtEditClientId": "クライアントIDを編集します",
  "txtEditClientIdWarning": "このクライアントIDは後で変更することはできず、一意でなければなりません。",
  "txtEditConfig": "構成を編集します",
  "txtEditNewsContent": "ニュースを編集します",
  "txtEditSearch": "検索を編集します",
  "txtEmail": "Eメール",
  "txtEmailAddress": "電子メールアドレス",
  "txtEmailRepresentative": "会社の代表者のメール",
  "txtEmailWrongFormat": "間違った電子メール形式",
  "txtEnable": "有効にする",
  "txtEnableApiKeys": "APIキーを有効にします",
  "txtEnableApiKeysDes": "顧客がAPIキーを使用してユーザーのデータにアクセスできるようにします。",
  "txtEnableEndUserRegistrations": "エンドユーザー登録を有効にします",
  "txtEnableEndUserRegistrationsDes": "エンドユーザーがこのサービスで検証に登録できるようになります。",
  "txtEnableKycDashboard": "KYCダッシュボードを有効にします",
  "txtEnableKycDashboardDes": "顧客がKYCダッシュボードにアクセスできるようにします。",
  "txtEnableService": "サービスを有効にします",
  "txtEnableServiceDes": "このオプションは危険です。サービスのオンとオフを頻繁に回すと、深刻なエラーが発生する可能性があります。",
  "txtEndDate": "終了日",
  "txtEnglish": "英語",
  "txtEnterpriseDesc1": "1か月あたり最低100個のプロファイル",
  "txtEnterpriseDesc2": " 詳細については。",
  "txtEnterpriseSentDesc1": "エンタープライズプランを選択していただきありがとうございます。当社の営業チームは、できるだけ早くお客様に連絡し、要件に従ってサービスを構成します。\n",
  "txtEnterpriseSentDesc2": "それまでの間、支払い方法を追加して請求情報を完成させてください。プランを有効にする必要があります",
  "txtErrorUnsupportFile": "サポートされていないファイル",
  "txtEthAddress": "イーサリアムアドレス",
  "txtEthereumAddress": "イーサリアムアドレス",
  "txtEvent": "イベント",
  "txtExample": "例",
  "txtExcept": "を除外する",
  "txtExcluded": "除外",
  "txtExcludedCountries": "除外された国",
  "txtExpiryDate": "有効期限",
  "txtExploreKyc": "KYC Connectダッシュボードを調べてください。",
  "txtExportServiceConfig": "エクスポートサービス構成",
  "txtFaceMatch": "フェイスマッチ",
  "txtFaceMatchCheck": "フェイスマッチチェック",
  "txtFaceMatchDesc1": "BlockPassは、ユーザーのIDの写真を登録中にリクエストされたライブセルフィーと比較します。",
  "txtFaceMatchDesc2": "このチェックでは、ユーザーが自分の身元ドキュメントとライブセルフィーを提供する必要があります。",
  "txtFacematchCheck": "FaceMatchチェック",
  "txtFailedToSendMemberInvitation": "メンバーの招待状を送信できませんでした",
  "txtFamilyName": "苗字",
  "txtFaq": "よくある質問",
  "txtFileNotSupport": "このファイルはサポートされていません",
  "txtFillCompanyInfo": "以下の既存の会社に基づいて情報を入力するか、新しい会社を追加してください。",
  "txtFlowAddress": "フロー（フロー）アドレス",
  "txtFlowDebugger": "フローデバッガー",
  "txtFlowDebuggerDesc": "フローIDでフローを検索します。フローには複数のチェックを含めることができます",
  "txtFlowsConfig": "Flows Config",
  "txtFlowsConfigDesc": "フローを定義するチェックを構成します",
  "txtForbidden": "このアクションを実行する許可はありません",
  "txtFreePlanDescription": "未検証のアイデンティティを受け取ります。 1日10個に制限されています。",
  "txtFreeTrial": "無料トライアル",
  "txtFrench": "フランス語",
  "txtFunctionUnavailable": "機能は一時的に利用できません",
  "txtGeneralInfomation": "一般的な情報",
  "txtGeoRestriction": "地理的制限",
  "txtGetServiceDisplayFailed": "サービス表示に失敗します。",
  "txtGetServiceListFailed": "サービスリストに失敗します。",
  "txtGetServiceWebhookListFailed": "サービスWebhooksが失敗します。",
  "txtGivenName": "名",
  "txtGoToDashboard": "ダッシュボードに進め",
  "txtGoToServiceDashboard": "サービスダッシュボードに移動します",
  "txtGoToServiceList": "後で",
  "txtGotoDashboardDesc1": "このサービスのためにKYC Connectダッシュボードに移動します。",
  "txtGotoDashboardDesc2": "ダッシュボードは、チームが個人がサービスにアップロードしたアイデンティティをレビューできる場所です。",
  "txtHelp": "ヘルプ",
  "txtIdEngine": "IDエンジン",
  "txtIdEngineDesc": "テストIDエンジンOCRツール",
  "txtIdentityDesc1": "BlockPassは、パスポート、全国IDカード、および運転免許証を検証します。",
  "txtIdentityDesc2": "ユーザーは、ドキュメントのライブ写真を撮るように要求されます。",
  "txtIdentityDesc3": "このオプションでは、ユーザーが身元のドキュメント、フルネーム、生年月日を提供する必要があります。",
  "txtIdentityDocument": "身分証明書",
  "txtIdentityDocumentCheck": "IDドキュメントチェック",
  "txtIdentityLink1": "支援国のリストを参照してください",
  "txtImageTooBig": "画像が大きすぎます",
  "txtImgDimensionBetween10242048": "512x512から2048x2048ピクセルの間に画像をアップロードしてください",
  "txtImgDimensionRule": "PNG、JPEG。 min 512x512ピクセル、最大2048x2048ピクセル。",
  "txtImpactChanging": "衝撃充電",
  "txtInactive": "非活性",
  "txtIncludeRawDataExceptPictureTooltip": "写真を除くメタデータとID属性を返します",
  "txtIncludeRawDataIncludePictureTooltip": "写真を含むメタデータとID属性を返します",
  "txtIncludeRawDataTooltip": "写真を除くメタデータとID属性を返します",
  "txtIncluding": "含む",
  "txtIncorrectFormat": "フィールドには、通常 /上部文字、数字、およびアンダースコアのみを含める必要があります（_）",
  "txtInfomation": "情報",
  "txtInputYourServiceName": "サービス名を入力します",
  "txtInternalServerError": "サーバーで予期しないエラーが発生しました",
  "txtInvalidEmailFormat": "無効な電子メール形式",
  "txtInvalidEmails": "無効なメール",
  "txtInvalidParams": "リクエストには無効なパラメーターが含まれています。入力を再確認してください",
  "txtInvalidURL": "URLは無効です",
  "txtInvalidUrl": "このURLは無効です",
  "txtInvitationRemoved": "招待状が削除されました",
  "txtInvitationResent": "招待状がresしています",
  "txtInviteAlreadySent": "すでに送信された招待",
  "txtInviteUser": "招待されたユーザー",
  "txtIsCopiedToClipboard": "クリップボードにコピーされます。",
  "txtIsSuccess": "成功",
  "txtJapanese": "日本語",
  "txtJobId": "ジョブID",
  "txtKYCBot": "KYCボット",
  "txtKYCDashboardAccess": "KYCダッシュボードアクセス",
  "txtKybDashboard": "KYBダッシュボード",
  "txtKybService": "KYBサービス",
  "txtKybServiceDesc": "KYBは「あなたのビジネスを知る」の略です。 KYBサービスを作成して、ビジネスのアイデンティティを確認します。",
  "txtKybServiceName": "KYBサービス名",
  "txtKybServices": "KYBサービス（WIP）",
  "txtKycDashboard": "KYCダッシュボード",
  "txtKycService": "KYCサービス",
  "txtKycServiceDesc": "KYCは「顧客を知る」の略です。 KYCサービスを作成して、個人のアイデンティティを確認します。",
  "txtKycServiceName": "KYCサービス名",
  "txtKycServices": "KYCサービス",
  "txtLast180Days": "過去180日間",
  "txtLast30Days": "過去30日間",
  "txtLast7Days": "過去7日間",
  "txtLast90Days": "過去90日間",
  "txtLastMonth": "先月",
  "txtLastRunAt": "で最後の実行",
  "txtLatestInvoice": "最新の請求書",
  "txtLatestInvoiceDesc": "請求書は、毎月請求所有者のメールアドレスに送信されます。以下に最後に発行された請求書をダウンロードすることもできます。",
  "txtLearnMoreAboutApiKey": "ブロックパスAPIの詳細をご覧ください",
  "txtLengthTooShort": "フィールドの長さが短すぎます",
  "txtLink": "リンク",
  "txtListDeleteEmail": "削除するメールのリスト、コンマで区切られ、最大50メールまで",
  "txtListOfServices": "サービスのリスト",
  "txtListingRunningCheck": "ランニングチェックのリスト",
  "txtListingRunningCheckDesc": "リストランニングチェックデスクリオン",
  "txtLive": "ライブ",
  "txtLockUser": "ロックされたユーザー",
  "txtLogin": "ログイン",
  "txtLogo": "ロゴ",
  "txtLogoDesc": "PNG、JPEG、JPG。\nMin 512x512 PX、MAX 2048x2048 PX",
  "txtLogoRestriction": "画像サイズは1024 x 1024から2048 x 2048ピクセルでなければなりません",
  "txtLogoSizeError": "アップロードしたロゴは正しいサイズではありません。",
  "txtLogout": "ログアウト",
  "txtLogs": "ログ",
  "txtLongDescription": "長い説明",
  "txtLongDescriptionTooltip": "あなたのサービスの完全な説明（定期的なテキストのみ）",
  "txtMONTH": "月",
  "txtMarkerPlace": "市場",
  "txtMarkerPlaceDesc": "市場のエントリを管理します",
  "txtMarketPlace": "マーケットプレイス-WIP",
  "txtMarketplace": "市場",
  "txtMarketplaceSettingUpdateSuccess": "市場の設定が正常に更新されました。",
  "txtMaticAddress": "ポリゴン（マティック）アドレス",
  "txtMaxEmailsExceeded": "削除ごとに最大50メール",
  "txtMetaDataOnlyTooltip": "メタデータのみを返します（プロファイルステータス、ID、...）",
  "txtMore": "もっと",
  "txtMoreDetails": "詳細",
  "txtMoreInfomation": "より多くの情報",
  "txtName": "名前",
  "txtNameOfCompany": "会社の名前",
  "txtNameOfCompanyIsRequired": "会社の名前が必要です",
  "txtNameOnCard": "カードの名前",
  "txtNameOnCardIncomplete": "カードの名前は不完全です。",
  "txtNameRepresentative": "会社の代表者の名前",
  "txtNationalID": "全国IDカード",
  "txtNationalId": "国民ID",
  "txtNewCompany": "新会社",
  "txtNewsContent": "ニュースコンテンツ",
  "txtNext": "次",
  "txtNoActivityLogs": "検索に一致するアクティビティはありません",
  "txtNoActivityLogsDesc": "あまり具体的ではないか、異なるキーワードを使用してみてください。",
  "txtNoApiKeyDesc": "メタデータ、個人情報、画像など、個人のアイデンティティを抽出するには、APIキーが必要です。",
  "txtNoApiKeys": "APIキーはありません",
  "txtNoCompanyAdd": "まだ会社の情報を追加していません",
  "txtNoCompanyAddDesc": "以下をクリックして、最初の会社情報を追加してください。",
  "txtNoCountryRestriction": "国はありません。検索を使用して、国をリストに追加します",
  "txtNoFieldsAreUpdated": "フィールドは更新されません",
  "txtNoInvoiceDesc1": "請求書は、毎月請求所有者のメールアドレスに送信されます。",
  "txtNoInvoiceDesc2": "今すぐダウンロードする請求書は発行されていません。",
  "txtNoServiceMatch": "あなたの検索に一致するサービスはありません",
  "txtNoServiceMatchDesc": "あまり具体的ではないか、異なるキーワードを使用してみてください。",
  "txtNoSetupCost": "セットアップコストはありません",
  "txtNoUsageDetail": "表示するデータがありません",
  "txtNoUsageDetailDesc": "現在、利用可能な統計はありません。",
  "txtNone": "なし",
  "txtNoneApprovalDesc": "マニュアル：",
  "txtNoneApprovalDesc1": "各ユーザーを手動で承認または拒否します。",
  "txtNormalDesc1": "月額100個のプロファイル。",
  "txtNotStoreCard": "BlockPassはカードの詳細を保存しません。",
  "txtNotifications": "通知",
  "txtNotificationsDesc": "モバイルユーザーにプッシュ通知を送信します",
  "txtOnboarding": "オンボーディング",
  "txtOnboardingGroup": "2.オンボーディング",
  "txtOnboardingSetting": "オンボーディング設定",
  "txtOnly": " のみ",
  "txtOpenDashboard": "ダッシュボードを開きます",
  "txtOverview": "概要",
  "txtPOA": "住所の証明",
  "txtPage403Desc1": "あなたは許可を持っていません\n このサービスにアクセスします",
  "txtPage403Desc2": "サービスに戻って適切なサービスを見つけたり、サービス所有者に連絡してください。",
  "txtParametersValidationError": "リクエストには無効なパラメーターが含まれています。入力を再確認してください",
  "txtPassport": "パスポート",
  "txtPassword": "パスワード",
  "txtPayAsYouGo": "使った分だけ",
  "txtPaymentMethod": "支払方法",
  "txtPaymentMethodDeleted": "支払い方法は取得できないか、削除されませんでした。",
  "txtPermissionDenied": "このアクションを実行する許可はありません",
  "txtPersonalAtt": "個人属性",
  "txtPersonalAttributes": "個人属性",
  "txtPhoneNumber": "電話番号",
  "txtPhoneNumberWrongFormat": "電話番号は数字でなければなりません",
  "txtPhoneRepresentative": "会社の代表者の電話番号",
  "txtPickYourServiceName": "サービスの名前を選択してください",
  "txtPlan": "プラン",
  "txtPlanConfig": "計画設定",
  "txtPlanConfigDesc": "請求書を送るプロセスは、支払うべきお金を払うように頼むプロセス",
  "txtPlanDescription1": "@@ num_ident @@ Identives spors a daul and business。\n",
  "txtPlanDescription2": "24時間以上処理します。標準",
  "txtPleaseCopyAPIKey": "APIキーをコピーしてください",
  "txtPleaseReplacePaymentMethod": "支払い方法を交換してください。",
  "txtPostalCodeWrongFormat": "フォーマットは間違っています。",
  "txtPrivacy": "プライバシー",
  "txtPrivacyPolicy": "プライバシーポリシー",
  "txtProfile": "プロフィール",
  "txtProofOfAddress": "住所の証明",
  "txtProvideNameOfService": "あなたのサービスの名前を提供します",
  "txtPublicLinkDesc": "リンクを備えたインターネット上の誰でもKYCプロファイルを送信できます。",
  "txtPublishService": "公開されたサービス",
  "txtPushNotification": "プッシュ通知",
  "txtRedirect": "リダイレクト",
  "txtRemove": "取り除く",
  "txtRemoveInvitation": "招待状を削除します",
  "txtRemoveTagConfirmContent": "このタグを削除したいですか？",
  "txtRemoveTagConfirmTitle": "「<0>」タグを削除します",
  "txtRemoveTagSuccess": "” <0>”タグが削除されました。",
  "txtRemoveUser": "削除されたユーザー",
  "txtReplace": "交換する",
  "txtReplacePaymentMethod": "支払い方法を交換します",
  "txtReplacePaymentMethodDesc1": "新しいカードはすべてのサービスに使用されます",
  "txtReplacePaymentMethodDesc2": "注意、支払い方法の変更は、これを使用する多くのサービスに影響を与えます\n支払方法。",
  "txtReport": "報告",
  "txtRepresentative": "代表",
  "txtRequestAccess": "アクセスをリクエストします",
  "txtRequestEnterprise": "エンタープライズプランのリクエスト",
  "txtRequestEnterpriseSent": "送信されるエンタープライズプランのリクエスト！",
  "txtRequired": "* 必須",
  "txtRequiredOneIdentityDocument": "少なくとも1つのIDドキュメントを選択してください",
  "txtRequirementPlaceHolder": "要件とタイムラインについて詳しく教えてください",
  "txtResAddress": "住宅アドレス",
  "txtResidentialAddress": "住宅アドレス",
  "txtRestricted": "制限付き",
  "txtRestrictedDesc1": "あなたのウェブサイトで認証された人のみが登録できます。",
  "txtRestrictedDesc2": "ユーザーをウィジェットに送信する場合は、このオプションを選択します。",
  "txtRestrictedDesc3": "WebサイトにウィジェットがインストールされているURLを入力してください。",
  "txtRestrictedDescLink": "ドキュメントの詳細。",
  "txtRestrictedLinkDesc1": "強制ユーザーは、KYCプロファイルを送信する前にWebサイトにログインします。",
  "txtRestrictedLinkDesc2": "ユーザーをウィジェットに送信する場合は、このオプションを選択します。",
  "txtRestrictedLinkDesc3": "ドキュメントの詳細。",
  "txtReturnAttributeData1": "メタデータとID属性を返します",
  "txtReturnAttributeData2": "ピクチャー",
  "txtReturnAttributeDataExceptPicture1": "メタデータとID属性を返します",
  "txtReturnAttributeDataExceptPicture2": "ピクチャー",
  "txtReturnMetaData1": "戻る",
  "txtReturnMetaData2": "メタデータ（プロファイルステータス、ID、...）",
  "txtReturnToServices": "サービスに戻ります",
  "txtRole": "役割",
  "txtRuleArchiveTime": "1から9999の数",
  "txtRunningFlowConfig": "実行フロー構成",
  "txtRunningFlowConfigDesc": "実行フロー構成の解体",
  "txtRuntimeConfig": "ランタイム構成",
  "txtRuntimeConfigDesc": "コンソールとダッシュボードのパラメーターを構成します",
  "txtRussian": "ロシア",
  "txtSAVE": "保存",
  "txtSampleCode": "サンプルコード",
  "txtSave": "保存",
  "txtSaveChange": "変更内容を保存",
  "txtSaveChanges": "変更内容を保存",
  "txtScheduleDeleteProfilesSuccess": "スケジュールプロファイルを正常に削除します",
  "txtScheduleForBulkDeletion": "バルク削除のスケジュール",
  "txtScope": "範囲",
  "txtSearchActivityLogs": "アクティビティログを検索します",
  "txtSearchCountry": "検索国",
  "txtSearchPlaceHolder": "検索...",
  "txtSeconds": "2番目",
  "txtSeeList": "リストを参照してください。",
  "txtSelectACryptoAddress": "暗号アドレスを選択します",
  "txtSelectAPlan": "プランを選択します",
  "txtSelectAPlanToUnlock": "計画をアップグレードします",
  "txtSelectIdentityAttributes": "選択されたID属性",
  "txtSelectPlan": "[プラン]を選択します",
  "txtSelfie": "セルフィー",
  "txtServiceClientIdIsExisted": "クライアントIDはすでに存在します、別のクライアントIDを使用してください",
  "txtServiceCompanyInfomation": "会社の情報",
  "txtServiceDetail": "サービスの詳細",
  "txtServiceEmailIsRequired": "サポートメールが必要です",
  "txtServiceListInvalidPage": "無効なページング値",
  "txtServiceLogo": "サービスロゴ",
  "txtServiceLogoDesc": "今すぐサービス用のロゴをアップロードするか、後で設定で更新できます。",
  "txtServiceName": "サービス名",
  "txtServiceNameIsRequired": "サービス名が必要です",
  "txtServiceNameOrClientId": "サービス名またはクライアントID",
  "txtServiceNameOrClientIdPlaceholder": "サービス名またはクライアントIDを入力します",
  "txtServiceNameTooltip": "サービスの名前を選択してください（最大64文字）",
  "txtServicePortalIsRequired": "サポートポータルが必要です",
  "txtServiceTags": "サービスタグ",
  "txtServiceTagsDes": "このサービスのタグを追加または削除します",
  "txtServices": "サービス",
  "txtServicesUsage": "最近のサービスの使用",
  "txtServicesUsageDesc": "請求書を送るプロセスは、支払うべきお金を払うように頼むプロセス",
  "txtSettings": "設定",
  "txtShareableDesc": "このページを使用すると、申請者はKYC情報をダッシュ​​ボードに送信できます。リンクを電子メールまたは共有したい場所にコピーして貼り付けます。",
  "txtShareableLink": "共有可能なリンク",
  "txtShareableLinkMustBeSetIfUsingRestrictedShareableMethod": "共有可能なリンクがありません",
  "txtShortDescription": "簡単な説明",
  "txtShortDescriptionTooltip": "あなたのサービスを説明する短いテキスト（フォーマットされていないテキストのみ）",
  "txtShowLess": "少なく表示します",
  "txtShowMore": "もっと見せる",
  "txtShowRefId": "refidを表示します",
  "txtShowSession": "セッションを表示します",
  "txtShowThisService": "このサービスを表示します",
  "txtShowThisServiceDes": "このサービスをオンまたはオフにして、市場に表示します。",
  "txtSolanaAddress": "Solana（Sol）アドレス",
  "txtStartDate": "開始日",
  "txtStartingAt": "から始めます",
  "txtStartingAtTooltip": "追加情報は利用できません。",
  "txtStatus": "状態",
  "txtStringMaxLengthError": "<0>または少ない文字でなければなりません",
  "txtStripeError": "請求情報を確認すると何か問題が発生しました。サポートについてはお問い合わせください。",
  "txtSubscribe": "購読する",
  "txtSubscribeTo": "ニュースレターを購読する",
  "txtSubscribed": "購読",
  "txtSubscription": "サブスクリプション",
  "txtSupport": "サポート",
  "txtSupportPortal": "サポートWebポータルのURL",
  "txtSupportPortalTooltip": "カスタマーサービスポータルWebサイトのURL",
  "txtSupportrEmail": "あなたのサポートメール",
  "txtSupportrEmailTooltip": "カスタマーサポートのメールアドレス。このアドレスは、サポートポータルURLを提供しない場合に使用されます。デフォルトアドレスはsupport@blockpass.orgです",
  "txtTags": "タグ",
  "txtTaxNumberWrongFormat": "無効な形式。",
  "txtTeam": "チーム",
  "txtTestService": "テストサービス",
  "txtTheRequestWasMadeButNoResponseWasReceived": "ネットワークエラー",
  "txtThisFieldIsRequired": "この項目は必須です。",
  "txtTitleAllow": "許可された国",
  "txtTitleExclude": "除外された国",
  "txtToGetStart": "開始するには、ログインしてください。",
  "txtToday": "今日",
  "txtTools": "ツール",
  "txtTooltipAddressCountryRestriction": "国の制限が有効になっている場合は、住所が必要です",
  "txtTooltipAddressProofOfAddress": "アドレスの証明が選択されている場合はアドレスが必要です",
  "txtTotal": "合計",
  "txtTotalForMonth": "月の合計",
  "txtTypeSomthing": "何かを入力...",
  "txtUSDperCheck": "USD/チェック",
  "txtUnAuthorized": "セッションの有効期限が切れているか無効です。もう一度ログインしてください",
  "txtUnauthorized": "セッションの有効期限が切れているか無効です。もう一度ログインしてください",
  "txtUnlimittedCollaborator": "無制限の協力者",
  "txtUnsubscribe": "登録解除",
  "txtUpdate": "アップデート",
  "txtUpdateAPIKey": "APIキーを更新します",
  "txtUpdateApiKey": "APIキーを更新します",
  "txtUpdateApiKeySuccess": "APIキーが更新されました",
  "txtUpdateBillingPlanDisplay": "請求計画の表示を更新します",
  "txtUpdateBillingPlanKycBot": "KYCボット構成を更新します",
  "txtUpdateBillingPlanVerification": "KYCチェック構成を更新します",
  "txtUpdateCertificate": "更新された証明書",
  "txtUpdateCheckConfig": "Check Configを更新します",
  "txtUpdateCompany": "更新会社",
  "txtUpdateCompanyInfoDesc": "会社の情報を更新します。",
  "txtUpdateFlowConfig": "フロー構成を更新します",
  "txtUpdateIdentityField": "IDのフィールドを更新しました",
  "txtUpdateInfo": "情報を更新します",
  "txtUpdateKYCSettings": "KYC設定を更新しました",
  "txtUpdatePlanSuccess": "サービス請求計画を正常に更新します",
  "txtUpdateRuntimeCfg": "ランタイム構成を更新しました",
  "txtUpdateService": "更新されたサービス",
  "txtUpdateServiceSuccess": "すべての変更が保存されました",
  "txtUpdateWebhook": "WebHookを更新します",
  "txtUploadALogo": "ロゴをアップロードします",
  "txtUrlPrivacyPolicy": "プライバシーポリシーのURL",
  "txtUrlPrivacyPolicyTooltip": "ユーザー登録前にプライバシーポリシーへのリンクが表示されます",
  "txtUrlTermsAndConditions": "利用規約のURL",
  "txtUrlTermsAndConditionsTooltip": "利用規約へのリンクは、ユーザー登録前に表示されます",
  "txtUsage": "使用法",
  "txtUsageMetrics": "使用法",
  "txtUserDebugger": "ユーザーデバッガー",
  "txtUserDebuggerDesc": "電子メールまたはSupportIDでユーザーを検索します",
  "txtUserId": "ユーザーID",
  "txtUserSessionExpired": "ユーザーセッションの有効期限が切れました",
  "txtUserSessionExpiredDesc": "あなたはログアウトされています。もう一度ログインしてください",
  "txtUsername": "ユーザー名",
  "txtUsernameOrEmail": "ユーザー名または電子メール",
  "txtUsernameOrEmailPlaceholder": "ユーザー名または電子メールを入力します",
  "txtVATNumber": "VAT番号",
  "txtVerification": "検証",
  "txtVerificationGroup": "1.検証",
  "txtVerifications": "検証",
  "txtViewDetails": "詳細を見る",
  "txtVisitThePage": "ページにアクセスしてください",
  "txtWEEK": "週",
  "txtWarningApprovalMode": "次のステップに移動する前に承認モードを確認してください",
  "txtWavesAddress": "波のアドレス",
  "txtWebhooks": "webhooks",
  "txtWebsite": "あなたのウェブサイトのURL",
  "txtWebsiteIntegration": "ウィジェット統合",
  "txtWebsiteTooltip": "公式ウェブサイトへのリンク",
  "txtWebsiteUrlIsRequired": "このフィールドを記入してください",
  "txtWelcomeBody": "サービスを作成、構成、監視して、顧客のIDを確認します。",
  "txtWelcomeTitle": "ブロックパス管理コンソールへようこそ",
  "txtWelcomeTo": "ようこそ",
  "txtWhoCanResgister": "誰が登録できますか？",
  "txtWillBeDeleted": "削除されます。",
  "txtYEAR": "年",
  "txtYesterday": "昨日",
  "txtYourApiKey": "APIキー",
  "txtYourCard": "あなたのカード",
  "txtYourCompanyInfo": "あなたの会社情報",
  "txtYourEmailAddress": "あなたのメールアドレス",
  "txtYourLink": "あなたのリンク",
  "txtYourLinkCopiedToClipboard": "リンクはクリップボードにコピーされました",
  "txtYourPaymentMethod": "あなたの支払い方法",
  "txtYourPaymentMethodGroup": "4.支払い方法",
  "txtYourServiceConfigCopiedToClipboard": "サービス構成はクリップボードにコピーされました",
  "txtYourServiceCreated": "あなたのサービスが作成されました",
  "txtYourServiceLink": "あなたのサービスリンク",
  "txtYourWebsite": "https://your-website.com",
  "txtYourWebsiteUrl": "あなたのウェブサイトURL",
  "txtYourWebsiteUrlDesc": "ウィジェットをインストールしたウェブサイトURLを入力してください",
  "txtdelete": "消去",
  "txtdonwloadMonitorReport": "Donwload Monitorレポート",
  "txtsettingUpdated": "更新された設定"
} // eslint-disable-line no-template-curly-in-string