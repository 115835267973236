import { observable, makeObservable } from "mobx";
import BaseDomainStore from "../base/BaseDomainStore";
import RootStore from '../../stores/RootStore'
import DashboardObj from './DashboardObj'
import { NOTI_AUTO_HIDE_TIME } from "@workspace/shared/constants/constants";
// import registry from '@/data/registry';
import devRegistry from '@/data/_dev_registry';
import prodRegistry from '@/data/_prod_registry';
import Fuse from 'fuse.js'
import countries from "../../constants/countries.json";
import get from 'lodash/get'
const { ContainerStore } = require('@blockpass-org/micro-bridge')

// import { filterXSS } from 'xss'
// import AuthStore from "../cores/AuthStore";

// const optionFilterXss = {
//   whiteList: {},
//   stripIgnoreTag: true,
//   stripIgnoreTagBody: ['script'],
// }
const BASE_URL = 'https://identity.blockpass.org/'
const API_SERVICE_DETAIL = 'web-kyc/v1/meta/services'
class DashboardStore extends BaseDomainStore {
  _rootStore: RootStore
  embedded = false
  hideLayout = false
  registry = localStorage.getItem('DEV_REGISTRY_DATA') === '1' ? devRegistry : prodRegistry 
  filterRegistry = localStorage.getItem('DEV_REGISTRY_DATA') === '1' ? devRegistry : prodRegistry 
  selectedCompanySlug = ''
  containerStore = ContainerStore
  searchInput = ""
  embeddedParam = ''
  fuse = undefined

  //
  ignoreGetParam = false
  constructor(rootStore: any) {
    super(DashboardObj);

    makeObservable(this, {
      embedded: observable,
      hideLayout: observable,
      registry: observable,
      selectedCompanySlug: observable,
      containerStore: observable,
      searchInput: observable,
      filterRegistry: observable,
      ignoreGetParam: observable,
    });

    this._rootStore = rootStore;
    this.addJurisdiction()
    this.fuse = new Fuse(this.registry, {
      // includeScore: true,
      // minMatchCharLength: 1,
      // findAllMatches: true,
      keys: [
      'kybInfo.businessDetails.name', 
      'kybInfo.businessDetails.brandName', 
      'kybInfo.businessDetails.country',
      'web.url',
      'kybInfo.businessDetails.jurisdiction',
      'socialNetworks.account', 
    ]
    
    })
  }

  getCountryByCode3 =(code3) => {
    if (code3) {
      const country = countries.find((c) => {
        return c.code3.toLowerCase() === code3.toLowerCase();
      });
      if (country) return country;
    }
    return "Invalid country";
  };

  addJurisdiction = () => {
    const newList = this.registry.map((r)=>{
      const country = this.getCountryByCode3(get(r, 'kybInfo.businessDetails.jurisdiction', ''))
      r.kybInfo.businessDetails['country'] = get(country, 'name', '')
      return r
    })
    this.registry = newList
    this.filterRegistry = newList
  }

  setEmbedMode = (b) => {
    if(b){
      this.embedded = true
      this.embeddedParam = '?embedded=1'
    } else {
      this.embedded = false
      this.embeddedParam = ''
    }
  }
 
  getErrorMessage = (error: any) => {
    let msg = "txtUnauthorized";
    if (error.code === 401) {
      // AuthStore.setAuthenticated(false)
      error.message = "txtUnauthorized";
    } else if (error.code === 400) {
      msg = error.message;
    } else {
      msg = error.message;
    }
    return msg;
  };

  
  showNotificationWithString = (notiString) => {
    const {
      UINotificationStore,
    } = this._rootStore;
    UINotificationStore.showSnackbar({
      message: notiString,
      options: {
        variant: 'notification',
        actionLabel: '',
        // actionCallback: null,
        dismiss: true,
        preventDuplicate: false,
        autoHideDuration: NOTI_AUTO_HIDE_TIME,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
      },
    })

  }

  showNotificationWithError = (error) => {
    const {
      UINotificationStore,
      AuthStore,
      LocalizationStore: { translate },
    } = this._rootStore;
    if (error.code === 401) {
      AuthStore.setOpenSessionExpired(true)
    } else if (error.code === 500) {
      UINotificationStore.showSnackbar({
        message: 'An unexpected error happened on the server',
        options: {
          variant: 'notification',
          actionLabel: 'RELOAD',
          dismiss: true,
          preventDuplicate: false,
          autoHideDuration: NOTI_AUTO_HIDE_TIME,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          actionCallback: () => {
            window.location.reload();
          },
          persist: true
        },
      })
    } else {
      UINotificationStore.showSnackbar({
        message: translate(error.message),
        options: {
          variant: 'notification',
          actionLabel: '',
          // actionCallback: null,
          dismiss: true,
          preventDuplicate: false,
          autoHideDuration: NOTI_AUTO_HIDE_TIME,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
        },
      })
    }
  }
  

  getCompanyDetailData = (slug) =>{
    let result = {}
    this.registry.forEach((c) => {
      if(c.slug === slug){
        result = c
        return
      }
    })
    return result
  }

  getServiceDetail = async(clientId) => {
    
    try{
        const res = await this.containerStore.httpRequest({
          baseUrl: BASE_URL,
          endpoint: `${API_SERVICE_DETAIL}/${clientId}`,
  
          method: 'GET',
         
        })
        if(res.result === null){
       
          return true
        } else {
          
          return false
        }
        
    } catch (error) {
      this._rootStore.debugLog('error: ', error)   
      this.showNotificationWithError(error)
      return false
    }

  }
  // @ts-ignore
  setSearchInput = (value:string) => {
    if(value === ''){
      this.filterRegistry = this.registry
    } else {
       // @ts-ignore
      this.searchInput = value
      
      const result = this.fuse.search(value)
      let filterData = []
      Object.keys(result).forEach((r) => {
        filterData.push(result[r].item)
      });
      // @ts-ignore
      this.filterRegistry = filterData
    }
   

    
  }


  // pushDataLayer = (data: any) => {
  //   const {
  //     AuthStore: { containerStore },
  //   } = this._rootStore;
  //   if(containerStore?.pushDataLayer){
  //     containerStore?.pushDataLayer(data)
  //   }
   
  // }
} 

export default DashboardStore;
