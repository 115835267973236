import { observable, reaction, makeObservable } from 'mobx'
import queryString from 'query-string'

import { EventEmitter } from 'events'
import { EV_BOOT_COMPLETED } from '@constants/events'

// Domain Store
import DashboardStore from './domains/DashboardStore'

// Base Store
import BaseStore from './base/BaseStore'
import AuthStore from './cores/AuthStore'
import LocalizationStore from './cores/LocalizationStore'
// import NetworkStore from './cores/NetworkStore'
import RouteStore from './cores/RouteStore'

// UI Store
import UILayoutStore from './ui/UILayoutStore'
// import UISnackbarStore from './ui/UISnackbarStore'

// Notification store 
import UINotificationStore from './ui/UINotificationStore'
import { NOTI_AUTO_HIDE_TIME } from "@workspace/shared/constants/constants";
class RootStore extends BaseStore {
  AuthStore: AuthStore
  LocalizationStore: LocalizationStore
  // NetworkStore: NetworkStore
  RouteStore: RouteStore
  DashboardStore: DashboardStore
  // UISnackbarStore: UISnackbarStore
  UILayoutStore: UILayoutStore
  UINotificationStore : UINotificationStore

  baseUrl: string
  event: EventEmitter
  isBootCompleted: boolean
  isCookieEnable: boolean
  isIframe: boolean
  isLocalStorageAvailable: boolean
  isSplashAnimCompleted: boolean
  urlParams: any
  viewStores: { [k: string]: BaseStore }

  constructor(options = {}) {
    super()

    this._initBridge()
    this._init(options)

    makeObservable(this, {
      isBootCompleted: observable,
      isSplashAnimCompleted: observable,
      isIframe: observable,
      viewStores: observable,
    })
  }

  _init(options: any) {
    this.baseUrl = options.baseUrl || ''
    this.viewStores = {}
    this.event = new EventEmitter()
    this.isBootCompleted = false
    this.isSplashAnimCompleted = false
    this.isLocalStorageAvailable = this._storageAvailable('localStorage')
    this.isCookieEnable = this._cookieAvailable()
    this.isIframe = true
    this.urlParams = {}


    this._initExtStores()
    this._initDomainStore()
    this._initCoreStores()
  
  }

  _initBridge() {
    // Message from IFrame host
    window.addEventListener('message', (event) => {})
  }

  _initCoreStores() {
    this.LocalizationStore = new LocalizationStore(this)
    //  offline
    this.AuthStore = new AuthStore(this)
    this.RouteStore = new RouteStore(this)
  }

  _initDomainStore() {
    this.DashboardStore = new DashboardStore(this)
  }

  _initExtStores() {
    // this.UISnackbarStore = new UISnackbarStore(this)
    this.UILayoutStore = new UILayoutStore(this)

    this.UINotificationStore = new UINotificationStore(this)
  }

  _parseArgs() {
    try {
      let bp_wid, acctype
      const params = queryString.parse(window.location.search)
      bp_wid = params.bp_wid
      acctype = params.acctype
      this.urlParams = {
        bp_wid,
        acctype,
      }
    } catch (error) {
      console.error('[RootStore] _parseArgs', error)
    }
  }

  _cookieAvailable() {
    return window && window.navigator && window.navigator.cookieEnabled
  }

  _storageAvailable(type) {
    var storage
    try {
      storage = window[type]
      var x = '__storage_test__'
      storage.setItem(x, x)
      storage.removeItem(x)
      return true
    } catch (e) {
      console.error('Storage ' + storage + ' error: ', e)
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage &&
        storage.length !== 0
      )
    }
  }

  async start() {
    this.isBootCompleted = false

    this.debugLog('boot start')
    // this._parseArgs()

    await this.AuthStore.start()
    await this.LocalizationStore.start()
    // await this.NetworkStore.start()

    // await this.UISnackbarStore.start()
    await this.UILayoutStore.start()
    await this.UINotificationStore.start()

    this.emit(EV_BOOT_COMPLETED, {})

    this.debugLog('boot end')

    this.isBootCompleted = true
  }

  async stop() {
    this.AuthStore.stop()
    this.LocalizationStore.stop()
    // this.NetworkStore.stop()

    // this.UISnackbarStore.stop()
    this.UILayoutStore.stop()
    this.UINotificationStore.stop()
    this.event.removeAllListeners()
  }

  sendIFrameMessage = (msg) => {
    this.debugLog('Iframe:', msg)
    window.parent && window.parent.postMessage(msg, '*')
  }

  onSwUpdate = (registration) => {
    registration.waiting &&
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    reaction(
      () => {
        return this.isBootCompleted && this.isSplashAnimCompleted
      },
      (con, _prevCon, react) => {
        if (con) {
          this.UINotificationStore.showSnackbar({
            message: 'New version available',
            options: {
              variant: 'notification',
              actionLabel: 'RELOAD',
              dismiss: true,
              preventDuplicate: false,
              autoHideDuration: NOTI_AUTO_HIDE_TIME,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              actionCallback: () => {
                registration.unregister().then(() => {
                  window.location.reload();
                });
              },
              persist: true,
            },
          })
          react.dispose()
        }
      },
      { fireImmediately: true }
    )
  }

  // -----------------------------------------------------//
  //  Splash Animation
  // -----------------------------------------------------//

  onSplashAnimCompleted() {
    this.isSplashAnimCompleted = true
  }

  // -----------------------------------------------------//
  //  Events
  // -----------------------------------------------------//
  emit(eventName: string, params?: any) {
    this.debugLog('emit', eventName)
    this.event.emit(eventName, params)
  }

  on(eventName: string, func: (...args: any[]) => void) {
    this.debugLog('on', eventName)
    this.event.on(eventName, func)
  }

  off(eventName: string, func: (...args: any[]) => void) {
    this.event.removeListener(eventName, func)
  }

  once(eventName: string, func: (...args: any[]) => void) {
    this.event.once(eventName, func)
  }

  loadViewStore(name: string, ins: BaseStore) {
    this.viewStores[name] = ins
  }

  unloadViewStore(name: string) {
    delete this.viewStores[name]
  }
}

export default RootStore
