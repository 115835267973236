/* eslint-disable */
// prettier-ignore
export default {
  "txPersonalAttributeDesc": "Вы можете запросить дополнительную информацию у ваших пользователей во время адаптации. Эти атрибуты не подтверждаются BlockPass.",
  "txt404": "Страница, которую вы ищете, не может быть найдена!",
  "txtAML": "Бомбардировка",
  "txtAMLCheck": "AML проверка",
  "txtAMLDesc1": "Скринирует на глобальную базу данных списков PEP/санкций и неблагоприятных СМИ, и определите, являются ли ваши клиенты политически разоблачены, санкционированы или имеют неблагоприятный профиль СМИ.",
  "txtAMLDesc2": "Этот чек требует, чтобы пользователи предоставили свое полное имя и дату рождения.",
  "txtAPIAndWebhooks": "API и Webhooks",
  "txtAccountDeletion": "Удаление учетной записи",
  "txtAccountDeletionDesc": "Удалить мобильные и веб -учетные записи",
  "txtAction": "Действие",
  "txtActive": "Активный",
  "txtActivityLogs": "Журналы деятельности",
  "txtAdd": "Добавлять",
  "txtAddCompany": "Добавить компанию",
  "txtAddNewCompany": "Добавить новую компанию",
  "txtAddNewsContent": "Добавить новости",
  "txtAddNotification": "Добавить уведомление",
  "txtAddPaymentMethod": "Добавить способ оплаты",
  "txtAddPaymentMethodFail": "Что -то не так. Пожалуйста, добавьте способ оплаты позже.",
  "txtAddPaymentMethodSuccessfully": "Ваша платежная карта успешно добавлена",
  "txtAddRichContent": "Добавить богатый контент",
  "txtAddTag": "Добавить метку",
  "txtAddTagPlaceholder": "Введите этикетку тега ...",
  "txtAddTagSuccess": "«<0>» метка добавлен успешно.",
  "txtAddingTag": "Добавление тега",
  "txtAdmin": "Администратор",
  "txtAdminConsole": "Административная консоль",
  "txtAdminSettingsUpdateSuccess": "Настройки администратора успешно обновлены.",
  "txtAdministration": "Администрация",
  "txtAdvancedApprovalDesc": "Advanced Kyc Bot:",
  "txtAdvancedApprovalDesc1": "Набор из 10+ правил для автоматического утверждения или отклонения пользователей.",
  "txtAdvancedApprovalTooltip": "Advanced Kyc Bot не доступен для этого плана. Свяжитесь с нами, если хотите включить это.",
  "txtAfterReturn": "После того, как API вернул данные",
  "txtAgeRestriction": "Возрастное ограничение",
  "txtAgeRestrictionDesc1": "Вы можете исключить кандидатов в зависимости от их возраста.",
  "txtAgeRestrictionDesc2": "Заявитель должен быть хотя бы",
  "txtAgeRestrictionDesc3": "лет.",
  "txtAllPlanFeatureHeader": "Все планы включают следующие функции",
  "txtAllTimeAreUTC": "(Все время UTC)",
  "txtAllowed": "Допустимый",
  "txtAllowedCountries": "разрешены страны",
  "txtAnnouncements": "Объявления",
  "txtAnyoneWithTheLink": "Любой со ссылкой",
  "txtAnyoneWithTheLinkDesc1": "Любой, у кого есть эта ссылка, может зарегистрироваться в вашем сервисе.",
  "txtAnyoneWithTheLinkDesc2": "Эта страница позволяет кандидатам отправлять информацию о KYC на вашу панель панели. Скопируйте и вставьте ссылку в электронное письмо или любое место, где вы хотите поделиться ею.",
  "txtApiKey": "API -ключ",
  "txtApiKeyCopied": "Ваш ключ API скопирован",
  "txtApiKeyDeleteDesc": "После удаления ключ API не может быть восстановлен. Если вы не совсем уверены, что ключ может быть удален, вы можете установить статус неактивным.",
  "txtApiKeys": "Ключи API",
  "txtApply": " применять.",
  "txtApproveAdvanced": "Advanced Kyc Bot",
  "txtApproveBasic": "Kyc Bot",
  "txtApproveDesc1": "Вы можете сэкономить время, выбрав режим обзора.\n",
  "txtApproveDesc2": "KYC -боты рекомендуются для большого объема пользователей. Видеть",
  "txtApproveDesc3": "больше деталей",
  "txtApproveDesc4": " в документации",
  "txtApproveFreeManual": "Руководство: Одобрить или отвергать каждого пользователя вручную. Выберите план, чтобы ускорить процесс адаптации.",
  "txtApproveNone": "Ручной обзор",
  "txtAssignCompanyToService": "Добавить информацию о компании",
  "txtAssignRole": "Назначенная роль",
  "txtAssignUserRole": "Назначенная роль",
  "txtAttributeDataDesc": "Как только профиль архивируется, остаются только метаданные, атрибуты идентичности удаляются.",
  "txtAttributesConfig": "Атрибуты конфигурация",
  "txtAttributesConfigDesc": "Настройте атрибуты, доступные в профилях пользователей",
  "txtAttributesGroupConfig": "Атрибуты группы конфигурации",
  "txtAttributesGroupConfigDesc": "Настройка группы атрибутов, доступных в профилях пользователей",
  "txtAutoApproval": "Автоматическое одобрение",
  "txtAutoArchive": "Автоархив профиль пользователя",
  "txtAutoArchiveTooltip": "Автоархив профиль пользователя @@ ar_ch_ti_me @@ sechings после того, как API вернул данные",
  "txtAutoTranslate": "(автоматическая трансляция)",
  "txtAutomationRules": "Правила автоматизации",
  "txtAvatar": "Аватар",
  "txtAvaxAddress": "Адрес",
  "txtBack": "Назад",
  "txtBasicApprovalDesc": "KYC BOT:",
  "txtBasicApprovalDesc1": "Автоматическое утверждение пользователей с четкими проверками.",
  "txtBasicInfo": "Базовая информация",
  "txtBestPractices": "лучшие практики",
  "txtBilling": "Биллинг",
  "txtBillingInfo": "Платежная информация",
  "txtBillingInfomation": "Платежная информация",
  "txtBillingOwner": "Владелец биллинга",
  "txtBinanceAddress": "Адрес Binance (BSC)",
  "txtBitcoinAddress": "Биткойн Адрес",
  "txtBlockpass": "Blockpass",
  "txtBlockpassKycRules": "BlockPass Rules",
  "txtBpKYCRules": "BlockPass Rules",
  "txtBrowse": "Просматривать",
  "txtBtcAdress": "Биткойн Адрес",
  "txtBusinessInfomation": "Деловая информация",
  "txtBusinessTerms": "Деловые термины",
  "txtCanNotSetTagsOnChainKYCForMultipleCryptoService": "Не может установить теги OnChainKyc для служб.",
  "txtCancel": "Отмена",
  "txtCardHolderName": "Имя владельца карты",
  "txtCardInfomation": "Карточная информация",
  "txtCardanoAddress": "Адрес кардано (ADA)",
  "txtCaution": "Осторожность !",
  "txtCautionDesc1": "Если вы в настоящее время принимаете участие в пользователях, не рекомендуется изменять план. Пожалуйста свяжитесь с нами для помощи.",
  "txtCautionDesc2": "В противном случае вы можете продолжить конфигурацию.",
  "txtCertificatesOrderConfig": "Сертификаты Заказ конфигурация",
  "txtCertificatesOrderConfigDesc": "Настроить заказ сертификатов в профилях пользователей",
  "txtChangeBillingPlan": "Изменить план выставления счетов",
  "txtChangePlan": "Изменить план",
  "txtChangeWebhook": "Обновлен Webhook",
  "txtChartEmptyTooltip": "Эта диаграмма будет заполняться, когда ваши услуги будут использоваться пользователями.",
  "txtCheckDebugger": "Проверьте отладчик",
  "txtCheckDebuggerDesc": "Поиск проверяет идентификатор проверки. Чек обычно выпускает сертификат",
  "txtChecksConfig": "Проверяет конфигурацию",
  "txtChecksConfigDesc": "Настройка атрибутов и сертификат определяет проверку",
  "txtChinese": "Китайский",
  "txtClickOrDragToUpload": "Нажмите или перетащите, чтобы загрузить свой логотип",
  "txtClientId": "ID клиента",
  "txtClientIdExisted": "Идентификатор клиента уже существует, пожалуйста, используйте другой идентификатор клиента",
  "txtClientIdTooltip": "Уникальный идентификатор вашего сервиса.\nЭто автоматически генерируется на основе\nНа названии вашего сервиса",
  "txtClose": "Закрывать",
  "txtCodeHasBeenCopied": "Код был скопирован",
  "txtCodeSample": "Пример кода",
  "txtCompany": "Компания",
  "txtCompanyAddress": "Адрес компании",
  "txtCompanyAddressCity": "Город",
  "txtCompanyAddressCountry": "Страна",
  "txtCompanyAddressLine1": "Адресная строка 1",
  "txtCompanyAddressLine1IsRequired": "Требуется адресная строка 1 Компании.",
  "txtCompanyAddressLine2": "Адресная строка 2 (необязательно)",
  "txtCompanyAddressPostalCode": "Почтовый индекс",
  "txtCompanyAddressPostalCodeRequired": "Требуется почтовый код.",
  "txtCompanyAddressState": "Состояние",
  "txtCompanyCity": "Город",
  "txtCompanyCountryIsRequired": "Страна требуется",
  "txtCompanyCustomRequest": "Сообщение",
  "txtCompanyEmailIsRequired": "Требуется электронная почта компании.",
  "txtCompanyGroup": "3. Компания",
  "txtCompanyInfo": "информация о компании",
  "txtCompanyInfomation": "Информация о компании",
  "txtCompanyName": "Название компании",
  "txtCompanyNameIsRequired": "Название компании требуется",
  "txtCompanyNameRepIsRequired": "Название представителя компании требуется.",
  "txtCompanyNumber": "Номер компании",
  "txtCompanyNumberIsRequired": "Требуется номер компании",
  "txtCompanyPhoneRepIsRequired": "Требуется телефон представителя компании.",
  "txtCompanyPostalCode": "Почтовый индекс",
  "txtCompanyRepresentative": "Название представителя компании",
  "txtCompanyState": "Состояние",
  "txtCompanyVAT": "Налог или номер НДС",
  "txtCompanyVATNumber": "Налог или номер НДС",
  "txtCompanyVATNumberIsRequired": "Требуется номер НДС компании",
  "txtConfiguration": "Конфигурация",
  "txtConfirm": "Подтверждать",
  "txtConfirmDeleteCompanyInfo": "Если вы вернетесь к предыдущему шагу, любая введенная информация компании будет стерта.",
  "txtConnectWithUs": "связаться с нами",
  "txtContactSale": "Свяжитесь с продажами",
  "txtContactSaleTeam": "Свяжитесь с нашей командой по продажам",
  "txtContactSupport": "Свяжитесь с продажами",
  "txtContent": "Содержание",
  "txtContinue": "Продолжать",
  "txtContinueToConfigureService": "Продолжайте настроить свою службу",
  "txtCopied": "Скопировано!",
  "txtCopy": "Копия",
  "txtCopyAndClose": "Копировать и закрыть",
  "txtCopyApiKeyWarning": "Вы не сможете получить его, как только закрываете всплывающее окно. Храните это где -нибудь в безопасности.\nЕсли потеряно, вам придется генерировать новый ключ.",
  "txtCopyLink": "Копировать ссылку",
  "txtCopyright": "© BlockPass",
  "txtCount": "Считать",
  "txtCountryRestriction": "Ограничения страны",
  "txtCountryRestrictionDesc1": "Вы можете исключить жителей из некоторых стран или принять только жителей других. Этот вариант требует, чтобы пользователи предоставили свой жилой адрес.",
  "txtCountryRestrictionDesc2": "Страна, предоставленная здесь, является только примером. Вы должны проверить с вашим сотрудником по соблюдению в отношении стран, чтобы ограничить.",
  "txtCountryRestrictionDescAllow": "Разрешить только жители из",
  "txtCountryRestrictionDescExclude": "Исключить жителей из",
  "txtCoutryOfIncorporation": "Страна включения",
  "txtCreate": "Создавать",
  "txtCreateAPIKey": "Создайте ключ API",
  "txtCreateAPIKeyFooter1": "Публично разоблачение ключа API может привести к скомпрометированию личных данных ваших пользователей.",
  "txtCreateAPIKeyFooter2": "Вы должны следовать",
  "txtCreateAPIKeyFooter3": " Для надежного использования клавиш API.",
  "txtCreateAccount": "Создал новую учетную запись",
  "txtCreateApiKeySuccess": "Новый ключ API создан",
  "txtCreateBillingPlan": "Создать план биллинга",
  "txtCreateCertificate": "Создал новый сертификат",
  "txtCreateCheckConfig": "Создать конфигурацию проверки",
  "txtCreateCompany": "Создать компанию",
  "txtCreateFlowConfig": "Создать конфигурацию потока",
  "txtCreateIdentityField": "Создано поле идентификации",
  "txtCreateKYCSettings": "Созданы настройки KYC",
  "txtCreateNewTag": "Создайте тег",
  "txtCreateRuntimeCfg": "Создана конфигурация времени выполнения",
  "txtCreateService": "Создал новый сервис",
  "txtCreateServiceDashboard": "Создать сервис",
  "txtCreateServiceSuccess": "Новая служба успешно создана",
  "txtCreateVersion": "Создал новую версию API",
  "txtCreateWebhook": "Создал WebHook",
  "txtCryptoAddresses": "Крипто -адреса",
  "txtCurrentPlan": "Текущий план",
  "txtCustomRegistrationUrl": "Пользовательский регистрационный URL",
  "txtCustomUrlInvalid": "Этот URL неверен.",
  "txtCustomiseAppLink": "Ссылка на приложение для мобильных",
  "txtDAY": "День",
  "txtDOB": "Дата рождения",
  "txtDashboard": "Панель приборов",
  "txtDataExport": "Экспорт данных",
  "txtDate": "Дата",
  "txtDelete": "Удалить",
  "txtDeleteAPIKey": "Удалить ключ API",
  "txtDeleteApiKeySuccess": "Ключ API удален",
  "txtDeleteBulkIdentityRecordConfirmMessage": "Вы уверены, что хотите удалить эти учетные записи?",
  "txtDeleteThisApiKey": "Удалить этот ключ API",
  "txtDeleteVersion": "Удаленная версия API",
  "txtDeleteWebhook": "Удален Webhook",
  "txtDescription": "Описание",
  "txtDescriptionIsRequired": "Пожалуйста, введите описание для этого ключа API",
  "txtDetachPaymentMethod": "Снимите способ оплаты",
  "txtDetails": "Подробности",
  "txtDiscard": "Отказаться",
  "txtDiscardRichContent": "Отбросить богатый контент",
  "txtDisplay": "Отображать",
  "txtDoNotSendAddMember": "У вас нет разрешения пригласить нового участника. Пожалуйста, свяжитесь с владельцем услуги. Код ошибки 403.",
  "txtDocs": "Док",
  "txtDocumentation": "Документация",
  "txtDonNotHaveService": "У вас нет услуг в этой компании",
  "txtDontHavePaymentMethod": "У вас нет никакого способа оплаты, пожалуйста, добавьте метод оплаты ниже",
  "txtDownload": "Скачать",
  "txtDownloadLogs": "Скачать журналы",
  "txtDrivingLicense": "Водительские права",
  "txtDropFileHere": "Отбросьте свой логотип здесь",
  "txtEditClientId": "Редактировать идентификатор клиента",
  "txtEditClientIdWarning": "Этот идентификатор клиента не может быть изменен позже и должен быть уникальным.",
  "txtEditConfig": "Редактировать конфигурацию",
  "txtEditNewsContent": "Редактировать новости",
  "txtEditSearch": "Редактировать поиск",
  "txtEmail": "Электронная почта",
  "txtEmailAddress": "Адрес электронной почты",
  "txtEmailRepresentative": "Электронная почта представителя компании",
  "txtEmailWrongFormat": "Неправильный формат электронной почты",
  "txtEnable": "Давать возможность",
  "txtEnableApiKeys": "Включить клавиши API",
  "txtEnableApiKeysDes": "Позвольте клиентам использовать ключи API для доступа к данным пользователей.",
  "txtEnableEndUserRegistrations": "Включить регистрацию конечного пользователя",
  "txtEnableEndUserRegistrationsDes": "Позволяет конечным пользователям зарегистрироваться для проверки с помощью этой службы.",
  "txtEnableKycDashboard": "Включить мошную панель KYC",
  "txtEnableKycDashboardDes": "Позвольте клиентам получить доступ к приборной панели KYC.",
  "txtEnableService": "Включить обслуживание",
  "txtEnableServiceDes": "Этот вариант опасен; Включение и выключение часто может вызвать серьезные ошибки.",
  "txtEndDate": "Дата окончания",
  "txtEnglish": "Английский",
  "txtEnterpriseDesc1": "Минимум 100 профилей в месяц",
  "txtEnterpriseDesc2": " для получения дополнительной информации.",
  "txtEnterpriseSentDesc1": "Спасибо за выбор плана предприятия. Наша команда по продажам свяжется с вами как можно скорее и настроит сервис после вашего требования.\n",
  "txtEnterpriseSentDesc2": "В то же время, пожалуйста, добавьте метод оплаты и заполните информацию о выставлении счетов, необходимо включить ваш план",
  "txtErrorUnsupportFile": "Неподдерживаемый файл",
  "txtEthAddress": "Адрес Ethereum",
  "txtEthereumAddress": "Адрес Ethereum",
  "txtEvent": "Событие",
  "txtExample": "Пример",
  "txtExcept": "кроме",
  "txtExcluded": "Исключенный",
  "txtExcludedCountries": "исключенные страны",
  "txtExpiryDate": "Дата истечения срока действия",
  "txtExploreKyc": "Исследуйте панель панели управления KYC Connect.",
  "txtExportServiceConfig": "Конфигурация экспорта службы",
  "txtFaceMatch": "Матч лица",
  "txtFaceMatchCheck": "Проверка матча лица",
  "txtFaceMatchDesc1": "BlockPass сравнивает изображение на идентификаторе пользователя с живым селфи, запрошенным во время регистрации.",
  "txtFaceMatchDesc2": "Эта проверка требует, чтобы пользователи предоставили свой идентификационный документ и живое селфи.",
  "txtFacematchCheck": "ЧЕК ФАССКОВ",
  "txtFailedToSendMemberInvitation": "Не удалось отправить приглашение участника",
  "txtFamilyName": "Фамилия",
  "txtFaq": "Часто задаваемые вопросы",
  "txtFileNotSupport": "Этот файл не поддерживается",
  "txtFillCompanyInfo": "Заполните информацию на основе существующей компании ниже или добавьте новую компанию.",
  "txtFlowAddress": "Адрес потока (потока)",
  "txtFlowDebugger": "Поток отладчик",
  "txtFlowDebuggerDesc": "Поиск потоков по идентификатору потока. Потоки могут включать несколько проверок",
  "txtFlowsConfig": "Потоки конфигурация",
  "txtFlowsConfigDesc": "Настройка проверки определения потока",
  "txtForbidden": "У вас нет разрешения на выполнение этого действия",
  "txtFreePlanDescription": "Вы получаете неверные личности. Ограничено 10 в день.",
  "txtFreeTrial": "Бесплатная пробная версия",
  "txtFrench": "Французский",
  "txtFunctionUnavailable": "Функция временно недоступна",
  "txtGeneralInfomation": "Общее представление",
  "txtGeoRestriction": "Географические ограничения",
  "txtGetServiceDisplayFailed": "Получить сервис дисплея.",
  "txtGetServiceListFailed": "Получить список услуг.",
  "txtGetServiceWebhookListFailed": "Получить сервисы Webhooks Fail.",
  "txtGivenName": "Собственное имя",
  "txtGoToDashboard": "ПЕРЕЙТИ К ПРИБОРНОЙ ПАНЕЛИ",
  "txtGoToServiceDashboard": "Перейти на панель сервисной панели",
  "txtGoToServiceList": "ПОЗЖЕ",
  "txtGotoDashboardDesc1": "Перейдите на приборную панель Kyc Connect для этой услуги.",
  "txtGotoDashboardDesc2": "Панель инструментов - это то, где ваша команда может просмотреть личности, загруженные на ваш сервис людьми.",
  "txtHelp": "Помощь",
  "txtIdEngine": "Идентификационный двигатель",
  "txtIdEngineDesc": "Тест идентификатор двигателя ocr инструмент",
  "txtIdentityDesc1": "BlockPass проверяет паспорта, национальные удостоверения личности и лицензии на вождение.",
  "txtIdentityDesc2": "Пользователям просят сфотографировать их документ.",
  "txtIdentityDesc3": "Этот вариант требует, чтобы пользователи предоставили свой идентификационный документ, полное имя и дату рождения.",
  "txtIdentityDocument": "Удостоверение личности",
  "txtIdentityDocumentCheck": "Проверка документа идентификации",
  "txtIdentityLink1": "См. Список поддерживаемых стран",
  "txtImageTooBig": "Изображение слишком большое",
  "txtImgDimensionBetween10242048": "Пожалуйста, загрузите изображение между 512x512 и 2048x2048 Pixel",
  "txtImgDimensionRule": "Png, JPEG. Мин 512x512 пиксель, макс 2048x2048 пиксель.",
  "txtImpactChanging": "Воздействие зарядка",
  "txtInactive": "Неактивный",
  "txtIncludeRawDataExceptPictureTooltip": "Возвращать метаданные и атрибуты идентичности, кроме картин",
  "txtIncludeRawDataIncludePictureTooltip": "Возвращать метаданные и атрибуты идентификации, включая картинки",
  "txtIncludeRawDataTooltip": "Возвращать метаданные и атрибуты идентичности, кроме картин",
  "txtIncluding": "включая",
  "txtIncorrectFormat": "Поле должно содержать только нормальные / верхние символы, числа и подчеркивание (_)",
  "txtInfomation": "Информация",
  "txtInputYourServiceName": "Введите имя вашего сервиса",
  "txtInternalServerError": "Неожиданная ошибка произошла на сервере",
  "txtInvalidEmailFormat": "Неверный формат электронной почты",
  "txtInvalidEmails": "Неверные электронные письма",
  "txtInvalidParams": "Запрос содержит неверные параметры, перепроверьте свой вход",
  "txtInvalidURL": "URL недействителен",
  "txtInvalidUrl": "Этот URL -адрес неверен",
  "txtInvitationRemoved": "Приглашение было удалено",
  "txtInvitationResent": "Приглашение возмущается",
  "txtInviteAlreadySent": "Пригласить уже отправлено",
  "txtInviteUser": "Приглашенный пользователь",
  "txtIsCopiedToClipboard": "скопирован в буфер обмена.",
  "txtIsSuccess": "Успех",
  "txtJapanese": "Японский",
  "txtJobId": "Идентификатор работы",
  "txtKYCBot": "Kyc Bot",
  "txtKYCDashboardAccess": "Доступ к приборной панели KYC",
  "txtKybDashboard": "KYB Dashboard",
  "txtKybService": "KYB Service",
  "txtKybServiceDesc": "KYB выступает за «Знай свой бизнес». Создайте услугу KYB для проверки личности предприятий.",
  "txtKybServiceName": "KYB Service Name",
  "txtKybServices": "KYB Services (WIP)",
  "txtKycDashboard": "KYC Dashboard",
  "txtKycService": "KYC Service",
  "txtKycServiceDesc": "KYC означает «Знай своего клиента». Создайте сервис KYC, чтобы проверить личность отдельных лиц.",
  "txtKycServiceName": "KYC Service название",
  "txtKycServices": "KYC Services",
  "txtLast180Days": "Последние 180 дней",
  "txtLast30Days": "Последние 30 дней",
  "txtLast7Days": "Последние 7 дней",
  "txtLast90Days": "Последние 90 дней",
  "txtLastMonth": "Прошлый месяц",
  "txtLastRunAt": "Последний пробег в",
  "txtLatestInvoice": "Последний счет",
  "txtLatestInvoiceDesc": "Счета каждый месяц отправляются по адресу электронной почты владельца биллинга. Вы также можете скачать последнюю выпущенную счету ниже.",
  "txtLearnMoreAboutApiKey": "Узнайте больше о BlockPass API",
  "txtLengthTooShort": "Длина поля слишком короткая",
  "txtLink": "Связь",
  "txtListDeleteEmail": "Список электронных писем, которые вы хотите удалить, разделенные запятыми, до 50 электронных писем",
  "txtListOfServices": "Список услуг",
  "txtListingRunningCheck": "Перечисление работы чека",
  "txtListingRunningCheckDesc": "Листинг бега чека",
  "txtLive": "Жить",
  "txtLockUser": "Заблокированный пользователь",
  "txtLogin": "Авторизоваться",
  "txtLogo": "Логотип",
  "txtLogoDesc": "Png, JPEG, JPG.\nМин 512x512 PX, максимум 2048x2048 px",
  "txtLogoRestriction": "Размер изображения должен быть между 1024 x 1024 и 2048 x 2048 пикселей",
  "txtLogoSizeError": "Логотип, который вы только что загрузили, не является правильным размером.",
  "txtLogout": "Выйти",
  "txtLogs": "Журналы",
  "txtLongDescription": "Длинное описание",
  "txtLongDescriptionTooltip": "Полное описание вашего сервиса (только неформатированный текст)",
  "txtMONTH": "Месяц",
  "txtMarkerPlace": "Рынок",
  "txtMarkerPlaceDesc": "Управление рыночными записями",
  "txtMarketPlace": "Рынок - WIP",
  "txtMarketplace": "Рынок",
  "txtMarketplaceSettingUpdateSuccess": "Настройки рынка успешно обновлены.",
  "txtMaticAddress": "Полигонный (матический) адрес",
  "txtMaxEmailsExceeded": "Максимум 50 электронных писем за удаление",
  "txtMetaDataOnlyTooltip": "Вернуть только метаданные (статус профиля, идентификаторы, ...)",
  "txtMore": "Более",
  "txtMoreDetails": "Больше деталей",
  "txtMoreInfomation": "Больше инфомации",
  "txtName": "Имя",
  "txtNameOfCompany": "Название компании",
  "txtNameOfCompanyIsRequired": "Название компании требуется",
  "txtNameOnCard": "Имя на карте",
  "txtNameOnCardIncomplete": "Название на карте неполное.",
  "txtNameRepresentative": "Название представителя компании",
  "txtNationalID": "Национальное удостоверение личности",
  "txtNationalId": "Национальный идентификатор",
  "txtNewCompany": "Новая компания",
  "txtNewsContent": "Новостной контент",
  "txtNext": "Следующий",
  "txtNoActivityLogs": "Никакие действия не соответствуют вашему поиску",
  "txtNoActivityLogsDesc": "Попробуйте быть менее конкретным или использовать разные ключевые слова.",
  "txtNoApiKeyDesc": "Ключ API необходим для извлечения личности отдельных лиц, включая метаданные, личную информацию и изображения.",
  "txtNoApiKeys": "Нет ключей API",
  "txtNoCompanyAdd": "Вы еще не добавили информацию о компании",
  "txtNoCompanyAddDesc": "Нажмите ниже, чтобы добавить первую информацию компании.",
  "txtNoCountryRestriction": "Нет страны. Используйте поиск, чтобы добавить страну в список",
  "txtNoFieldsAreUpdated": "Поля не обновляются",
  "txtNoInvoiceDesc1": "Счета каждый месяц отправляются по адресу электронной почты владельца биллинга.",
  "txtNoInvoiceDesc2": "Не выпущен счетом для скачивания сейчас.",
  "txtNoServiceMatch": "Никакие услуги не соответствуют вашему поиску",
  "txtNoServiceMatchDesc": "Попробуйте быть менее конкретным или использовать разные ключевые слова.",
  "txtNoSetupCost": "Нет стоимости настройки",
  "txtNoUsageDetail": "Нет данных для отображения",
  "txtNoUsageDetailDesc": "В настоящее время нет доступной статистики.",
  "txtNone": "Никто",
  "txtNoneApprovalDesc": "Руководство:",
  "txtNoneApprovalDesc1": "Одобрить или отвергать каждого пользователя вручную.",
  "txtNormalDesc1": "Максимум 100 профилей в месяц.",
  "txtNotStoreCard": "BlockPass не хранит данные вашей карты.",
  "txtNotifications": "Уведомления",
  "txtNotificationsDesc": "Отправить push -уведомления для мобильных пользователей",
  "txtOnboarding": "Настройка",
  "txtOnboardingGroup": "2. Встроенная",
  "txtOnboardingSetting": "Настройки на адаптирование",
  "txtOnly": " только",
  "txtOpenDashboard": "Открытая панель приборной панели",
  "txtOverview": "Обзор",
  "txtPOA": "Подтверждение адреса",
  "txtPage403Desc1": "У вас нет разрешения на\n получить доступ к этой услуге",
  "txtPage403Desc2": "Пожалуйста, вернитесь к услугам и найдите правильный сервис или свяжитесь с владельцем услуги.",
  "txtParametersValidationError": "Запрос содержит неверные параметры, перепроверьте свой вход",
  "txtPassport": "Заграничный пасспорт",
  "txtPassword": "Пароль",
  "txtPayAsYouGo": "Плати как сможешь",
  "txtPaymentMethod": "Способ оплаты",
  "txtPaymentMethodDeleted": "Метод оплаты не может быть извлечен или был удален.",
  "txtPermissionDenied": "У вас нет разрешения на выполнение этого действия",
  "txtPersonalAtt": "Личные качества",
  "txtPersonalAttributes": "Личные качества",
  "txtPhoneNumber": "Номер телефона",
  "txtPhoneNumberWrongFormat": "Номер телефона должен быть цифрами",
  "txtPhoneRepresentative": "Номер телефона представителя компании",
  "txtPickYourServiceName": "Выберите имя для вашего сервиса",
  "txtPlan": "План",
  "txtPlanConfig": "Планы конфигурация",
  "txtPlanConfigDesc": "Процесс отправки счетов людей с просьбой заплатить задолженность денег",
  "txtPlanDescription1": "Меньше, чем @@ num_ident @@ личности в день и за бизнес.\n",
  "txtPlanDescription2": "Время обработки более 24 часов. Стандартный",
  "txtPleaseCopyAPIKey": "Пожалуйста, скопируйте ключ API",
  "txtPleaseReplacePaymentMethod": "Пожалуйста, замените способ оплаты.",
  "txtPostalCodeWrongFormat": "Формат неправильный.",
  "txtPrivacy": "Конфиденциальность",
  "txtPrivacyPolicy": "Политика конфиденциальности",
  "txtProfile": "Профиль",
  "txtProofOfAddress": "Подтверждение адреса",
  "txtProvideNameOfService": "Предоставьте имя для вашего сервиса",
  "txtPublicLinkDesc": "Любой в Интернете со ссылкой может отправить свой профиль KYC.",
  "txtPublishService": "Опубликованная служба",
  "txtPushNotification": "Отправить уведомление",
  "txtRedirect": "Перенаправить",
  "txtRemove": "Удалять",
  "txtRemoveInvitation": "Удалить приглашение",
  "txtRemoveTagConfirmContent": "Вы уверены, что хотите удалить этот тег?",
  "txtRemoveTagConfirmTitle": "Удалить тег «<0>»",
  "txtRemoveTagSuccess": "«<0>» тег был удален.",
  "txtRemoveUser": "Удален пользователь",
  "txtReplace": "Заменять",
  "txtReplacePaymentMethod": "Заменить способ оплаты",
  "txtReplacePaymentMethodDesc1": "Новая карта будет использоваться для всех ваших услуг",
  "txtReplacePaymentMethodDesc2": "Обратите внимание, изменения метода оплаты повлияют на многие услуги, которые используют это\nспособ оплаты.",
  "txtReport": "Отчет",
  "txtRepresentative": "Представитель",
  "txtRequestAccess": "Запрос доступа",
  "txtRequestEnterprise": "Запрос на корпоративный план",
  "txtRequestEnterpriseSent": "Запрос на корпоративный план отправлено!",
  "txtRequired": "* необходимый",
  "txtRequiredOneIdentityDocument": "Пожалуйста, выберите хотя бы один идентификационный документ",
  "txtRequirementPlaceHolder": "Расскажите нам больше о ваших требованиях и графике",
  "txtResAddress": "Адрес жилья",
  "txtResidentialAddress": "Адрес жилья",
  "txtRestricted": "Ограниченный",
  "txtRestrictedDesc1": "Только люди, аутентифицированные на вашем сайте, могут зарегистрироваться.",
  "txtRestrictedDesc2": "Выберите эту опцию, если вы хотите отправить пользовательский рефекцию в виджет.",
  "txtRestrictedDesc3": "Пожалуйста, введите URL -адрес, где виджет установлен на вашем сайте.",
  "txtRestrictedDescLink": "Больше информации в документации.",
  "txtRestrictedLinkDesc1": "Пользователи заставляют войти на ваш сайт, прежде чем они смогут отправить свой профиль KYC.",
  "txtRestrictedLinkDesc2": "Выберите эту опцию, если вы хотите отправить пользовательский рефекцию в виджет.",
  "txtRestrictedLinkDesc3": "Больше информации в документации.",
  "txtReturnAttributeData1": "Возвращать метаданные и атрибуты идентичности",
  "txtReturnAttributeData2": "картинки",
  "txtReturnAttributeDataExceptPicture1": "Возвращать метаданные и атрибуты идентичности",
  "txtReturnAttributeDataExceptPicture2": "картинки",
  "txtReturnMetaData1": "Возвращаться",
  "txtReturnMetaData2": "Метаданные (статус профиля, идентификаторы, ...)",
  "txtReturnToServices": "Вернуться к услугам",
  "txtRole": "Роль",
  "txtRuleArchiveTime": "Число от 1 до 9999",
  "txtRunningFlowConfig": "Конфигурация потока",
  "txtRunningFlowConfigDesc": "Запуск потока конфигурация",
  "txtRuntimeConfig": "Конфигурация времени выполнения",
  "txtRuntimeConfigDesc": "Настроить параметры консоли и панели инструментов",
  "txtRussian": "Русский",
  "txtSAVE": "СОХРАНЯТЬ",
  "txtSampleCode": "Образец кода",
  "txtSave": "Сохранять",
  "txtSaveChange": "Сохранить изменения",
  "txtSaveChanges": "Сохранить изменения",
  "txtScheduleDeleteProfilesSuccess": "Запланировать профили удаления успешно",
  "txtScheduleForBulkDeletion": "Расписание по объему удаления",
  "txtScope": "Объем",
  "txtSearchActivityLogs": "Журналы активности поиска",
  "txtSearchCountry": "Страна поиска",
  "txtSearchPlaceHolder": "Поиск...",
  "txtSeconds": "Второе (S)",
  "txtSeeList": "Смотрите список.",
  "txtSelectACryptoAddress": "Выберите крипто -адрес",
  "txtSelectAPlan": "Выберите план",
  "txtSelectAPlanToUnlock": "Обновите свой план",
  "txtSelectIdentityAttributes": "Выбранные атрибуты идентификации",
  "txtSelectPlan": "Выберите план",
  "txtSelfie": "Селфи",
  "txtServiceClientIdIsExisted": "Идентификатор клиента уже существует, пожалуйста, используйте другой идентификатор клиента",
  "txtServiceCompanyInfomation": "Информация о компании",
  "txtServiceDetail": "Служба деталь",
  "txtServiceEmailIsRequired": "Требуется электронная почта поддержки",
  "txtServiceListInvalidPage": "Неверная стоимость поджигания",
  "txtServiceLogo": "Сервисный логотип",
  "txtServiceLogoDesc": "Вы можете загрузить логотип для вашей службы сейчас или обновить его позже в настройках.",
  "txtServiceName": "Наименование услуги",
  "txtServiceNameIsRequired": "Требуется название обслуживания",
  "txtServiceNameOrClientId": "Имя службы или идентификатор клиента",
  "txtServiceNameOrClientIdPlaceholder": "Введите имя службы или идентификатор клиента",
  "txtServiceNameTooltip": "Выберите имя для вашего сервиса (максимум 64 символа)",
  "txtServicePortalIsRequired": "Требуется портал поддержки",
  "txtServiceTags": "Сервисные теги",
  "txtServiceTagsDes": "Добавить или удалить любые теги для этой службы",
  "txtServices": "Услуги",
  "txtServicesUsage": "Последнее использование услуг",
  "txtServicesUsageDesc": "Процесс отправки счетов людей с просьбой заплатить задолженность денег",
  "txtSettings": "Настройки",
  "txtShareableDesc": "Эта страница позволяет кандидатам отправлять информацию о KYC на вашу панель панели. Скопируйте и вставьте ссылку в электронное письмо или любое место, где вы хотите поделиться ею.",
  "txtShareableLink": "Общая ссылка",
  "txtShareableLinkMustBeSetIfUsingRestrictedShareableMethod": "Отсутствует общая ссылка",
  "txtShortDescription": "Краткое описание",
  "txtShortDescriptionTooltip": "Короткий текст, описывающий ваш сервис (только неформатированный текст)",
  "txtShowLess": "Показывай меньше",
  "txtShowMore": "Показать больше",
  "txtShowRefId": "Показать RefID",
  "txtShowSession": "Показать сессию",
  "txtShowThisService": "Покажите эту услугу",
  "txtShowThisServiceDes": "Включите или выключите эту услугу, чтобы отобразить его на рынке.",
  "txtSolanaAddress": "Адрес Solana (Sol)",
  "txtStartDate": "Дата начала",
  "txtStartingAt": "Начинается с",
  "txtStartingAtTooltip": "Дополнительная информация недоступна.",
  "txtStatus": "Положение дел",
  "txtStringMaxLengthError": "Должен быть <0> или меньше символов длиной",
  "txtStripeError": "Что -то пошло не так при проверке вашей платежной информации. Пожалуйста, свяжитесь с нами для поддержки.",
  "txtSubscribe": "Подписаться",
  "txtSubscribeTo": "Подписывайтесь на нашу новостную рассылку",
  "txtSubscribed": "Подписано",
  "txtSubscription": "Подписка",
  "txtSupport": "Поддерживать",
  "txtSupportPortal": "URL вашего веб -портала поддержки",
  "txtSupportPortalTooltip": "URL -адрес вашего веб -сайта портала обслуживания клиентов",
  "txtSupportrEmail": "Ваша электронная почта поддержки",
  "txtSupportrEmailTooltip": "Адрес электронной почты поддержки клиентов. Этот адрес используется, если вы не предоставляете URL -адрес портала поддержки. Адрес по умолчанию - support@blockpass.org",
  "txtTags": "Тег (s)",
  "txtTaxNumberWrongFormat": "Неверный формат.",
  "txtTeam": "Команда",
  "txtTestService": "Тестовая служба",
  "txtTheRequestWasMadeButNoResponseWasReceived": "Ошибка сети",
  "txtThisFieldIsRequired": "Это поле обязательно к заполнению.",
  "txtTitleAllow": "Разрешены страны",
  "txtTitleExclude": "Исключенные страны",
  "txtToGetStart": "Чтобы начать, пожалуйста, войдите в систему.",
  "txtToday": "Сегодня",
  "txtTools": "Инструменты",
  "txtTooltipAddressCountryRestriction": "Адрес необходим, если ограничения страны включены",
  "txtTooltipAddressProofOfAddress": "Адрес требуется, если выбран подтверждение адреса",
  "txtTotal": "Общий",
  "txtTotalForMonth": "Всего за месяц",
  "txtTypeSomthing": "Введите что-либо...",
  "txtUSDperCheck": "Доллар США/чек",
  "txtUnAuthorized": "Сессия истек или недействительна, пожалуйста, войдите снова",
  "txtUnauthorized": "Сессия истек или недействительна, пожалуйста, войдите снова",
  "txtUnlimittedCollaborator": "Неограниченные сотрудники",
  "txtUnsubscribe": "Отписаться",
  "txtUpdate": "Обновлять",
  "txtUpdateAPIKey": "Обновите ключ API",
  "txtUpdateApiKey": "Обновите ключ API",
  "txtUpdateApiKeySuccess": "Ключ API обновлен",
  "txtUpdateBillingPlanDisplay": "Обновление дисплея плана биллинга",
  "txtUpdateBillingPlanKycBot": "Обновление конфигурации KYC BOT",
  "txtUpdateBillingPlanVerification": "Обновление конфигурации проверки KYC",
  "txtUpdateCertificate": "Обновленный сертификат",
  "txtUpdateCheckConfig": "Обновление проверки конфигурации",
  "txtUpdateCompany": "Обновление компании",
  "txtUpdateCompanyInfoDesc": "Обновить информацию компании.",
  "txtUpdateFlowConfig": "Обновление потока конфигурации",
  "txtUpdateIdentityField": "Обновленное поле идентификации",
  "txtUpdateInfo": "Обновить информацию",
  "txtUpdateKYCSettings": "Обновленные настройки KYC",
  "txtUpdatePlanSuccess": "Успешно обновить план счетов за услуги",
  "txtUpdateRuntimeCfg": "Обновленная конфигурация времени выполнения",
  "txtUpdateService": "Обновленная служба",
  "txtUpdateServiceSuccess": "Все изменения сохранились",
  "txtUpdateWebhook": "Обновить веб -крюк",
  "txtUploadALogo": "Загрузите логотип",
  "txtUrlPrivacyPolicy": "URL вашей политики конфиденциальности",
  "txtUrlPrivacyPolicyTooltip": "Ссылка на вашу политику конфиденциальности будет отображаться до регистрации пользователя",
  "txtUrlTermsAndConditions": "URL ваших условий",
  "txtUrlTermsAndConditionsTooltip": "Ссылка на ваши условия и условия будет отображаться до регистрации пользователя",
  "txtUsage": "Применение",
  "txtUsageMetrics": "Метрики использования",
  "txtUserDebugger": "Пользователь отладчик",
  "txtUserDebuggerDesc": "Поиск пользователей по электронной почте или поддержке",
  "txtUserId": "ID пользователя",
  "txtUserSessionExpired": "Пользовательский сеанс истек",
  "txtUserSessionExpiredDesc": "Вы вышли из системы. Пожалуйста, войдите снова",
  "txtUsername": "Имя пользователя",
  "txtUsernameOrEmail": "Имя пользователя или адрес электронной почты",
  "txtUsernameOrEmailPlaceholder": "Введите имя пользователя или электронную почту",
  "txtVATNumber": "Номер НДС",
  "txtVerification": "Проверки",
  "txtVerificationGroup": "1. Проверки",
  "txtVerifications": "Проверки",
  "txtViewDetails": "Посмотреть детали",
  "txtVisitThePage": "Посетите страницу",
  "txtWEEK": "Неделя",
  "txtWarningApprovalMode": "Пожалуйста, подтвердите режим одобрения, прежде чем перейти на следующий шаг",
  "txtWavesAddress": "Адрес волн",
  "txtWebhooks": "Webhooks",
  "txtWebsite": "URL вашего сайта",
  "txtWebsiteIntegration": "Интеграция виджетов",
  "txtWebsiteTooltip": "Ссылка на ваш официальный сайт",
  "txtWebsiteUrlIsRequired": "Пожалуйста, заполните это поле",
  "txtWelcomeBody": "Создать, настраивать и контролировать сервисы для проверки идентификации ваших клиентов.",
  "txtWelcomeTitle": "Добро пожаловать в консоли администратора BlockPass",
  "txtWelcomeTo": "Добро пожаловать в",
  "txtWhoCanResgister": "Кто может зарегистрироваться?",
  "txtWillBeDeleted": "будет удален.",
  "txtYEAR": "Год",
  "txtYesterday": "Вчера",
  "txtYourApiKey": "Ваш ключ API",
  "txtYourCard": "Ваша карта",
  "txtYourCompanyInfo": "Информация о вашей компании",
  "txtYourEmailAddress": "Ваш электронный адрес",
  "txtYourLink": "Ваша ссылка",
  "txtYourLinkCopiedToClipboard": "Ваша ссылка скопирована в буфер обмена",
  "txtYourPaymentMethod": "Ваш способ оплаты",
  "txtYourPaymentMethodGroup": "4. Метод оплаты",
  "txtYourServiceConfigCopiedToClipboard": "Ваша сервис конфигурация скопирована в буфер обмена",
  "txtYourServiceCreated": "Ваш сервис был создан",
  "txtYourServiceLink": "Ваша сервисная ссылка",
  "txtYourWebsite": "https://your-website.com",
  "txtYourWebsiteUrl": "Ваш сайт URL",
  "txtYourWebsiteUrlDesc": "Пожалуйста, введите URL -адрес веб -сайта, где вы установили виджет",
  "txtdelete": "удалить",
  "txtdonwloadMonitorReport": "Отчет монитора Donwload",
  "txtsettingUpdated": "Установка обновлена"
} // eslint-disable-line no-template-curly-in-string