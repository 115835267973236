import React from 'react'
import RootStore from '@/stores/RootStore'
import { StoreContext } from '@providers/StoreProviderWrapper'

export default function useStores(): RootStore {
  const store = React.useContext(StoreContext)

  if (!store) {
    throw new Error('useRootStore must be used within a StoreProviderWrapper.')
  }

  return store
}
