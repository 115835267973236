import { useEffect } from 'react'
import { useLocalObservable } from 'mobx-react-lite'
import RootStore from '@/stores/RootStore'
import BaseStore from '@/stores/base/BaseStore'

/**
 * @template T
 * @param {any} rootStore
 * @param {T} ViewStoreType
 */
export default function useStores<T extends BaseStore>(
  rootStore: RootStore,
  ViewStoreType: { new (rootStore: RootStore, options?: any): T },
  options?: any
) {
  const viewStore = useLocalObservable(
    () => new ViewStoreType(rootStore, options)
  )

  useEffect(() => {
    viewStore.start(rootStore)
    viewStore.load && viewStore.load()
    return () => {
      viewStore.save?.()
      viewStore.stop(rootStore)
    }
  }, [rootStore, viewStore])

  return viewStore
}
