import React from 'react'
import { observer } from 'mobx-react-lite'
import styled, { css } from 'styled-components'
import useRootStore from '@hooks/useRootStore'

export const PageWrapper = styled.div<{ fullscreen: boolean }>`
  // padding: 0 16px 0px;

  @media (min-width: 421px) {
    // padding: 0 8px 0px;
  }

  @media (min-width: 651px) {
    // padding: 0 32px 0px;
  }

  @media (min-width: 1599px) {
    // padding: 0 32px 0px;
  }

  ${({ fullscreen }: any) =>
    fullscreen &&
    css`
      padding: 0 !important;
    `}
`

const PageRouteContainer: React.FC = observer(function PageRouteContainer({
  children,
}) {
  const {
    UILayoutStore: { fullscreen },
  } = useRootStore()

  return <PageWrapper fullscreen={fullscreen}>{children}</PageWrapper>
})

export default PageRouteContainer
