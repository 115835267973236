import styled from 'styled-components'
import { COLOR } from "@theme/colors";
export const FooterWrapper = styled.div`
  background-color: ${COLOR.commonColor.white};
  display: flex;
  justify-content: space-between;
  position: relative;

  // @media (max-width: 1599px) {
  //   padding: 0px 32px 0px;
  // }

  // @media (max-width: 1200px) {
  //   padding: 0px 16px 0px;
  //   display: block;
  // }

  // @media (max-width: 420px) {
  //   padding: 0px 8px 0px;
  //   display: block;
  // }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  position: relative;
  z-index: 1;
`

export const FooterContent = styled.div`
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
`

export const FooterInfoWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 16px;
`
