import styled from 'styled-components'

export const LayoutMain = styled.div`
  position: relative;
  z-index: 3;
`

export const MainWrapper = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 472px;
  /* @media (max-width: 1280px) {
    //min-height: calc(100vh - 64px);
  }

  @media (max-width: 420px) {
    //min-height: 100vh;
  } */
`
