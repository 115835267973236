import React, { useEffect } from 'react'
import { HashRouter, useHistory } from 'react-router-dom'
import useRootStore from '../hooks/useRootStore'

const StoreSync = () => {
  const history = useHistory()
  const { RouteStore } = useRootStore()

  // Binding
  RouteStore.history = history
  RouteStore.location = history.location
  useEffect(() => {
    const onChange = (location: any) => RouteStore.location = history.location
    const destroy = history.listen(onChange)

    return () => destroy()
  }, [RouteStore, history])

  return null
}

const ProviderWrapper = ({
  children
}: any) => {
  return (
    <HashRouter>
      <StoreSync />
      {children}
    </HashRouter>
  )
}

export default ProviderWrapper
