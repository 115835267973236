import styled from 'styled-components'
import { containerWidth } from '@theme/container'

export const AppContainer = styled.div`
  

  // @media (max-width: 1599px) {
  //   max-width: ${containerWidth.desktop};
  // }

  // @media (max-width: 650px) {
  //   max-width: ${containerWidth.tablet};
  // }

  // @media (max-width: 420px) {
  //   margin: 0 auto;
  //   max-width: 100%;
  // }
`
