import { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { SnackbarProvider, useSnackbar, MaterialDesignContent } from 'notistack'
import useRootStore from '@/hooks/useRootStore'
import { styled } from '@mui/material/styles';
import Notification from '@workspace/shared/Notification/Notification'
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#333333',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#333333',
  },
  '&.notistack-MuiContent-waring': {
    backgroundColor: '#333333',
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#333333',
  },
}));

const Notifier = observer(function Notifier() {
  const displayed = useRef([])
  const rootStore = useRootStore()
  const { enqueueSnackbar } = useSnackbar()

  const storeDisplayed = (id: string | number) => {
    displayed.current.push(id)
  }

  const { notifications = [] } = rootStore.UINotificationStore

  notifications.forEach((noti) => {
    if (displayed.current.includes(noti.key)) return
    enqueueSnackbar(noti.message, noti.options)
    storeDisplayed(noti.key)
    rootStore.UINotificationStore.removeSnackbar(noti.key)
  })

  return null
})

declare module "notistack" {
  interface VariantOverrides {
    notification: true;
  }
}

export const UINotificationProvider = observer(function UINotificationProvider({
  children,
}) {
  return (
    <SnackbarProvider
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        notification: Notification
      }}
      maxSnack={1}
      TransitionProps={{
        direction: 'up'
      }}

    >
      <Notifier />
      {children}
    </SnackbarProvider>
  )
})
