import React, { useEffect } from 'react'
import { useObserver } from 'mobx-react'
import useRootStore from '@hooks/useRootStore'
import { NOTI_AUTO_HIDE_TIME } from "@workspace/shared/constants/constants";
import get from 'lodash/get'

import Main from './Main'
import Footer, { FooterSource } from './Footer'

import Header, { HeaderSource } from './Header'

import AppContainer from '@components/Container'

import AppBar from '@mui/material/AppBar';
// Welcome
import { SplashPage } from '@pages/SplashPage'
interface LayoutProps {
  [key: string]: any
}

const Layout: React.FC<LayoutProps> = (function Layout({
  children,
  ...props
}) {
  // const {
  //   UILayoutStore: { appBar },
  // } = useRootStore()
  const rootStore = useRootStore()
  const { AuthStore, DashboardStore, UINotificationStore } = rootStore
  useEffect(() => {
    function changeStatus() {
      if (navigator.onLine) {
        // do nothing
      } else {
        UINotificationStore.showSnackbar({
          message: 'No internet access.',
          options: {
            variant: 'notification',
            actionLabel: '',
            actionCallback: null,
            dismiss: true,
            preventDuplicate: false,
            autoHideDuration: NOTI_AUTO_HIDE_TIME,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
          },
        })
      }
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, [UINotificationStore]);
  return useObserver(() => {
    // const viewStore = useViewStore(rootStore, ViewStore, {});
    const embedded = get(DashboardStore, 'embedded', false)
    const hideLayout = get(DashboardStore, 'hideLayout', false)

    if (AuthStore.verifying) {
      return <div style={{ height: '100vh', textAlign: 'center' }}><SplashPage /></div>
    }

    return (
      <>
        <AppContainer
          {...props}
          style={{
            ...get(props, 'style', {}),
          }}
        >
          <React.Fragment>
            <AppBar position="fixed" sx={{
              '&.MuiAppBar-root': {
                boxShadow: 'none',
                backgroundColor: '#fff'
              }
            }}>
              <Header />
              <HeaderSource hideHeader={embedded || hideLayout} />

            </AppBar>
          </React.Fragment>

          <Main>
            {children}
          </Main>
          <Footer />
          <FooterSource hideFooter={embedded || hideLayout} />

        </AppContainer>
      </>
    )

  })
})

export default Layout

export { Main, Footer, Header }
