import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

// import Slide from '@/components/Slide'
import useRootStore from '@hooks/useRootStore'

import Page404 from './404/Page404'

import Features from '../constants/features'
// // Ext:
import { pagesExtFunc } from '../extends'

import ServiceList from './ServiceList'
import CompanyDetail from './CompanyDetail'
const RouteTables = pagesExtFunc([
  // Unauthorize
  {
    key: 1,
    path: '/',
    extra: {},
    scope: ['admin', 'operator', 'user', 'bpReadOnly', 'supporter'],
    Component: ServiceList,
  },
  {
    key: 2,
    path: '/:companySlug',
    extra: {},
    scope: ['admin', 'operator', 'user', 'bpReadOnly', 'supporter'],
    Component: CompanyDetail,
  },
])

const RouteTransitionWrapper = ({ children, lastAction }: any) => {
  if (!Features.ANIMATION_EXPERIMENT) return children

  return (
    <div>{children}</div>
  )
}

const PageRoutes: React.FC<{ PageContainerComp: React.FC }> = observer(
  function PageRoutes({ PageContainerComp }) {
    const { AuthStore, RouteStore, isBootCompleted } =
      useRootStore()

    const activeRoutes = RouteTables.filter((itm: any) =>
      AuthStore.hasPermission(itm.scope)
    )
    RouteStore.activeRoutes = activeRoutes.map((itm: any) => itm.path)

    // Waiting for boot completed and SplashAnimation complete
    // if (!isBootCompleted || !isSplashAnimCompleted) return <PageSplash />

    return (
      <Switch location={RouteStore.location}>
        {isBootCompleted &&
          activeRoutes.map(({ key, path, extra, Component }: any) => (
            <Route
              key={key}
              exact
              path={path}
              {...extra}
              render={({ match }: any) => {
                RouteStore.params = match.params
                return (
                  <RouteTransitionWrapper
                    lastAction={RouteStore.lastAction}
                    path={path}
                  >
                    <PageContainerComp>
                      <Component />
                    </PageContainerComp>
                  </RouteTransitionWrapper>
                )
              }}
            />
          ))}
        <Route>
          <PageContainerComp>
            <Page404 />
          </PageContainerComp>
        </Route>
      </Switch>
    )
  }
)

export default PageRoutes
