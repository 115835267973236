import FeatureFlags from '../../constants/features'
const colors = {
  Reset: '\x1b[0m',
  Bright: '\x1b[1m',
  Dim: '\x1b[2m',
  Underscore: '\x1b[4m',
  Blink: '\x1b[5m',
  Reverse: '\x1b[7m',
  Hidden: '\x1b[8m',
  bg: {
    Black: '\x1b[40m',
    Red: '\x1b[41m',
    Green: '\x1b[42m',
    Yellow: '\x1b[43m',
    Blue: '\x1b[44m',
    Magenta: '\x1b[45m',
    Cyan: '\x1b[46m',
    White: '\x1b[47m',
    Crimson: '\x1b[48m',
  },
}
// Allow debug on prod if key DEBUG enable inside local storage
const isDebugEnable =
  process.env.NODE_ENV !== 'production' ? true : (FeatureFlags as any).DEBUG
class BaseStore {
  BASE_STORE_DEBUG_COLOR: string
  _debugLabel: string
  _destroyed: boolean
  constructor(debugLabel?: any) {
    this._debugLabel = debugLabel || this.constructor.name
    this._destroyed = false
  }

  async start(rootStore?: any) {}
  async stop(rootStore?: any) {
    this._destroyed = true
  }

  save(rootStore?: any) {}
  load(rootStore?: any) {}

  debugLog(...args: any[]) {
    const debugColorConfig = this.BASE_STORE_DEBUG_COLOR
    const _debugLabel = this._debugLabel

    if (process.env.NODE_ENV === 'test') {
      let color = '\x1b[0m'
      const debugColorCode = colors.bg[debugColorConfig] || colors.bg.Magenta
      color = `${debugColorCode}%s\x1b[0m`
      console.log(color, `[${_debugLabel}]`, ...args)
      return
    }

    // For prevent callstack trace
    // setImmediate(function () {
      if (!isDebugEnable) return
      let color = '\x1b[0m'
      const debugColorCode = colors.bg[debugColorConfig] || colors.bg.Magenta
      color = `${debugColorCode}%s\x1b[0m`
      console.log(color, `[${_debugLabel}]`, ...args)
    // })
  }

  crossLog(...args: any[]) {
    const _debugLabel = this._debugLabel
    // For prevent callstack trace
    // setImmediate(function () {
      if (!isDebugEnable) return
      console.log(`${colors.bg.Red}%s\x1b[0m`, `[${_debugLabel}]`, ...args)
    // })
  }
}
export default BaseStore
