
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Typography } from "@blockpass-org/ui";
import useRootStore from '@hooks/useRootStore'
import makeStyles from '@mui/styles/makeStyles';
import { COLOR } from '@theme/colors'
const useStyles = makeStyles((theme) => ({

  table: {
    display: 'table',
    width: 'calc(100% -  64px)',
    height: 'calc(100vh - 144px)',
    padding: 32,
    backgroundColor: '#F2F2F2'
  },
  tableCell: {
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle',
    backgroundColor: COLOR.commonColor.white,
    borderRadius: 8
  },
}))

const Page404 = observer(function Page404() {
  const {
    LocalizationStore: { translate },
  } = useRootStore()
  const classes = useStyles()
  // const handleReload = React.useCallback(() => {
  //   RouteStore.replace('/')
  // }, [RouteStore])
  useEffect(() => {
    // DashboardStore.hideFooter = true
  })

  return (
    <div className={classes.table}>
      <div className={classes.tableCell}>

        <Typography variant="h6" style={{ marginTop: 16 }}>
          {translate('txt404')}
        </Typography>
      </div>
    </div>
  )

})

export default Page404
