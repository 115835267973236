/* eslint-disable */
// prettier-ignore
export default {
  "txPersonalAttributeDesc": "Vous pouvez demander des informations supplémentaires à vos utilisateurs pendant l'intégration. Ces attributs ne sont pas vérifiés par BlockPass.",
  "txt404": "La page que vous recherchez ne peut pas être trouvée!",
  "txtAML": "AML",
  "txtAMLCheck": "Chèque AML",
  "txtAMLDesc1": "Écran contre une base de données globale de listes PEP / Sanction, et les médias défavorables, et identifiez si vos clients sont politiquement exposés, sanctionnés ou ont un profil médiatique défavorable.",
  "txtAMLDesc2": "Ce chèque oblige les utilisateurs à fournir leur nom complet et leur date de naissance.",
  "txtAPIAndWebhooks": "API et webhooks",
  "txtAccountDeletion": "Suppression du compte",
  "txtAccountDeletionDesc": "Supprimer les comptes mobiles et Web",
  "txtAction": "Action",
  "txtActive": "Actif",
  "txtActivityLogs": "Journaux d'activité",
  "txtAdd": "Ajouter",
  "txtAddCompany": "Ajouter l'entreprise",
  "txtAddNewCompany": "Ajouter une nouvelle entreprise",
  "txtAddNewsContent": "Ajouter des nouvelles",
  "txtAddNotification": "Ajouter une notification",
  "txtAddPaymentMethod": "Ajouter le mode de paiement",
  "txtAddPaymentMethodFail": "Quelque chose ne va pas ici. Veuillez ajouter le mode de paiement plus tard.",
  "txtAddPaymentMethodSuccessfully": "Votre carte de paiement est ajoutée avec succès",
  "txtAddRichContent": "Ajouter un contenu riche",
  "txtAddTag": "Ajouter une étiquette",
  "txtAddTagPlaceholder": "Tapez une étiquette de tag ...",
  "txtAddTagSuccess": "La balise \"<0>\" a ajouté avec succès.",
  "txtAddingTag": "Ajout d'une balise",
  "txtAdmin": "Administrer",
  "txtAdminConsole": "Console d'administration",
  "txtAdminSettingsUpdateSuccess": "Paramètres d'administration mis à jour avec succès.",
  "txtAdministration": "Administration",
  "txtAdvancedApprovalDesc": "Bot Kyc avancé:",
  "txtAdvancedApprovalDesc1": "Ensemble de plus de 10 règles pour approuver ou rejeter automatiquement les utilisateurs.",
  "txtAdvancedApprovalTooltip": "Advanced KYC Bot n'est pas disponible pour ce plan. Contactez-nous si vous souhaitez l'activer.",
  "txtAfterReturn": "Après que l'API a renvoyé les données",
  "txtAgeRestriction": "Restriction d'âge",
  "txtAgeRestrictionDesc1": "Vous pouvez exclure les candidats en fonction de leur âge.",
  "txtAgeRestrictionDesc2": "Le candidat doit être au moins",
  "txtAgeRestrictionDesc3": "ans.",
  "txtAllPlanFeatureHeader": "Tous les plans incluent les fonctionnalités suivantes",
  "txtAllTimeAreUTC": "(Tous les temps sont UTC)",
  "txtAllowed": "Autorisé",
  "txtAllowedCountries": "pays autorisés",
  "txtAnnouncements": "Annonces",
  "txtAnyoneWithTheLink": "Toute personne avec le lien",
  "txtAnyoneWithTheLinkDesc1": "Toute personne ayant ce lien peut s'inscrire avec votre service.",
  "txtAnyoneWithTheLinkDesc2": "Cette page permet aux candidats d'envoyer leurs informations KYC à votre tableau de bord. Copiez et collez le lien dans un e-mail ou dans tout endroit où vous souhaitez le partager.",
  "txtApiKey": "Clé API",
  "txtApiKeyCopied": "Votre clé API a copié",
  "txtApiKeyDeleteDesc": "Une fois supprimé, la clé API ne peut pas être récupérée. Si vous n'êtes pas entièrement sûr que la clé peut être supprimée, vous pouvez plutôt définir l'état sur inactif.",
  "txtApiKeys": "Clés API",
  "txtApply": " appliquer.",
  "txtApproveAdvanced": "Bot Kyc avancé",
  "txtApproveBasic": "Kyc",
  "txtApproveDesc1": "Vous pouvez gagner du temps en sélectionnant le mode de révision.\n",
  "txtApproveDesc2": "Les bots KYC sont recommandés pour un grand volume d'utilisateurs. Voir",
  "txtApproveDesc3": "plus de détails",
  "txtApproveDesc4": " dans la documentation",
  "txtApproveFreeManual": "Manuel: approuver ou rejeter chaque utilisateur manuellement. Sélectionnez un plan pour accélérer votre processus d'intégration.",
  "txtApproveNone": "Revue manuelle",
  "txtAssignCompanyToService": "Ajouter des informations de l'entreprise",
  "txtAssignRole": "Rôle attribué",
  "txtAssignUserRole": "Rôle attribué",
  "txtAttributeDataDesc": "Une fois qu'un profil est archivé, il ne reste que les métadonnées, les attributs d'identité sont supprimés.",
  "txtAttributesConfig": "Attributs Config",
  "txtAttributesConfigDesc": "Configurer les attributs disponibles dans les profils utilisateur",
  "txtAttributesGroupConfig": "Attributs Config Group Config",
  "txtAttributesGroupConfigDesc": "Configurer le groupe d'attributs disponibles dans les profils utilisateur",
  "txtAutoApproval": "Approbation automatique",
  "txtAutoArchive": "Archive automatique le profil de l'utilisateur",
  "txtAutoArchiveTooltip": "Auto Archive le profil de l'utilisateur @@ ar_ch_ti_me @@ secondes après que l'API a renvoyé les données",
  "txtAutoTranslate": "(traduit automatique)",
  "txtAutomationRules": "Règles d'automatisation",
  "txtAvatar": "Avatar",
  "txtAvaxAddress": "Adresse d'avalanche",
  "txtBack": "Dos",
  "txtBasicApprovalDesc": "KYC BOT:",
  "txtBasicApprovalDesc1": "Auto approuve les utilisateurs avec des vérifications claires.",
  "txtBasicInfo": "Informations de base",
  "txtBestPractices": "les meilleures pratiques",
  "txtBilling": "Facturation",
  "txtBillingInfo": "Informations de facturation",
  "txtBillingInfomation": "Informations de facturation",
  "txtBillingOwner": "Propriétaire de facturation",
  "txtBinanceAddress": "Adresse Binance (BSC)",
  "txtBitcoinAddress": "Adresse Bitcoin",
  "txtBlockpass": "Blockpass",
  "txtBlockpassKycRules": "BlockPass KYC Rules",
  "txtBpKYCRules": "BlockPass KYC Rules",
  "txtBrowse": "Parcourir",
  "txtBtcAdress": "Adresse Bitcoin",
  "txtBusinessInfomation": "Informations d'affaires",
  "txtBusinessTerms": "Termes commerciales",
  "txtCanNotSetTagsOnChainKYCForMultipleCryptoService": "Impossible de définir des balises OnchainKyc pour les services ont plusieurs champs de cryptographie",
  "txtCancel": "Annuler",
  "txtCardHolderName": "Nom du titulaire",
  "txtCardInfomation": "Informatique de la carte",
  "txtCardanoAddress": "Adresse Cardano (ADA)",
  "txtCaution": "Prudence !",
  "txtCautionDesc1": "Si vous êtes actuellement à l'intégration des utilisateurs, il n'est pas recommandé de modifier le plan. Veuillez nous contacter pour obtenir de l'aide.",
  "txtCautionDesc2": "Sinon, vous pouvez continuer la configuration.",
  "txtCertificatesOrderConfig": "Configuration de commande de certificats",
  "txtCertificatesOrderConfigDesc": "Configurer la commande des certificats dans les profils des utilisateurs",
  "txtChangeBillingPlan": "Changer le plan de facturation",
  "txtChangePlan": "Plan de changement",
  "txtChangeWebhook": "Webhook mis à jour",
  "txtChartEmptyTooltip": "Ce graphique remplira lorsque vos services seront utilisés par les utilisateurs.",
  "txtCheckDebugger": "Vérifier le débogueur",
  "txtCheckDebuggerDesc": "Rechercher les chèques par vérification ID. Un chèque émet généralement un certificat",
  "txtChecksConfig": "Vérifie la configuration",
  "txtChecksConfigDesc": "Configurer les attributs et le certificat définissant le chèque",
  "txtChinese": "Chinois",
  "txtClickOrDragToUpload": "Cliquez ou faites glisser pour télécharger votre logo",
  "txtClientId": "identité du client",
  "txtClientIdExisted": "L'ID client existe déjà, veuillez utiliser un ID client différent",
  "txtClientIdTooltip": "Identifiant unique de votre service.\nIl est automatiquement généré\nSur le nom de votre service",
  "txtClose": "Fermer",
  "txtCodeHasBeenCopied": "Le code a été copié",
  "txtCodeSample": "Exemple de code",
  "txtCompany": "Entreprise",
  "txtCompanyAddress": "Adresse de la société",
  "txtCompanyAddressCity": "Ville",
  "txtCompanyAddressCountry": "Pays",
  "txtCompanyAddressLine1": "Adresse Ligne 1",
  "txtCompanyAddressLine1IsRequired": "La ligne d'adresse de l'entreprise 1 est requise.",
  "txtCompanyAddressLine2": "ligne d'adresse 2 (optionnel)",
  "txtCompanyAddressPostalCode": "Code Postal",
  "txtCompanyAddressPostalCodeRequired": "Le code postal est requis.",
  "txtCompanyAddressState": "État",
  "txtCompanyCity": "Ville",
  "txtCompanyCountryIsRequired": "Le pays est requis",
  "txtCompanyCustomRequest": "Message",
  "txtCompanyEmailIsRequired": "Un e-mail de l'entreprise est requis.",
  "txtCompanyGroup": "3. Société",
  "txtCompanyInfo": "Information d'entreprise",
  "txtCompanyInfomation": "Informations sur la société",
  "txtCompanyName": "Nom de l'entreprise",
  "txtCompanyNameIsRequired": "Le nom de l'entreprise est requis",
  "txtCompanyNameRepIsRequired": "Le nom du représentant de l'entreprise est requis.",
  "txtCompanyNumber": "Numéro d'entreprise",
  "txtCompanyNumberIsRequired": "Le numéro de l'entreprise est requis",
  "txtCompanyPhoneRepIsRequired": "Téléphone du représentant de l'entreprise est requis.",
  "txtCompanyPostalCode": "Code Postal",
  "txtCompanyRepresentative": "Nom du représentant de l'entreprise",
  "txtCompanyState": "État",
  "txtCompanyVAT": "Numéro de taxe ou de TVA",
  "txtCompanyVATNumber": "Numéro de taxe ou de TVA",
  "txtCompanyVATNumberIsRequired": "Le numéro de TVA de l'entreprise est requis",
  "txtConfiguration": "Configuration",
  "txtConfirm": "Confirmer",
  "txtConfirmDeleteCompanyInfo": "Si vous revenez à l'étape précédente, toutes les informations de l'entreprise entrées seront effacées.",
  "txtConnectWithUs": "Connecte-toi avec nous",
  "txtContactSale": "Ventes de contact",
  "txtContactSaleTeam": "Contactez notre équipe de vente",
  "txtContactSupport": "Ventes de contact",
  "txtContent": "Contenu",
  "txtContinue": "Continuer",
  "txtContinueToConfigureService": "Continuez à configurer votre service",
  "txtCopied": "Copié!",
  "txtCopy": "Copie",
  "txtCopyAndClose": "Copier et fermer",
  "txtCopyApiKeyWarning": "Vous ne pourrez pas le récupérer dès que vous fermez la fenêtre contextuelle. Rangez-le dans un endroit sûr.\nEn cas de perte, vous devrez générer une nouvelle clé.",
  "txtCopyLink": "Copier le lien",
  "txtCopyright": "© BlockPass",
  "txtCount": "Compter",
  "txtCountryRestriction": "Restrictions de pays",
  "txtCountryRestrictionDesc1": "Vous pouvez exclure les résidents de certains pays ou accepter uniquement les résidents d'autres. Cette option oblige les utilisateurs à fournir leur adresse résidentielle.",
  "txtCountryRestrictionDesc2": "Le pays fourni ici est un exemple uniquement. Vous devez vérifier auprès de votre responsable de la conformité concernant les pays pour restreindre.",
  "txtCountryRestrictionDescAllow": "Permettre uniquement aux résidents de",
  "txtCountryRestrictionDescExclude": "Exclure les résidents de",
  "txtCoutryOfIncorporation": "Pays de constitution",
  "txtCreate": "Créer",
  "txtCreateAPIKey": "Créer une clé API",
  "txtCreateAPIKeyFooter1": "L'exposition publique d'une clé API peut entraîner la compromis des données personnelles de vos utilisateurs.",
  "txtCreateAPIKeyFooter2": "Tu dois suivre",
  "txtCreateAPIKeyFooter3": " pour l'utilisation solide des clés d'API.",
  "txtCreateAccount": "Créé un nouveau compte",
  "txtCreateApiKeySuccess": "Nouvelle clé API créée",
  "txtCreateBillingPlan": "Créer un plan de facturation",
  "txtCreateCertificate": "Créé un nouveau certificat",
  "txtCreateCheckConfig": "Créer une configuration de vérification",
  "txtCreateCompany": "Créer une entreprise",
  "txtCreateFlowConfig": "Créer une configuration de flux",
  "txtCreateIdentityField": "Champ d'identité créé",
  "txtCreateKYCSettings": "Création des paramètres KYC",
  "txtCreateNewTag": "Créer une balise",
  "txtCreateRuntimeCfg": "Création de configuration d'exécution",
  "txtCreateService": "Créé un nouveau service",
  "txtCreateServiceDashboard": "Créer un service",
  "txtCreateServiceSuccess": "Nouveau service créé avec succès",
  "txtCreateVersion": "Créé une nouvelle version API",
  "txtCreateWebhook": "Créé webhook",
  "txtCryptoAddresses": "Adresses de crypto",
  "txtCurrentPlan": "Plan actuel",
  "txtCustomRegistrationUrl": "URL d'enregistrement personnalisée",
  "txtCustomUrlInvalid": "Cette URL n'est pas valide.",
  "txtCustomiseAppLink": "Lien d'application pour mobile",
  "txtDAY": "Jour",
  "txtDOB": "Date de naissance",
  "txtDashboard": "Tableau de bord",
  "txtDataExport": "Exportation de données",
  "txtDate": "Date",
  "txtDelete": "Supprimer",
  "txtDeleteAPIKey": "Supprimer la clé de l'API",
  "txtDeleteApiKeySuccess": "Clé API supprimée",
  "txtDeleteBulkIdentityRecordConfirmMessage": "Êtes-vous sûr de vouloir supprimer ces comptes?",
  "txtDeleteThisApiKey": "Supprimer cette clé API",
  "txtDeleteVersion": "Version API supprimée",
  "txtDeleteWebhook": "Webhook supprimé",
  "txtDescription": "Description",
  "txtDescriptionIsRequired": "Veuillez saisir une description de cette clé API",
  "txtDetachPaymentMethod": "Méthode de paiement de détachement",
  "txtDetails": "Détails",
  "txtDiscard": "Jeter",
  "txtDiscardRichContent": "Jeter le contenu riche",
  "txtDisplay": "Afficher",
  "txtDoNotSendAddMember": "Vous n'avez pas la permission d'inviter un nouveau membre. Veuillez contacter le propriétaire du service. Code d'erreur 403.",
  "txtDocs": "Docs",
  "txtDocumentation": "Documentation",
  "txtDonNotHaveService": "Vous n'avez aucun service dans cette entreprise",
  "txtDontHavePaymentMethod": "Vous n'avez pas de mode de paiement, veuillez ajouter un mode de paiement ci-dessous",
  "txtDownload": "Télécharger",
  "txtDownloadLogs": "Télécharger les journaux",
  "txtDrivingLicense": "Permis de conduire",
  "txtDropFileHere": "Déposez votre logo ici",
  "txtEditClientId": "Modifier l'ID client",
  "txtEditClientIdWarning": "Cet ID client ne peut pas être modifié plus tard et doit être unique.",
  "txtEditConfig": "Modifier la configuration",
  "txtEditNewsContent": "Modifier les nouvelles",
  "txtEditSearch": "Modifier la recherche",
  "txtEmail": "E-mail",
  "txtEmailAddress": "Adresse e-mail",
  "txtEmailRepresentative": "Courriel de représentant de l'entreprise",
  "txtEmailWrongFormat": "Mauvais format de messagerie",
  "txtEnable": "Activer",
  "txtEnableApiKeys": "Activer les clés de l'API",
  "txtEnableApiKeysDes": "Permettez aux clients d'utiliser des clés API pour accéder aux données des utilisateurs.",
  "txtEnableEndUserRegistrations": "Activer les inscriptions de l'utilisateur final",
  "txtEnableEndUserRegistrationsDes": "Permet aux utilisateurs finaux de s'inscrire à la vérification avec ce service.",
  "txtEnableKycDashboard": "Activer le tableau de bord KYC",
  "txtEnableKycDashboardDes": "Permettez aux clients d'accéder au tableau de bord KYC.",
  "txtEnableService": "Activer le service",
  "txtEnableServiceDes": "Cette option est dangereuse; L'activation du service est sur et éteint fréquemment peut provoquer de graves erreurs.",
  "txtEndDate": "Date de fin",
  "txtEnglish": "Anglais",
  "txtEnterpriseDesc1": "Minimum 100 profils par mois",
  "txtEnterpriseDesc2": " pour plus d'informations.",
  "txtEnterpriseSentDesc1": "Merci d'avoir choisi le plan d'entreprise. Notre équipe de vente vous contactera dès que possible et configurera le service en répondant à vos besoins.\n",
  "txtEnterpriseSentDesc2": "En attendant, veuillez ajouter un mode de paiement et compléter vos informations de facturation, il est nécessaire d'activer votre plan",
  "txtErrorUnsupportFile": "Fichier non supporté",
  "txtEthAddress": "Adresse Ethereum",
  "txtEthereumAddress": "Adresse Ethereum",
  "txtEvent": "Événement",
  "txtExample": "Exemple",
  "txtExcept": "sauf",
  "txtExcluded": "Exclu",
  "txtExcludedCountries": "pays exclus",
  "txtExpiryDate": "Date d'expiration",
  "txtExploreKyc": "Explorez votre tableau de bord KYC Connect.",
  "txtExportServiceConfig": "Configuration du service d'exportation",
  "txtFaceMatch": "Match de visage",
  "txtFaceMatchCheck": "Vérification du match pour le visage",
  "txtFaceMatchDesc1": "BlockPass compare l'image sur l'ID de l'utilisateur avec le selfie en direct demandé lors de l'inscription.",
  "txtFaceMatchDesc2": "Ce chèque oblige les utilisateurs à fournir leur document d'identité et un selfie en direct.",
  "txtFacematchCheck": "Chèque de liquette",
  "txtFailedToSendMemberInvitation": "Échec de l'invitation des membres",
  "txtFamilyName": "Nom de famille",
  "txtFaq": "FAQ",
  "txtFileNotSupport": "Ce fichier n'est pas pris en charge",
  "txtFillCompanyInfo": "Remplissez des informations en fonction d'une entreprise existante ci-dessous ou ajoutez une nouvelle entreprise.",
  "txtFlowAddress": "Adresse de flux (flux)",
  "txtFlowDebugger": "Débogueur de flux",
  "txtFlowDebuggerDesc": "Les flux de recherche par ID de flux. Les flux peuvent inclure plusieurs chèques",
  "txtFlowsConfig": "Configage des flux",
  "txtFlowsConfigDesc": "Configurer des vérifications définissant le flux",
  "txtForbidden": "Vous n'avez pas la permission d'effectuer cette action",
  "txtFreePlanDescription": "Vous recevez des identités non vérifiées. Limité à 10 par jour.",
  "txtFreeTrial": "Essai gratuit",
  "txtFrench": "Français",
  "txtFunctionUnavailable": "Fonction temporairement indisponible",
  "txtGeneralInfomation": "Informatique générale",
  "txtGeoRestriction": "Restrictions géographiques",
  "txtGetServiceDisplayFailed": "Obtenir l'échec de l'affichage des services.",
  "txtGetServiceListFailed": "Obtenez l'échec de la liste des services.",
  "txtGetServiceWebhookListFailed": "Obtenez des services Webhooks échouer.",
  "txtGivenName": "Prénom",
  "txtGoToDashboard": "Aller au tableau de bord",
  "txtGoToServiceDashboard": "Aller au tableau de bord du service",
  "txtGoToServiceList": "PLUS TARD",
  "txtGotoDashboardDesc1": "Accédez au tableau de bord KYC Connect pour ce service.",
  "txtGotoDashboardDesc2": "Le tableau de bord est l'endroit où votre équipe peut examiner les identités téléchargées à votre service par des particuliers.",
  "txtHelp": "Aide",
  "txtIdEngine": "Moteur d'identité",
  "txtIdEngineDesc": "Test d'identité d'identification OCR du moteur",
  "txtIdentityDesc1": "BlockPass vérifie les passeports, les cartes d'identité nationales et les permis de conduire.",
  "txtIdentityDesc2": "Les utilisateurs sont priés de prendre une photo en direct de leur document.",
  "txtIdentityDesc3": "Cette option oblige les utilisateurs à fournir leur document d'identité, leur nom complet et leur date de naissance.",
  "txtIdentityDocument": "Document d'identité",
  "txtIdentityDocumentCheck": "Vérification des documents d'identité",
  "txtIdentityLink1": "Voir la liste des pays pris en charge",
  "txtImageTooBig": "L'image est trop grande",
  "txtImgDimensionBetween10242048": "Veuillez télécharger l'image entre 512x512 et 2048x2048 pixel",
  "txtImgDimensionRule": "Png, jpeg. Min 512x512 pixels, max 2048x2048 pixel.",
  "txtImpactChanging": "Charge d'impact",
  "txtInactive": "Inactif",
  "txtIncludeRawDataExceptPictureTooltip": "Retour des métadonnées et des attributs d'identité à l'exception des images",
  "txtIncludeRawDataIncludePictureTooltip": "Retour des métadonnées et des attributs d'identité, y compris des images",
  "txtIncludeRawDataTooltip": "Retour des métadonnées et des attributs d'identité à l'exception des images",
  "txtIncluding": "y compris",
  "txtIncorrectFormat": "Le champ ne doit contenir que des caractères normaux / supérieurs, des nombres et des soulignements (_)",
  "txtInfomation": "Information",
  "txtInputYourServiceName": "Entrez le nom de votre service",
  "txtInternalServerError": "Une erreur inattendue s'est produite sur le serveur",
  "txtInvalidEmailFormat": "Format d'email invalide",
  "txtInvalidEmails": "E-mails non valides",
  "txtInvalidParams": "La demande contienne des paramètres non valides, veuillez revérifier votre entrée",
  "txtInvalidURL": "L'URL n'est pas valide",
  "txtInvalidUrl": "Cette URL n'est pas valide",
  "txtInvitationRemoved": "L'invitation a été supprimée",
  "txtInvitationResent": "L'invitation est ressentie",
  "txtInviteAlreadySent": "Inviter déjà envoyé",
  "txtInviteUser": "Utilisateur invité",
  "txtIsCopiedToClipboard": "est copié dans le presse-papiers.",
  "txtIsSuccess": "Succès",
  "txtJapanese": "Japonais",
  "txtJobId": "ID de travail",
  "txtKYCBot": "Kyc",
  "txtKYCDashboardAccess": "Accès au tableau de bord KYC",
  "txtKybDashboard": "Tableau de bord KYB",
  "txtKybService": "Service kyb",
  "txtKybServiceDesc": "KYB signifie \"connaître votre entreprise\". Créez un service KYB pour vérifier l'identité des entreprises.",
  "txtKybServiceName": "Nom du service KYB",
  "txtKybServices": "Services KYB (WIP)",
  "txtKycDashboard": "Tableau de bord KYC",
  "txtKycService": "Service KYC",
  "txtKycServiceDesc": "KYC signifie \"connaître votre client\". Créez un service KYC pour vérifier l'identité des individus.",
  "txtKycServiceName": "Nom du service KYC",
  "txtKycServices": "Services KYC",
  "txtLast180Days": "180 derniers jours",
  "txtLast30Days": "Les 30 derniers jours",
  "txtLast7Days": "Les 7 derniers jours",
  "txtLast90Days": "90 derniers jours",
  "txtLastMonth": "Le mois dernier",
  "txtLastRunAt": "Dernière course à",
  "txtLatestInvoice": "Dernière facture",
  "txtLatestInvoiceDesc": "Les factures sont envoyées à l'adresse e-mail du propriétaire de facturation chaque mois. Vous pouvez également télécharger la dernière facture émise ci-dessous.",
  "txtLearnMoreAboutApiKey": "En savoir plus sur l'API BlockPass",
  "txtLengthTooShort": "La longueur du champ est trop courte",
  "txtLink": "Lien",
  "txtListDeleteEmail": "Liste des e-mails que vous souhaitez supprimer, séparés par des virgules, jusqu'à 50 e-mails",
  "txtListOfServices": "Liste des services",
  "txtListingRunningCheck": "Listing CHECK CHECK",
  "txtListingRunningCheckDesc": "Listing Exécution de chèques de la descipe",
  "txtLive": "En direct",
  "txtLockUser": "Utilisateur verrouillé",
  "txtLogin": "Se connecter",
  "txtLogo": "Logo",
  "txtLogoDesc": "PNG, JPEG, JPG.\nMin 512x512 PX, MAX 2048X2048 PX",
  "txtLogoRestriction": "La taille de l'image doit se situer entre 1024 x 1024 et 2048 x 2048 pixels",
  "txtLogoSizeError": "Le logo que vous venez de télécharger n'est pas la bonne taille.",
  "txtLogout": "Se déconnecter",
  "txtLogs": "Bûches",
  "txtLongDescription": "Longue description",
  "txtLongDescriptionTooltip": "Description complète de votre service (texte non formaté uniquement)",
  "txtMONTH": "Mois",
  "txtMarkerPlace": "Marché",
  "txtMarkerPlaceDesc": "Gérer les entrées de marché",
  "txtMarketPlace": "Marketplace - WIP",
  "txtMarketplace": "Marché",
  "txtMarketplaceSettingUpdateSuccess": "Paramètres de marché mis à jour avec succès.",
  "txtMaticAddress": "Adresse polygone (MATIC)",
  "txtMaxEmailsExceeded": "50 e-mails maximum par suppression",
  "txtMetaDataOnlyTooltip": "Renvoie uniquement les métadonnées (statut de profil, IDS, ...)",
  "txtMore": "Plus",
  "txtMoreDetails": "Plus de détails",
  "txtMoreInfomation": "Plus d'informations",
  "txtName": "Nom",
  "txtNameOfCompany": "Nom de l'entreprise",
  "txtNameOfCompanyIsRequired": "Le nom de l'entreprise est requis",
  "txtNameOnCard": "Nom sur la carte",
  "txtNameOnCardIncomplete": "Le nom sur carte est incomplet.",
  "txtNameRepresentative": "Nom du représentant de l'entreprise",
  "txtNationalID": "Carte d'identité nationale",
  "txtNationalId": "carte d'identité",
  "txtNewCompany": "Nouvelle compagnie",
  "txtNewsContent": "Contenu de nouvelles",
  "txtNext": "Suivant",
  "txtNoActivityLogs": "Aucune activité ne correspond à votre recherche",
  "txtNoActivityLogsDesc": "Essayez d'être moins spécifique ou d'utiliser différents mots clés.",
  "txtNoApiKeyDesc": "Une clé API est nécessaire pour extraire les identités des individus, y compris les métadonnées, les informations personnelles et les images.",
  "txtNoApiKeys": "Pas de clés API",
  "txtNoCompanyAdd": "Vous n'avez pas encore ajouté d'informations d'entreprise",
  "txtNoCompanyAddDesc": "Cliquez ci-dessous pour ajouter les premières informations de l'entreprise.",
  "txtNoCountryRestriction": "Aucun pays. Utilisez la recherche pour ajouter un pays à la liste",
  "txtNoFieldsAreUpdated": "Aucun champ n'est mis à jour",
  "txtNoInvoiceDesc1": "Les factures sont envoyées à l'adresse e-mail du propriétaire de facturation chaque mois.",
  "txtNoInvoiceDesc2": "Aucune facture émise à télécharger maintenant.",
  "txtNoServiceMatch": "Aucun service ne correspond à votre recherche",
  "txtNoServiceMatchDesc": "Essayez d'être moins spécifique ou d'utiliser différents mots clés.",
  "txtNoSetupCost": "Aucun coût d'installation",
  "txtNoUsageDetail": "aucune donnée à afficher",
  "txtNoUsageDetailDesc": "Il n'y a pas de statistiques disponibles pour le moment.",
  "txtNone": "Aucun",
  "txtNoneApprovalDesc": "Manuel:",
  "txtNoneApprovalDesc1": "Approuver ou rejeter chaque utilisateur manuellement.",
  "txtNormalDesc1": "100 profils maximum par mois.",
  "txtNotStoreCard": "BlockPass ne stocke pas les détails de votre carte.",
  "txtNotifications": "Notifications",
  "txtNotificationsDesc": "Envoyer des notifications push aux utilisateurs mobiles",
  "txtOnboarding": "Embarquement",
  "txtOnboardingGroup": "2. Intégration",
  "txtOnboardingSetting": "Paramètres d'intégration",
  "txtOnly": " seulement",
  "txtOpenDashboard": "Tableau de bord ouvert",
  "txtOverview": "Aperçu",
  "txtPOA": "Un justificatif de domicile",
  "txtPage403Desc1": "Vous n'avez pas la permission de\n accéder à ce service",
  "txtPage403Desc2": "Veuillez revenir aux services et trouver le bon service ou contacter le propriétaire du service.",
  "txtParametersValidationError": "La demande contienne des paramètres non valides, veuillez revérifier votre entrée",
  "txtPassport": "Passeport",
  "txtPassword": "Mot de passe",
  "txtPayAsYouGo": "Payer au fur et à mesure",
  "txtPaymentMethod": "Mode de paiement",
  "txtPaymentMethodDeleted": "Le mode de paiement ne peut pas être récupéré ou a été supprimé.",
  "txtPermissionDenied": "Vous n'avez pas la permission d'effectuer cette action",
  "txtPersonalAtt": "Qualités personnelles",
  "txtPersonalAttributes": "Qualités personnelles",
  "txtPhoneNumber": "Numéro de téléphone",
  "txtPhoneNumberWrongFormat": "Le numéro de téléphone doit être des chiffres",
  "txtPhoneRepresentative": "Numéro de téléphone du représentant de l'entreprise",
  "txtPickYourServiceName": "Choisissez un nom pour votre service",
  "txtPlan": "Plan",
  "txtPlanConfig": "Plans de configuration",
  "txtPlanConfigDesc": "Le processus d'envoi de factures de personnes leur demandant de payer de l'argent due",
  "txtPlanDescription1": "Moins que @@ num_ident @@ identités par jour et par entreprise.\n",
  "txtPlanDescription2": "Temps de traitement plus de 24 heures. Standard",
  "txtPleaseCopyAPIKey": "Veuillez copier la clé API",
  "txtPleaseReplacePaymentMethod": "Veuillez remplacer le mode de paiement.",
  "txtPostalCodeWrongFormat": "Le format est faux.",
  "txtPrivacy": "Confidentialité",
  "txtPrivacyPolicy": "Politique de confidentialité",
  "txtProfile": "Profil",
  "txtProofOfAddress": "Un justificatif de domicile",
  "txtProvideNameOfService": "Fournir un nom pour votre service",
  "txtPublicLinkDesc": "Toute personne sur Internet avec le lien peut soumettre son profil KYC.",
  "txtPublishService": "Service publié",
  "txtPushNotification": "Notification push",
  "txtRedirect": "Réorienter",
  "txtRemove": "Retirer",
  "txtRemoveInvitation": "Supprimer l'invitation",
  "txtRemoveTagConfirmContent": "Êtes-vous sûr de vouloir supprimer cette balise?",
  "txtRemoveTagConfirmTitle": "Supprimer la balise \"<0>\"",
  "txtRemoveTagSuccess": "La balise \"<0>\" a été supprimée.",
  "txtRemoveUser": "Utilisateur supprimé",
  "txtReplace": "Remplacer",
  "txtReplacePaymentMethod": "Remplacer le mode de paiement",
  "txtReplacePaymentMethodDesc1": "La nouvelle carte sera utilisée pour tous vos services",
  "txtReplacePaymentMethodDesc2": "Remarque, les modifications du mode de paiement affecteront de nombreux services qui utilisent ce\nmode de paiement.",
  "txtReport": "Rapport",
  "txtRepresentative": "Représentant",
  "txtRequestAccess": "Demande d'accès",
  "txtRequestEnterprise": "Demande de plan d'entreprise",
  "txtRequestEnterpriseSent": "Demande de plan d'entreprise envoyé!",
  "txtRequired": "* requis",
  "txtRequiredOneIdentityDocument": "Veuillez sélectionner au moins un document d'identité",
  "txtRequirementPlaceHolder": "Parlez-nous de vos exigences et de votre calendrier",
  "txtResAddress": "Adresse résidentielle",
  "txtResidentialAddress": "Adresse résidentielle",
  "txtRestricted": "Limité",
  "txtRestrictedDesc1": "Seules les personnes authentifiées sur votre site Web peuvent s'inscrire.",
  "txtRestrictedDesc2": "Sélectionnez cette option si vous souhaitez soumettre le raffinement de l'utilisateur au widget.",
  "txtRestrictedDesc3": "Veuillez saisir l'URL où le widget est installé sur votre site Web.",
  "txtRestrictedDescLink": "Plus d'informations dans la documentation.",
  "txtRestrictedLinkDesc1": "Forcez les utilisateurs de se connecter à votre site Web avant de pouvoir soumettre leur profil KYC.",
  "txtRestrictedLinkDesc2": "Sélectionnez cette option si vous souhaitez envoyer le refuge de l'utilisateur au widget.",
  "txtRestrictedLinkDesc3": "Plus d'informations dans la documentation.",
  "txtReturnAttributeData1": "Retour des métadonnées et des attributs d'identité",
  "txtReturnAttributeData2": "des photos",
  "txtReturnAttributeDataExceptPicture1": "Retour des métadonnées et des attributs d'identité",
  "txtReturnAttributeDataExceptPicture2": "des photos",
  "txtReturnMetaData1": "Retour",
  "txtReturnMetaData2": "métadonnées (statut de profil, IDS, ...)",
  "txtReturnToServices": "Retour aux services",
  "txtRole": "Rôle",
  "txtRuleArchiveTime": "Un nombre de 1 à 9999",
  "txtRunningFlowConfig": "Configuration de flux en cours d'exécution",
  "txtRunningFlowConfigDesc": "Exécution de la configuration du flux Desciction",
  "txtRuntimeConfig": "Configuration d'exécution",
  "txtRuntimeConfigDesc": "Configurer les paramètres de la console et du tableau de bord",
  "txtRussian": "russe",
  "txtSAVE": "SAUVEGARDER",
  "txtSampleCode": "Exemple de code",
  "txtSave": "Sauvegarder",
  "txtSaveChange": "Sauvegarder les modifications",
  "txtSaveChanges": "Sauvegarder les modifications",
  "txtScheduleDeleteProfilesSuccess": "Planifiez avec succès les profils de suppression",
  "txtScheduleForBulkDeletion": "Horaire de suppression en vrac",
  "txtScope": "Portée",
  "txtSearchActivityLogs": "Journaux d'activité de recherche",
  "txtSearchCountry": "pays de recherche",
  "txtSearchPlaceHolder": "Recherche...",
  "txtSeconds": "deuxième (s)",
  "txtSeeList": "Voir la liste.",
  "txtSelectACryptoAddress": "Sélectionnez une adresse cryptographique",
  "txtSelectAPlan": "Sélectionnez un plan",
  "txtSelectAPlanToUnlock": "Améliorez votre plan",
  "txtSelectIdentityAttributes": "Attributs d'identité sélectionnés",
  "txtSelectPlan": "Sélectionnez le plan",
  "txtSelfie": "Selfie",
  "txtServiceClientIdIsExisted": "L'ID client existe déjà, veuillez utiliser un ID client différent",
  "txtServiceCompanyInfomation": "Informations sur l'entreprise",
  "txtServiceDetail": "Détail du service",
  "txtServiceEmailIsRequired": "E-mail d'assistance est requis",
  "txtServiceListInvalidPage": "Valeur de pagination non valide",
  "txtServiceLogo": "Logo de service",
  "txtServiceLogoDesc": "Vous pouvez télécharger un logo pour votre service maintenant ou le mettre à jour plus tard dans les paramètres.",
  "txtServiceName": "Nom du service",
  "txtServiceNameIsRequired": "Le nom du service est requis",
  "txtServiceNameOrClientId": "Nom du service ou identifiant client",
  "txtServiceNameOrClientIdPlaceholder": "Entrez le nom du service ou l'ID client",
  "txtServiceNameTooltip": "Choisissez un nom pour votre service (MAX 64 caractères)",
  "txtServicePortalIsRequired": "Le portail de support est requis",
  "txtServiceTags": "Balises de service",
  "txtServiceTagsDes": "Ajouter ou supprimer toutes les balises pour ce service",
  "txtServices": "Prestations de service",
  "txtServicesUsage": "Utilisation des services récents",
  "txtServicesUsageDesc": "Le processus d'envoi de factures de personnes leur demandant de payer de l'argent due",
  "txtSettings": "Paramètres",
  "txtShareableDesc": "Cette page permet aux candidats d'envoyer leurs informations KYC à votre tableau de bord. Copiez et collez le lien dans un e-mail ou dans tout endroit où vous souhaitez le partager.",
  "txtShareableLink": "Lien partageable",
  "txtShareableLinkMustBeSetIfUsingRestrictedShareableMethod": "Lien partageable manquant",
  "txtShortDescription": "Brève description",
  "txtShortDescriptionTooltip": "Un petit texte décrivant votre service (texte non formaté uniquement)",
  "txtShowLess": "Montrer moins",
  "txtShowMore": "Montre plus",
  "txtShowRefId": "Show rafid",
  "txtShowSession": "Session de spectacle",
  "txtShowThisService": "Montrez ce service",
  "txtShowThisServiceDes": "Allumez ou désactivez ce service pour l'afficher sur le marché.",
  "txtSolanaAddress": "Adresse Solana (Sol)",
  "txtStartDate": "Date de début",
  "txtStartingAt": "À partir de",
  "txtStartingAtTooltip": "Informations supplémentaires non disponibles.",
  "txtStatus": "Statut",
  "txtStringMaxLengthError": "Doit être <0> ou moins de caractères",
  "txtStripeError": "Quelque chose s'est mal passé lors de la vérification de vos informations de facturation. Veuillez nous contacter pour un soutien.",
  "txtSubscribe": "S'abonner",
  "txtSubscribeTo": "Abonnez-vous à notre newsletter",
  "txtSubscribed": "Souscrit",
  "txtSubscription": "Abonnement",
  "txtSupport": "Soutien",
  "txtSupportPortal": "URL de votre portail Web d'assistance",
  "txtSupportPortalTooltip": "URL de votre site Web de portail de service client",
  "txtSupportrEmail": "Votre e-mail d'assistance",
  "txtSupportrEmailTooltip": "Adresse e-mail du support client. Cette adresse est utilisée si vous ne fournissez pas d'URL du portail de support. L'adresse par défaut est support@blockpass.org",
  "txtTags": "Mots clés)",
  "txtTaxNumberWrongFormat": "Format invalide.",
  "txtTeam": "Équipe",
  "txtTestService": "Service d'essai",
  "txtTheRequestWasMadeButNoResponseWasReceived": "Erreur de réseau",
  "txtThisFieldIsRequired": "Ce champ est obligatoire.",
  "txtTitleAllow": "Pays autorisés",
  "txtTitleExclude": "Pays exclus",
  "txtToGetStart": "Pour commencer, veuillez vous connecter.",
  "txtToday": "Aujourd'hui",
  "txtTools": "Outils",
  "txtTooltipAddressCountryRestriction": "Une adresse est requise si les restrictions de pays sont activées",
  "txtTooltipAddressProofOfAddress": "Une adresse est requise si la preuve d'adresse est sélectionnée",
  "txtTotal": "Total",
  "txtTotalForMonth": "Total pour le mois",
  "txtTypeSomthing": "Tapez quelque chose ...",
  "txtUSDperCheck": "USD / Vérifier",
  "txtUnAuthorized": "La session est expirée ou non valide, veuillez vous connecter à nouveau",
  "txtUnauthorized": "La session est expirée ou non valide, veuillez vous connecter à nouveau",
  "txtUnlimittedCollaborator": "Collaborateurs illimités",
  "txtUnsubscribe": "Se désabonner",
  "txtUpdate": "Mise à jour",
  "txtUpdateAPIKey": "Mettre à jour la clé de l'API",
  "txtUpdateApiKey": "Mettre à jour la clé de l'API",
  "txtUpdateApiKeySuccess": "Clé API mis à jour",
  "txtUpdateBillingPlanDisplay": "Mettre à jour l'affichage du plan de facturation",
  "txtUpdateBillingPlanKycBot": "Mettre à jour la configuration de KYC Bot",
  "txtUpdateBillingPlanVerification": "Mettre à jour la configuration de la vérification KYC",
  "txtUpdateCertificate": "Certificat mis à jour",
  "txtUpdateCheckConfig": "Mettre à jour la configuration de vérification",
  "txtUpdateCompany": "Mettre à jour l'entreprise",
  "txtUpdateCompanyInfoDesc": "Mettez à jour les informations de l'entreprise.",
  "txtUpdateFlowConfig": "Mettre à jour la configuration du flux",
  "txtUpdateIdentityField": "Champ d'identité mis à jour",
  "txtUpdateInfo": "Mettre à jour les informations",
  "txtUpdateKYCSettings": "Paramètres KYC mis à jour",
  "txtUpdatePlanSuccess": "Mettre à jour le plan de facturation de service avec succès",
  "txtUpdateRuntimeCfg": "Configuration de l'exécution mise à jour",
  "txtUpdateService": "Service mis à jour",
  "txtUpdateServiceSuccess": "Tous les modifications enregistrées",
  "txtUpdateWebhook": "Mettre à jour WebHook",
  "txtUploadALogo": "Télécharger un logo",
  "txtUrlPrivacyPolicy": "URL de votre politique de confidentialité",
  "txtUrlPrivacyPolicyTooltip": "Un lien vers votre politique de confidentialité sera affiché avant l'enregistrement de l'utilisateur",
  "txtUrlTermsAndConditions": "URL de vos conditions générales",
  "txtUrlTermsAndConditionsTooltip": "Un lien vers vos conditions générales sera affiché avant l'enregistrement de l'utilisateur",
  "txtUsage": "Usage",
  "txtUsageMetrics": "Métriques d'utilisation",
  "txtUserDebugger": "Débogueur des utilisateurs",
  "txtUserDebuggerDesc": "Recherchez les utilisateurs par e-mail ou supportID",
  "txtUserId": "ID de l'utilisateur",
  "txtUserSessionExpired": "La session utilisateur a expiré",
  "txtUserSessionExpiredDesc": "Vous avez été déconnecté. Veuillez vous connecter à nouveau",
  "txtUsername": "Nom d'utilisateur",
  "txtUsernameOrEmail": "Nom d'utilisateur ou email",
  "txtUsernameOrEmailPlaceholder": "Entrez le nom d'utilisateur ou l'e-mail",
  "txtVATNumber": "numéro de TVA",
  "txtVerification": "Vérifications",
  "txtVerificationGroup": "1. Vérifications",
  "txtVerifications": "Vérifications",
  "txtViewDetails": "Voir les détails",
  "txtVisitThePage": "Visitez la page",
  "txtWEEK": "Semaine",
  "txtWarningApprovalMode": "Veuillez confirmer le mode d'approbation avant de passer à l'étape suivante",
  "txtWavesAddress": "Adresse des vagues",
  "txtWebhooks": "Webhooks",
  "txtWebsite": "URL de votre site Web",
  "txtWebsiteIntegration": "Intégration du widget",
  "txtWebsiteTooltip": "Lien vers votre site officiel",
  "txtWebsiteUrlIsRequired": "Veuillez remplir ce champ",
  "txtWelcomeBody": "Créer, configurer et surveiller les services pour vérifier l'identité de vos clients.",
  "txtWelcomeTitle": "Bienvenue dans la console d'administration BlockPass",
  "txtWelcomeTo": "Bienvenue à",
  "txtWhoCanResgister": "Qui peut s'inscrire?",
  "txtWillBeDeleted": "sera supprimé.",
  "txtYEAR": "Année",
  "txtYesterday": "Hier",
  "txtYourApiKey": "Votre clé API",
  "txtYourCard": "Votre carte",
  "txtYourCompanyInfo": "Renseignements sur votre entreprise",
  "txtYourEmailAddress": "Votre adresse e-mail",
  "txtYourLink": "Votre lien",
  "txtYourLinkCopiedToClipboard": "Votre lien copié au presse-papiers",
  "txtYourPaymentMethod": "Votre mode de paiement",
  "txtYourPaymentMethodGroup": "4. Méthode de paiement",
  "txtYourServiceConfigCopiedToClipboard": "Votre configuration de service copiée dans le presse-papiers",
  "txtYourServiceCreated": "Votre service a été créé",
  "txtYourServiceLink": "Votre lien de service",
  "txtYourWebsite": "https://your-website.com",
  "txtYourWebsiteUrl": "L'adresse URL de votre site",
  "txtYourWebsiteUrlDesc": "Veuillez saisir l'URL du site Web où vous avez installé le widget",
  "txtdelete": "supprimer",
  "txtdonwloadMonitorReport": "Rapport de moniteur DonWload",
  "txtsettingUpdated": "Réglage mis à jour"
} // eslint-disable-line no-template-curly-in-string