import React from 'react'
import { observer } from 'mobx-react-lite'
// import makeStyles from '@mui/styles/makeStyles';
// import { COLOR } from '@theme/colors'
// import useRootStore from '@hooks/useRootStore'
import {
  SocialApple,
  SocialAppleColor,
  SocialClubhouse,
  SocialClubhouseColor,
  SocialDiscord,
  SocialDiscordColor,
  SocialDribbble,
  SocialDribbbleColor,
  SocialFacebook,
  SocialFacebookColor,
  SocialFigma,
  SocialFigmaColor,
  SocialGithub,
  SocialGithubColor,
  SocialGoogle,
  SocialGoogleColor,
  SocialInstagram,
  SocialInstagramColor,
  SocialLinkedIn,
  SocialLinkedInColor,
  SocialMedium,
  SocialMediumColor,
  SocialMessenger,
  SocialMessengerColor,
  SocialPinterest,
  SocialPinterestColor,
  SocialReddit,
  SocialRedditColor,
  SocialSignal,
  SocialSignalColor,
  SocialSnapchat,
  SocialSnapchatColor,
  SocialSpotify,
  SocialSpotifyColor,
  SocialTelegram,
  SocialTelegramColor,
  SocialThreads,
  SocialThreadsColor,
  SocialTiktok,
  SocialTiktokColor,
  SocialTumblr,
  SocialTumblrColor,
  SocialTwitch,
  SocialTwitchColor,
  SocialVK,
  SocialVKColor,
  SocialWhatsapp,
  SocialWhatsappColor,
  SocialX,
  SocialXColor,
  SocialYoutube,
  SocialYoutubeColor,

} from '@components/Icon/SocialIcon'


const ICON_GREY_MAPPING = {
  apple: <SocialApple />,
  clubhouse: <SocialClubhouse />,
  discord: <SocialDiscord />,
  dribbble: <SocialDribbble />,
  facebook: <SocialFacebook />,
  figma: <SocialFigma />,
  github: <SocialGithub />,
  google: <SocialGoogle />,
  instagram: <SocialInstagram />,
  linkedin: <SocialLinkedIn />,
  medium: <SocialMedium />,
  messenger: <SocialMessenger />,
  pinterest: <SocialPinterest />,
  reddit: <SocialReddit />,
  signal: <SocialSignal />,
  snapchat: <SocialSnapchat />,
  spotify: <SocialSpotify />,
  telegram: <SocialTelegram />,
  threads: <SocialThreads />,
  tiktok: <SocialTiktok />,
  tumblr: <SocialTumblr />,
  twitch: <SocialTwitch />,
  vk: <SocialVK />,
  whatsapp: <SocialWhatsapp />,
  x: <SocialX />,
  youtube: <SocialYoutube />,
  default: <SocialX />,

}

const ICON_COLOR_MAPPING = {
  youtube: <SocialYoutubeColor />,
  x: <SocialXColor />,
  whatsapp: <SocialWhatsappColor />,
  vk: <SocialVKColor />,
  twitch: <SocialTwitchColor />,
  tumblr: <SocialTumblrColor />,
  tiktok: <SocialTiktokColor />,
  threads: <SocialThreadsColor />,
  telegram: <SocialTelegramColor />,
  spotify: <SocialSpotifyColor />,
  snapchat: <SocialSnapchatColor />,
  signal: <SocialSignalColor />,
  reddit: <SocialRedditColor />,
  pinterest: <SocialPinterestColor />,
  messenger: <SocialMessengerColor />,
  medium: <SocialMediumColor />,
  linkedin: <SocialLinkedInColor />,
  instagram: <SocialInstagramColor />,
  google: <SocialGoogleColor />,
  github: <SocialGithubColor />,
  figma: <SocialFigmaColor />,
  facebook: <SocialFacebookColor />,
  dribbble: <SocialDribbbleColor />,
  apple: <SocialAppleColor />,
  clubhouse: <SocialClubhouseColor />,
  discord: <SocialDiscordColor />,
  default: <SocialXColor />,
}

export const SocialIcon = (props: any) => {
  // const [hoverText, setHoverText] = React.useState(false);
  // const classes = useStyles()

  const { color, name } = props

  if (color) {
    if (ICON_COLOR_MAPPING.hasOwnProperty(name.toLowerCase()))
      return ICON_COLOR_MAPPING[name.toLowerCase()]
    else {
      return ICON_COLOR_MAPPING['default']
    }
  } else {
    if (ICON_GREY_MAPPING.hasOwnProperty(name.toLowerCase()))
      return ICON_GREY_MAPPING[name.toLowerCase()]
    else {
      return ICON_GREY_MAPPING['default']
    }
  }

}

export default observer(SocialIcon)

