
import { observer } from 'mobx-react-lite'
import { COLOR } from '@theme/colors'
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLOR.textColor.primary,
    maxWidth: 238,

    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',

  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLOR.textColor.primary,
  },

}));

export const DashboardTooltip = (props: any) => {

  return (
    <LightTooltip
      {...props}
    >
      {
        props.children
      }
    </LightTooltip >
  )
}

export default observer(DashboardTooltip)

