import { useObserver } from 'mobx-react'
import { Button, Typography } from '@blockpass-org/ui'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'
import COLOR from '@theme/colors'
import get from 'lodash/get'
import { DEFAULT_AVATAR } from '@/constants/constants'
import Avatar from '@mui/material/Avatar'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 344,
    // height: 164,
    textAlign: 'center',
    // margin: 'auto',
    padding: '16px',
    backgroundColor: COLOR.commonColor.white,
    borderRadius: 8,
    border: '1px solid rgba(196, 196, 196, 1)',
  },
  containerFull: {
    maxWidth: 320,
    width: '100%',
    // minHeight: 164,
    textAlign: 'center',
    // margin: 'auto',
    padding: '12px',
    backgroundColor: COLOR.commonColor.white,
    borderRadius: 8,
    border: '1px solid rgba(196, 196, 196, 1)',
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  avatar: {
    margin: 'auto !important',
    maxHeight: 40,
    maxWidth: 40,
  },
}))
export const ServiceComponent = (props: any) => {
  const classes = useStyles()
  // const rootStore = useRootStore();
  // const {
  //   DashboardStore,
  // } = rootStore;

  const theme = useTheme()
  let downSm = useMediaQuery(theme.breakpoints.down('sm'))
  return useObserver(() => {
    const { service, modeFull } = props

    const onClickRegister = () => {
      const clientId = get(service, 'clientId', '')
      const serviceType = get(service, 'type', '')

      // to replace with API call
      const vWUrl = 'https://verify-with.blockpass.org/'
      const kybUrl = 'https://kyb.blockpass.org/registration/'
      let registerUrl = ''
      serviceType === 'kyc'
        ? (registerUrl = vWUrl + '?clientId=' + clientId)
        : (registerUrl = kybUrl + '?kybClientId=' + clientId)
      window.open(registerUrl.toString(), '_blank')
    }
    // const onClickLearnMore = () => {
    //   // DashboardStore.getServiceDetail(get(service, 'clientId', ''))
    // }

    return (
      <Box
        className={modeFull ? classes.containerFull : classes.container}
        style={modeFull && downSm ? { width: 'auto' } : {}}
      >
        <Box>
          <Grid container spacing={modeFull ? 1.5 : 2}>
            <Grid item xs={2}>
              <Avatar
                alt="Blockpass"
                src={get(service, 'logo', DEFAULT_AVATAR)}
                className={classes.avatar}
              />
            </Grid>
            <Grid item xs={10}>
              <Box
                style={{
                  textAlign: 'left',
                  height: '100%',
                  alignContent: 'center',
                }}
              >
                <Typography noWrap variant="subtitle1">
                  {get(service, 'clientId', '')}
                </Typography>
                {/* <Typography variant="body2" color="textSecondary">{get(service, 'publicKey', '')}</Typography> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          style={{
            textAlign: 'left',
            paddingTop: 16,
            height: 60,
            width: '100%',
          }}
        >
          <div className={classes.truncate}>
            <Typography variant="body2" color="textSecondary">
              {get(service, 'description', '')}
            </Typography>
          </div>
        </Box>
        {modeFull ? (
          <>
            <Box
              style={{
                textAlign: 'center',
                paddingTop: 16,
                display: 'grid',
                alignItems: 'center',
              }}
            >
              <Button variant="outlined" onClick={onClickRegister}>
                REGISTER
              </Button>
              {/* <Button style={{ marginTop: 8 }} size='small' variant="text" onClick={onClickLearnMore}>LEARN MORE </Button> */}
            </Box>
          </>
        ) : (
          <>
            <Box
              style={{
                textAlign: 'center',
                paddingTop: 16,
                display: 'grid',
                alignItems: 'center',
              }}
            >
              <Button variant="outlined" onClick={onClickRegister}>
                REGISTER
              </Button>
              {/* <Link href={'webObj.url'} underline="none" target="_blank" rel="noreferrer" style={{ display: 'flex', alignItems: 'flex-end' }}>
                {'Learn more '}
                <RightArrowComponentBlueIcon style={{ marginLeft: 8 }} />
              </Link> */}
            </Box>
          </>
        )}
      </Box>
    )
  })
}

export default ServiceComponent
