import React from 'react'
import { observer } from 'mobx-react-lite'
import Divider from '@mui/material/Divider';
import {
  FooterWrapper,
} from './Footer.styled'
// import useRootStore from '@hooks/useRootStore'
import { Typography } from '@blockpass-org/ui'
import { getCurrentYear } from '../../utils'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import loadable from '@loadable/component'
import { BlockpassFooterIcon } from '@components/Icon/CommonIcons'

// const Subscribe = loadable(() => import('@components/Subcribe'))
const MoreInfo = loadable(() => import('@components/MoreInfo'))
const ConnectWithUs = loadable(() => import('@components/ConnectWithUs'))

type FooterProps = {
  children?: React.ReactNode
  hideFooter?: boolean
}

export const Footer = observer(
  function Footer(props: FooterProps, ref: React.ForwardedRef<HTMLDivElement>) {

    const { hideFooter } = props
    const currentYear = getCurrentYear()
    // const isSubscribe = false
    return !hideFooter ? (
      <Box style={{ padding: '0px 32px 32px 32px' }}>
        <Box style={{ backgroundColor: '#FFFFFF', padding: '32px 0px 0px 0px', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} >
          <FooterWrapper ref={ref} >
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
              spacing={{ xs: 4, sm: 4, md: 4, lg: 1, xl: 1 }}
              justifyContent='flex-start'
              alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'center', xl: 'center' }}
              width={'100%'}
            >
              <Box style={{ height: '100%', width: '50%' }}>
                <BlockpassFooterIcon />
              </Box>
              <MoreInfo />
              {/* <Subscribe isSubscribe={isSubscribe} /> */}
            </Stack>
          </FooterWrapper>
          <Divider style={{ width: '100%', marginTop: 24 }} />
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
            spacing={{ xs: 4, sm: 4, md: 4, lg: 1, xl: 1 }}
            justifyContent='space-between !important'
            alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'center', xl: 'center' }}
            width={'100%'}
            style={{ padding: '24px 0px' }}
          >
            <ConnectWithUs />
            <Typography variant='body2' color='textSecondary' style={{ textAlign: 'center' }}>{'© Blockpass ' + currentYear}</Typography>
          </Stack>
        </Box>

      </Box>
    ) : <></>
  },
  { forwardRef: true }
)
export default Footer
