import { action, makeObservable, observable } from 'mobx'
import BaseStore from '../base/BaseStore'
import RootStore from '../RootStore'

interface Notification {
  key?: string | number
  message: string
  options: any
}

class UINotificationStore extends BaseStore {

  notifications: any
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
    this.notifications = []

    makeObservable(this, {
      notifications: observable,

      showSnackbar: action,
      removeSnackbar: action,
    })

   
  }

  showSnackbar = (notification: Notification) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      ...notification,
    })
  }

  removeSnackbar = (key: string | number) => {
    this.notifications = this.notifications.filter((noti) => noti.key !== key)
  }

  async start() {
    super.start()
  }

  async stop() {
    super.stop()
  }
}

export default UINotificationStore
