import React from 'react'
import { observer } from 'mobx-react-lite'
// import makeStyles from '@mui/styles/makeStyles';
// import { COLOR } from '@theme/colors'
// import useRootStore from '@hooks/useRootStore'
import {
  CryptoAdaColor,
  CryptoArbitrumColor,
  CryptoAvaxColor,
  CryptoBscFlowColor,
  CryptoBtcColor,
  CryptoEthColor,
  CryptoFlowColor,
  CryptoSolanaColor,
  CryptoWavesColor,
  CryptoDefaultColor

} from '@components/Icon/SocialIcon'



const ICON_CRYPTO_MAPPING = {
  ada: <CryptoAdaColor />,
  arb: <CryptoArbitrumColor />,
  avax: <CryptoAvaxColor />,
  bsc: <CryptoBscFlowColor />,
  btc: <CryptoBtcColor />,
  eth: <CryptoEthColor />,
  flow: <CryptoFlowColor />,
  solana: <CryptoSolanaColor />,
  waves: <CryptoWavesColor />,
  default: <CryptoDefaultColor />
}
export const CryptoIcon = (props: any) => {
  // const [hoverText, setHoverText] = React.useState(false);
  // const classes = useStyles()
  let cryptoName = ''
  const { ticker, chainId } = props
  if (ticker === '') {
    cryptoName = chainId.toLowerCase()
  }

  if (chainId === '') {
    cryptoName = ticker.toLowerCase()
  }


  if (ICON_CRYPTO_MAPPING.hasOwnProperty(cryptoName))
    return ICON_CRYPTO_MAPPING[cryptoName]
  else {
    return ICON_CRYPTO_MAPPING['default']
  }

}

export default observer(CryptoIcon)

